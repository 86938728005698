<template>
  <v-app>
    <AppSessionCountdownBar :minutes-warning="minutesTimeoutWarning" />
    <navigation-drawer :items="menuItems" permanent />
    <app-bar
      color="primary"
      app-name="oes-online-system-management"
      title="Online Enrolment System Management"
      data-testid="app-bar"
      :show-nav-icon="true"
      :enable-home-link="false"
    >
      <template #appIcon>
        <app-icon
          app-name="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>
      <template #profile v-if="user">
        <profile
          :given-name="user.givenName"
          :surname="user.sn"
          :update-profile="false"
          :change-password="false"
          :update-secret-question="false"
          :portal-settings="false"
          :logout="true"
          :logout-handler="logout"
        />
      </template>
    </app-bar>
    <v-main data-testid="content" v-if="isAuthenticatedUser">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
    <app-overlay-spinner />
    <environment-info
      :version-no="versionNo"
      :environment="environment"
      :is-visible="isVisible"
      @hide="handleHide"
    />
    <access-deny-modal
      v-model="isAccessDeny"
      max-width="550px"
      @logout="logout"
      persistent
    />
    <app-snackbar />
    <AppMessageBox v-if="messageBox" />
  </v-app>
</template>

<script>
import {
  AppBar,
  AppIcon,
  Profile,
  EnvironmentInfo,
  NavigationDrawer
} from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { NODE_ENVIRONMENTS } from '@/constants'
import AppOverlaySpinner from '@/components/AppOverlaySpinner.vue'
import AccessDenyModal from '@/components/AccessDenyModal'
import AppSnackbar from '@/components/AppSnackbar'
import AppSessionCountdownBar from '@/components/AppSessionCountdownBar.vue'
import AppMessageBox from '@/components/AppMessageBox.vue'

export default {
  name: 'App',
  components: {
    AppMessageBox,
    AppSnackbar,
    AccessDenyModal,
    AppBar,
    AppIcon,
    Profile,
    EnvironmentInfo,
    AppOverlaySpinner,
    NavigationDrawer,
    AppSessionCountdownBar
  },
  data: () => ({
    isVisible:
      process.env.VUE_APP_ENV_NAME &&
      process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD &&
      false,
    versionNo: process.env.VUE_APP_VERSION,
    envColour: process.env.VUE_APP_ENV_COLOUR,
    envName: process.env.VUE_APP_ENV_NAME,
    showOverlay: false,
    minutesTimeoutWarning: parseInt(
      process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES
    )
  }),
  async created() {
    await this.$store.dispatch('showSpinner')
    await this.$store.dispatch('setCurrentUser')
  },
  beforeMount() {
    this.$activityMonitor.activate()
  },
  beforeDestroy() {
    this.$activityMonitor.deactivate()
  },
  computed: {
    ...mapGetters([
      'messageBox',
      'user',
      'isAuthenticated',
      'isSpinner',
      'accessDeny',
      'userMenuItems'
    ]),
    environment() {
      if (this.envColour && this.envName) {
        return `${this.envColour}-${this.envName.toLowerCase()}`
      }
      if (this.envName) {
        return this.envName.toLowerCase()
      }
      return null
    },
    isAuthenticatedUser() {
      return this.isAuthenticated && !this.isSpinner
    },
    isAccessDeny() {
      return this.accessDeny
    },
    menuItems() {
      if (this.userMenuItems) {
        return this.userMenuItems
      }
      return []
    }
  },
  methods: {
    logout() {
      window.sessionStorage.removeItem('vuex')
      this.$router.push('/logout')
    },
    handleHide() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss">
@import './scss/ads.scss';
@import './scss/app.scss';

.v-application {
  background-color: $ads-secondary-grey;
}

.appbar-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
}

.row {
  margin: 0;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 44px;
}
</style>
