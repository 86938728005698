import {
  SMI_USER_ROLES,
  STATUS,
  OPTION_PROVIDER_ERROR,
  OPTION_STATUS,
  OPTION_TYPE
} from '@/constants'
import moment from 'moment'
import lodash from 'lodash'

export function isIe() {
  const ua = window.navigator.userAgent
  return (
    ua.indexOf('MSIE') !== -1 || !!navigator.userAgent.match(/Trident.*rv:11\./)
  )
}

export function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function buildNavigationDrawerMenu(userRolesString) {
  const items = []
  if (
    userRolesString &&
    userRolesString.includes(SMI_USER_ROLES.SMI_SRE_SEE_ADMIN)
  ) {
    items.push({
      icon: 'mdi-human-male-board',
      title: 'SRE/SEE settings',
      id: 'sreSeeSettings',
      route: '/',
      exact: true
    })
  }
  if (
    userRolesString &&
    userRolesString.includes(SMI_USER_ROLES.SMI_CAPSTATUS_ADMIN)
  ) {
    items.push({
      icon: 'playlist_add_check',
      title: 'School cap settings',
      id: 'schoolCapSettings',
      route: '/schoolcap'
    })
  }
  if (
    userRolesString &&
    userRolesString.includes(SMI_USER_ROLES.SMI_COMMUNICATIONS_ADMIN)
  ) {
    items.push({
      icon: 'mdi-message-text-outline',
      title: 'Communications',
      id: 'communications',
      route: '/communications'
    })
  }
  return items
}

export function filterProviders(providers, statusFilter, optionsFilter) {
  return providers.filter((item) => {
    let isStatus = true
    if (statusFilter && statusFilter.length) {
      isStatus = statusFilter.includes(item.status)
    }

    let isOption = true
    if (optionsFilter && optionsFilter.length) {
      const approvedOptions = item.approvedOptions
      if (approvedOptions) {
        const optionNames = approvedOptions.split(', ')
        if (Array.isArray(optionNames) && optionNames.length > 0) {
          isOption = optionsFilter.some((value) => optionNames.includes(value))
        }
      } else {
        isOption = false
      }
    }
    return isStatus && isOption
  })
}

export function buildProviderData(rawData) {
  let providersWithExpiredStatus = []
  if (Array.isArray(rawData) && rawData.length > 0) {
    providersWithExpiredStatus = rawData.map((item) => {
      const providerItem = {
        id: '',
        providerName: '',
        contact: '',
        approvedOptions: '',
        insuranceExpiry: '',
        lastUpdated: '',
        status: ''
      }
      if (item) {
        providerItem.id = item.id
        providerItem.providerName = item.name

        if (item.contactDetails) {
          const contactName =
            item.contactDetails.firstName + ' ' + item.contactDetails.lastName
          providerItem.contact = contactName
        }

        if (Array.isArray(item.options) && item.options.length > 0) {
          const optionNames = item.options.map(function (optionItem) {
            return optionItem['name']
          })
          const approvedOptionsStr = optionNames.join(', ')
          providerItem.approvedOptions = approvedOptionsStr
        }

        providerItem.status = item.status
        let expiryDateMoment
        if (item.insuranceExpiryDate) {
          expiryDateMoment = moment(item.insuranceExpiryDate, 'YYYY-MM-DD')
          if (expiryDateMoment && expiryDateMoment.isValid()) {
            providerItem.insuranceExpiry = expiryDateMoment.format('YYYY-MM-DD')
          }
        }
        if (STATUS.APPROVED === item.status) {
          if (item.insuranceExpiryDate) {
            if (expiryDateMoment && expiryDateMoment.isValid()) {
              const nowDate = new Date()
              const nowOnlyDate =
                nowDate.getFullYear() +
                '-' +
                (nowDate.getMonth() + 1) +
                '-' +
                nowDate.getDate()
              const nowMoment = moment(nowOnlyDate, 'YYYY-MM-DD')
              if (nowMoment.isAfter(expiryDateMoment)) {
                providerItem.status = STATUS.EXPIRED
              }
            }
          }
        }

        if (item.updatedDate) {
          const updatedDateMoment = moment(item.updatedDate)
          if (updatedDateMoment && updatedDateMoment.isValid()) {
            providerItem.lastUpdated = updatedDateMoment.format('YYYY-MM-DD')
          }
        }
      }
      return providerItem
    })
    return providersWithExpiredStatus
  }
}

export function buildOptionData(rawData) {
  let optionsWithFlatProviders = []
  if (Array.isArray(rawData) && rawData.length > 0) {
    optionsWithFlatProviders = rawData.map((item) => {
      const optionItem = {
        id: '',
        title: '',
        type: '',
        providers: '',
        description: '',
        status: '',
        updatedDate: ''
      }
      if (item) {
        optionItem.id = item.id
        optionItem.title = item.title
        optionItem.type = item.type
        optionItem.description = item.description
        optionItem.status = item.status

        if (Array.isArray(item.providers) && item.providers.length > 0) {
          const providerNames = item.providers.map(function (providerItem) {
            return providerItem['name']
          })
          const providerNamesStr = providerNames.join(', ')
          optionItem.providers = providerNamesStr
        } else {
          if (
            item.type !== OPTION_TYPE.AMA &&
            item.status === OPTION_STATUS.PUBLISHED
          ) {
            optionItem.providers = OPTION_PROVIDER_ERROR.NONE_SPECIFIED
            optionItem.status = OPTION_STATUS.ERROR
          }
        }

        if (item.updatedDate) {
          const updatedDateMoment = moment(item.updatedDate)
          if (updatedDateMoment && updatedDateMoment.isValid()) {
            optionItem.updatedDate = updatedDateMoment.format('YYYY-MM-DD')
          }
        }
      }
      return optionItem
    })
    return optionsWithFlatProviders
  }
}

export function filterOptions(
  options,
  typeFilter,
  statusFilter,
  providerFilter
) {
  return options.filter((item) => {
    let isType = true
    if (typeFilter && typeFilter.length) {
      isType = typeFilter.includes(item.type)
    }

    let isStatus = true
    if (statusFilter && statusFilter.length) {
      isStatus = statusFilter.includes(item.status)
    }

    let isProvider = true
    if (providerFilter && providerFilter.length) {
      const providers = item.providers
      if (providers) {
        const providerNames = providers.split(', ')
        if (Array.isArray(providerNames) && providerNames.length > 0) {
          isProvider = providerFilter.some((value) =>
            providerNames.includes(value)
          )
        }
      } else {
        isProvider = false
      }
    }
    return isType && isStatus && isProvider
  })
}

export function getProviderUniqueNames(providers) {
  if (Array.isArray(providers) && providers.length > 0) {
    return [...new Set(providers.map((item) => item.name))]
  }
}

export function getOptionUniqueTitles(options) {
  if (Array.isArray(options) && options.length > 0) {
    return [...new Set(options.map((item) => item.title))]
  }
}

export function upperCaseNoSpaceString(itemString) {
  if (itemString) {
    const whitespaceRemovedString = itemString.replace(/\s/g, '')
    const upperCaseString = whitespaceRemovedString.toUpperCase()
    return upperCaseString
  }
  return ''
}

export function sanitizeProviderRequestPayload(provider) {
  if (provider) {
    delete provider['options']
    delete provider['createdDate']
    delete provider['createdBy']
    delete provider['updatedDate']
    delete provider['updatedBy']
    if (provider.contactDetails && provider.contactDetails.phone) {
      let noSpacePhone = provider.contactDetails.phone
      noSpacePhone = noSpacePhone.trim()
      // eslint-disable-next-line
      noSpacePhone = noSpacePhone.replace(/\s/g, '')
      provider.contactDetails.phone = noSpacePhone
    }
    if (
      provider.contactDetails &&
      Array.isArray(provider.contactDetails.secondaryEmails)
    ) {
      provider.contactDetails.secondaryEmails =
        provider.contactDetails.secondaryEmails.filter((n) => n) // remove all the empty item.
    }
  }
}

export function generateProviderCSVExportData(providerArray) {
  const titleKeys = [
    'Provider name',
    'First name',
    'Last name',
    'Position',
    'Website',
    'Street address',
    'Phone',
    'Email',
    'Additional Email(1)',
    'Additional Email(2)',
    'Additional Email(3)',
    'Additional Email(4)',
    'Status',
    'Insurance expiry date',
    'Approved options'
  ]

  const refinedData = []
  refinedData.push(titleKeys)

  const actualData = providerArray.map((item) => {
    const name = item?.name.includes(',')
      ? '"'.concat(item?.name || '').concat('"')
      : item?.name || ''
    const firstName = item?.contactDetails?.firstName || ''
    const lastName = item?.contactDetails?.lastName || ''
    const position = item?.contactDetails?.position || ''
    const website = item?.contactDetails?.website || ''
    const address = item?.contactDetails?.address.includes(',')
      ? '"'.concat(item?.contactDetails?.address || '').concat('"')
      : item?.contactDetails?.address || ''
    const phone = item?.contactDetails?.phone || ''
    const email = item?.contactDetails?.email || ''
    let status = item?.status || ''

    let insuranceExpiry = ''
    let expiryDateMoment
    if (item.insuranceExpiryDate) {
      expiryDateMoment = moment(item.insuranceExpiryDate, 'YYYY-MM-DD')
      if (expiryDateMoment && expiryDateMoment.isValid()) {
        insuranceExpiry = expiryDateMoment.format('YYYY-MM-DD')
      }
    }
    if (STATUS.APPROVED === status) {
      if (item.insuranceExpiryDate) {
        if (expiryDateMoment && expiryDateMoment.isValid()) {
          const nowDate = new Date()
          const nowOnlyDate =
            nowDate.getFullYear() +
            '-' +
            (nowDate.getMonth() + 1) +
            '-' +
            nowDate.getDate()
          const nowMoment = moment(nowOnlyDate, 'YYYY-MM-DD')
          if (nowMoment.isAfter(expiryDateMoment)) {
            status = STATUS.EXPIRED
          }
        }
      }
    }
    const secondaryEmails = item?.contactDetails?.secondaryEmails
    let secondaryEmail1 = ''
    let secondaryEmail2 = ''
    let secondaryEmail3 = ''
    let secondaryEmail4 = ''

    secondaryEmails?.forEach((secondaryEmail, index) => {
      if (!lodash.isEmpty(secondaryEmail) && !lodash.isNil(secondaryEmail)) {
        if (index === 0) {
          secondaryEmail1 = secondaryEmail
        } else if (index === 1) {
          secondaryEmail2 = secondaryEmail
        } else if (index === 2) {
          secondaryEmail3 = secondaryEmail
        } else if (index === 3) {
          secondaryEmail4 = secondaryEmail
        }
      }
    })

    const optionsArray = item?.options?.map((option) => option?.name || '')
    let options = '"'
    optionsArray.forEach((option, idx) => {
      options = options.concat(option)

      if (idx < optionsArray.length - 1) {
        options = options.concat(`,`)
      }
    })
    options = options.concat('"')
    return {
      name,
      firstName,
      lastName,
      position,
      website,
      address,
      phone,
      email,
      secondaryEmail1,
      secondaryEmail2,
      secondaryEmail3,
      secondaryEmail4,
      status,
      insuranceExpiry,
      options
    }
  })

  actualData.forEach((item) => {
    refinedData.push(Object.values(item))
  })
  let csvContent = ''

  refinedData.forEach((row) => {
    csvContent += row.join(',') + '\n'
  })
  return csvContent
}

export function generateOptionsCSVExportData(optionArray) {
  const titleKeys = [
    'Option Title',
    'Type',
    'Provider(s)',
    'Description',
    'Status'
  ]

  const refinedData = []
  refinedData.push(titleKeys)

  const actualData = optionArray.map((item) => {
    const title = item?.title.includes(',')
      ? '"'.concat(item?.title || '').concat('"')
      : item?.title || ''
    const type = item?.type || ''
    const description = item?.description.includes(',')
      ? '"'.concat(item?.description || '').concat('"')
      : item?.description || ''

    let status = item?.status || ''

    let providers
    if (Array.isArray(item.providers) && item.providers.length > 0) {
      const providersArray = item?.providers?.map(
        (provider) => provider?.name || ''
      )
      providers = '"'
      providersArray.forEach((provider, idx) => {
        providers = providers.concat(provider)

        if (idx < providersArray.length - 1) {
          providers = providers.concat(`,`)
        }
      })
      providers = providers.concat('"')
    } else {
      if (
        item.type !== OPTION_TYPE.AMA &&
        item.status === OPTION_STATUS.PUBLISHED
      ) {
        providers = OPTION_PROVIDER_ERROR.NONE_SPECIFIED
        status = OPTION_STATUS.ERROR
      }
    }
    return {
      title,
      type,
      providers,
      description,
      status
    }
  })

  actualData.forEach((item) => {
    refinedData.push(Object.values(item))
  })
  let csvContent = ''

  refinedData.forEach((row) => {
    csvContent += row.join(',') + '\n'
  })
  return csvContent
}

export function generateSchoolCapStatusExportData(optionArray) {
  const titleKeys = [
    'School name',
    'School code',
    'New cap status',
    'OES nominated cap status',
    'Email notification'
  ]

  const refinedData = []
  refinedData.push(titleKeys)

  const actualData = optionArray.map((item) => {
    const schoolName = item?.schoolName || ''
    const schoolCode = item?.schoolCode || ''
    const newSchoolCapStatus = item?.newSchoolCapStatus || ''
    const oesNominatedCapStatus = item?.currentSchoolCapStatus || ''
    const emailNotification = item?.emailNotification ? 'Yes' : 'No'

    return {
      schoolName,
      schoolCode,
      newSchoolCapStatus,
      oesNominatedCapStatus,
      emailNotification
    }
  })

  actualData.forEach((item) => {
    refinedData.push(Object.values(item))
  })
  let csvContent = ''

  refinedData.forEach((row) => {
    csvContent += row.join(',') + '\n'
  })
  return csvContent
}
