import store from '@/store'

export default async (to, from, next) => {
  if (
    !store.getters.userMenuItems ||
    store.getters.userMenuItems?.length === 0
  ) {
    await store.dispatch('setCurrentUser')
  }
  const menuItems = store.getters.userMenuItems
  if (
    menuItems &&
    menuItems.length === 1 &&
    menuItems[0].route === '/schoolcap'
  ) {
    next({ name: 'SchoolCapView' })
  } else if (
    menuItems &&
    menuItems.length === 1 &&
    menuItems[0].route === '/'
  ) {
    next({ name: 'SreSeeSettingsView' })
  } else {
    if (!store.getters.oesSchools) {
      await store.dispatch('getOesSchools')
    }
    await store.dispatch('hideCommunicationAlert')
    next()
  }
}
