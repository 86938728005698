import AuthUtils from '@/utils/authUtils'
import userAPI from '@/api/server/user'
import { buildNavigationDrawerMenu } from '@/utils/generalUtils'

const authModule = {
  state: {
    user: null,
    userRoles: null,
    showSpinner: false,
    accessDeny: false,
    userMenuItems: [],
    showSpinnerForApplication: false
  },
  getters: {
    user: (state) => state.user,
    isSpinner: (state) => state.showSpinner,
    isSpinnerForApplication: (state) => state.showSpinnerForApplication,
    userRoles: (state) => state.userRoles,
    isAuthenticated: (state) => !!(state.user && state.userRoles),
    accessDeny: (state) => state.accessDeny,
    userMenuItems: (state) => state.userMenuItems
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setShowSpinner(state, value) {
      state.showSpinner = value
    },
    setShowSpinnerForApplication(state, value) {
      state.showSpinnerForApplication = value
    },
    setUserRoles(state, roles) {
      state.userRoles = roles
    },
    setAccessDeny(state, accessDeny) {
      state.accessDeny = accessDeny
    },
    setUserMenuItems(state, items) {
      state.userMenuItems = items
    }
  },
  actions: {
    async setCurrentUser({ dispatch, commit }) {
      dispatch('showSpinner')
      let decodedToken = AuthUtils.getToken('idToken')
      if (decodedToken) {
        commit('setUser', decodedToken)
        if (decodedToken.email) {
          const userId = decodedToken.email
          const response = await userAPI.getSMIUserRolesByUserId(userId)
          if (response) {
            const userRoles = response[0]
            if (userRoles) {
              commit('setUserRoles', userRoles)
              const roles = userRoles.roles
              const items = buildNavigationDrawerMenu(roles)
              commit('setUserMenuItems', items)
            } else {
              commit('setAccessDeny', true)
            }
          }
        }
      }
      dispatch('hideSpinner')
    },
    authenticateUser() {
      this._vm.$auth.DET.startAuth()
    },
    accessDenyUser({ commit }) {
      commit('setAccessDeny', true)
    },
    async refreshTokens() {
      const refreshToken = sessionStorage.getItem('refreshToken')
      if (refreshToken) {
        const { id_token, refresh_token, access_token } =
          await this._vm.$auth.DET.refreshTokens(refreshToken)
        if (id_token) {
          sessionStorage.setItem('idToken', id_token)
        }
        if (access_token) {
          sessionStorage.setItem('accessToken', access_token)
        }
        if (refresh_token) {
          sessionStorage.setItem('refreshToken', refreshToken)
        }
        return !!id_token
      } else {
        throw 'No refresh token was found'
      }
    },
    showSpinner({ commit }) {
      commit('setShowSpinner', true)
    },
    hideSpinner({ commit }) {
      commit('setShowSpinner', false)
    },
    showSpinnerForApplication({ commit }) {
      commit('setShowSpinnerForApplication', true)
    },
    hideSpinnerForApplication({ commit }) {
      commit('setShowSpinnerForApplication', false)
    }
  }
}

export default authModule
