<template>
  <div class="content">
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="12" class="d-flex align-start">
              <div>
                <h1 class="banner__title" v-if="isNewCommunication">
                  {{ bannerHeading }}
                </h1>
                <h3 class="banner__title" v-else>{{ bannerHeading }}</h3>
              </div>
              <div class="ml-3" v-if="!isNewCommunication">
                <chip class="banner-chip" v-bind="getStatusChipDetails" small />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>
    <div class="mainContent">
      <NavBar router-to="/communications" :last-updated="lastUpdatedInfo" />
      <communication-details :c-status="communicationAlterStatus" />
    </div>
  </div>
</template>

<script>
import { Chip, Banner } from '@nswdoe/doe-ui-core'
import NavBar from '@/components/NavBar.vue'
import CommunicationDetails from '@/components/CommunicationDetails.vue'
import { mapGetters } from 'vuex'
import {
  ROUTE_PATH,
  COMMUNICATION_STATUSES,
  COMMUNICATION_STATUS_CHIPS
} from '@/constants'

export default {
  name: 'CommunicationDetailsView',
  components: {
    Chip,
    Banner,
    NavBar,
    CommunicationDetails
  },
  computed: {
    ...mapGetters(['communicationEditMode', 'communicationById']),
    isEditing() {
      return !!this.communicationEditMode
    },
    isNewCommunication() {
      const routePath = this.$router.currentRoute.path
      if (routePath === ROUTE_PATH.COMMUNICATION.create) {
        return true
      }
      return false
    },
    communicationDetails() {
      const itemId = this.$route.params.id
      if (itemId) {
        return this.communicationById(itemId) || {}
      }
      return {}
    },
    bannerHeading() {
      const routePath = this.$router.currentRoute.path
      if (routePath === ROUTE_PATH.COMMUNICATION.create) {
        return 'Create notification'
      }
      return this.communicationDetails.notification.notificationId
    },
    communicationStatus() {
      if (this.communicationDetails) {
        return this.communicationDetails?.notification?.status
      }
      return ''
    },
    communicationAlterStatus() {
      const notification = this.communicationDetails?.notification
      const status = notification?.status
      const startDateTime = notification?.timing.startDateTime
      const endDateTime = notification?.timing.endDateTime
      if (status === COMMUNICATION_STATUSES.PUBLISHED) {
        if (new Date(startDateTime) > new Date()) {
          return COMMUNICATION_STATUSES.SCHEDULED
        } else if (new Date(endDateTime) < new Date()) {
          return COMMUNICATION_STATUSES.EXPIRED
        } else {
          return COMMUNICATION_STATUSES.ACTIVE
        }
      } else if (status === COMMUNICATION_STATUSES.REMOVED) {
        return COMMUNICATION_STATUSES.REMOVED
      } else {
        return COMMUNICATION_STATUSES.DRAFT
      }
    },
    getStatusChipDetails() {
      const status = this.communicationAlterStatus
      if (status) {
        return COMMUNICATION_STATUS_CHIPS[status]
      }
      return ''
    },
    lastUpdatedInfo() {
      if (this.communicationDetails && !this.isNewCommunication) {
        const communication = this.communicationDetails
        return (
          communication.updatedBy +
          ' on ' +
          new Date(communication.updatedDateTime).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .banner-chip {
  .v-chip__content {
    width: 117px;
    display: inline-grid;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
