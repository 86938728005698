import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import OAuth from './plugins/OAuth/OAuth'
import moment from 'vue-moment'
import axios from 'axios'
import setupAxios from '@/api/utils'
import ActivityMonitor from './plugins/ActivityMonitor'
import addGlyphs from './fontAwesome'
setupAxios(axios, store)
addGlyphs()

Vue.config.productionTip = false
Vue.use(moment) // Adds MomentJS date handling utilities
Vue.use(ActivityMonitor, {
  events: ['keydown', 'mousedown'],
  inactivityTimeout:
    (process.env.VUE_APP_ACTIVITY_TIMEOUT_MINUTES -
      process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES) *
    60000,
  throttlerTimeout: 1000,
  onInactiveUser: () => {
    store.dispatch('beginFinalInactivityTimer')
  }
})

Vue.use(OAuth, {
  router,
  authProviderConfig: {
    DET: {
      urls: {
        authorise: process.env.VUE_APP_DET_AUTH_URI,
        token: process.env.VUE_APP_DET_TOKEN_URI
      },
      params: {
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        response_type: 'code',
        scope: 'openid'
      }
    }
  },
  goToRouteOnRedirect: true,
  appStateToPersist: () => ({}),
  storagePrefix: 'si.auth',
  async onAuthorisedRedirect(tokens) {
    sessionStorage.setItem('accessToken', tokens.access)
    sessionStorage.setItem('idToken', tokens.id)
    sessionStorage.setItem('refreshToken', tokens.refresh)
    await store.dispatch('setCurrentUser')
  },
  onRedirectError: () => {}
})

Vue.prototype.$eventHub = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
