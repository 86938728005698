<template>
  <base-modal
    v-bind="$attrs"
    v-on="$listeners"
    :include-close-button="false"
    @input="closeModal"
  >
    <div id="accessDenyModal">
      <h1 class="heading">
        <v-icon size="36px" class="mr-4"> mdi-alert-circle </v-icon>
        Access denied
      </h1>
      <p>Sorry, you don’t have permission to access this application.</p>
      <div class="actions">
        <v-btn large class="primary logout" @click="$emit('logout')">
          Log out
        </v-btn>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal'

export default {
  name: 'AccessDenyModal',
  components: {
    BaseModal
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
#accessDenyModal {
  font-weight: 500;
  margin: 0 -0.5rem -0.5rem -0.5rem;
  border-top: none;

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin-top: 2rem;

    & button {
      font-weight: bold;
      background-color: $ads-primary-blue;
    }
  }

  h1 {
    color: $ads-primary-blue;
    font-weight: bold;
    font-size: 1.25rem;
  }

  p:last-child {
    margin: 0;
  }

  .v-icon {
    color: $ads-primary-blue;
  }
}
</style>
