<template>
  <nav class="NavBar" aria-label="breadcrumb">
    <div class="back float-left d-flex">
      <router-link
        :to="routerTo"
        tag="button"
        class="unstyled d-flex align-center"
        aria-label="Back"
      >
        <v-icon>arrow_back</v-icon>
        <span class="pl-1 body-2" v-if="!lastUpdated">Back</span>
      </router-link>
      <span class="pl-1 pt-1 body-2" v-if="lastUpdated">
        Last edited by: <strong>{{ lastUpdated }}</strong>
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    routerTo: {
      type: String,
      default: '/'
    },
    lastUpdated: {
      type: String,
      default: ''
    }
  },
  components: {},
  computed: {
    state() {
      return this.$store.state
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.NavBar {
  padding: 1.5rem 3rem 2.5rem 3rem;
  .icon {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: 0.5em;
  }
}
</style>
