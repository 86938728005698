import axios from 'axios'

const API_BASE = process.env.VUE_APP_ROOT_API

const PROVIDERS_URL = `${API_BASE}/smi/providers`

function fetchProviders() {
  return axios
    .get(`${PROVIDERS_URL}`)
    .then((response) => response)
    .catch((error) => error.response)
}

function updateProvider(provider) {
  const providerId = provider.id
  return axios
    .put(`${PROVIDERS_URL}/${providerId}`, provider)
    .then((response) => response)
    .catch((error) => error.response)
}

function deleteProvider(providerID) {
  return axios
    .delete(`${PROVIDERS_URL}/` + providerID)
    .then((response) => response)
    .catch((error) => error.response)
}

function createProvider(provider) {
  return axios
    .post(`${PROVIDERS_URL}`, provider)
    .then((response) => response)
    .catch((error) => error.response)
}

export default {
  fetchProviders,
  updateProvider,
  deleteProvider,
  createProvider
}
