import axios from 'axios'
import store from '@/store'

const API_BASE = process.env.VUE_APP_COMMON_API
const GET_SMI_USER_ROLES_LAMBDA_URL = `${API_BASE}/findSMIUserByUserId?userId=`

function getSMIUserRolesByUserId(userId) {
  if (userId) {
    return axios
      .get(`${GET_SMI_USER_ROLES_LAMBDA_URL}` + userId)
      .then((response) => response?.data?.body)
      .catch((error) => {
        if (error?.response?.status === 403) {
          store.dispatch('accessDenyUser')
          return null
        }
        throw error
      })
  }
  return null
}

export default {
  getSMIUserRolesByUserId
}
