import axios from 'axios'

const API_BASE = process.env.VUE_APP_ROOT_API
const OPTIONS_URL = `${API_BASE}/smi/options`

function fetchOptions() {
  return axios
    .get(`${OPTIONS_URL}`)
    .then((response) => response)
    .catch((error) => error.response)
}

function updateOption(option) {
  const optionId = option.id
  return axios
    .put(`${OPTIONS_URL}/${optionId}`, option)
    .then((response) => response)
    .catch((error) => error.response)
}

function deleteOption(optionID) {
  return axios
    .delete(`${OPTIONS_URL}/` + optionID)
    .then((response) => response)
    .catch((error) => error.response)
}

function createOption(option) {
  return axios
    .post(`${OPTIONS_URL}`, option)
    .then((response) => response)
    .catch((error) => error.response)
}

export default {
  fetchOptions,
  updateOption,
  deleteOption,
  createOption
}
