<template>
  <div class="provider-details">
    <v-form ref="providerForm" v-model="isFormValid">
      <v-card class="mx-auto pa-8" outlined>
        <div class="title ads-primary-blue pb-3">SRE/SEE provider</div>
        <ads-text-field
          class="column-text-short pb-5 pt-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.name"
          :label="isEditing ? 'Provider name*' : 'Provider name'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.providerNameRules"
          persistent-placeholder
          maxlength="255"
          placeholder="Type name"
        ></ads-text-field>

        <div class="title ads-primary-blue pt-6 pb-4">Contact details</div>
        <v-row :class="[!isEditing ? '' : 'mt-1']">
          <v-col
            cols="6"
            class="pt-0 pb-2"
            :class="[!isEditing ? 'pl-2' : 'pl-0']"
          >
            <ads-text-field
              v-model="providerItem.contactDetails.firstName"
              :label="isEditing ? 'First name*' : 'First name'"
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              :rules="rules.required"
              persistent-placeholder
              maxlength="255"
              placeholder="Type first name"
            ></ads-text-field>
          </v-col>
          <v-col cols="6" class="pl-1 pr-0 pt-0 pb-2">
            <ads-text-field
              v-model="providerItem.contactDetails.lastName"
              :label="isEditing ? 'Last name*' : 'Last name'"
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              :rules="rules.required"
              persistent-placeholder
              maxlength="255"
              placeholder="Type last name"
            ></ads-text-field>
          </v-col>
        </v-row>
        <ads-text-field
          class="pb-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.contactDetails.position"
          :label="isEditing ? 'Position*' : 'Position'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.required"
          persistent-placeholder
          maxlength="255"
          placeholder="Type a position"
        ></ads-text-field>
        <ads-text-field
          class="pb-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.contactDetails.website"
          :label="isEditing ? 'Website*' : 'Website'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.websiteRules"
          persistent-placeholder
          maxlength="255"
          placeholder="Type a URL"
        ></ads-text-field>
        <ads-text-field
          class="pb-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.contactDetails.address"
          :label="isEditing ? 'Street address*' : 'Street address'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.required"
          persistent-placeholder
          maxlength="255"
          placeholder="Type an address"
        ></ads-text-field>
        <ads-text-field
          class="pb-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.contactDetails.phone"
          :label="isEditing ? 'Phone*' : 'Phone'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.phoneRules"
          persistent-placeholder
          maxlength="255"
          placeholder="Type a phone number"
        ></ads-text-field>
        <ads-text-field
          class="pb-2"
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.contactDetails.email"
          :label="isEditing ? 'Email*' : 'Email'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.emailRules"
          persistent-placeholder
          maxlength="255"
          placeholder="Type an email address"
        ></ads-text-field>

        <v-row
          v-for="(item, index) in providerItem.contactDetails.secondaryEmails"
          :key="index"
        >
          <v-col
            class="pl-0 pt-0 pb-0 pr-0"
            :class="[!isEditing ? 'pr-0' : '']"
            :cols="!isEditing ? 12 : 11"
          >
            <ads-text-field
              :class="[!isEditing ? 'ml-2' : '']"
              v-model="providerItem.contactDetails.secondaryEmails[index]"
              :label="'Additional email (' + (index + 1) + ')'"
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              :rules="rules.emailRules"
              persistent-placeholder
              maxlength="255"
              placeholder="Type an email address"
            ></ads-text-field>
          </v-col>
          <v-col v-if="isEditing" class="pt-0 pb-0" cols="1">
            <v-btn
              icon
              color="red"
              class="pr-0"
              @click="handleRemoveEmail(index)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isEditing" class="pt-0">
          <v-col
            class="text-left pr-8 pt-0"
            :class="[!isEditing ? 'pl-2' : 'pl-0']"
          >
            <ads-button
              secondary
              class="add-email-button"
              icon="mdi-plus-circle-outline"
              button-text="Add email"
              @click="handleAddEmail"
              :disabled="isAdditionalEmailLimited"
            >
            </ads-button>
          </v-col>
        </v-row>

        <div class="title ads-primary-blue pt-6 pb-4">Approval</div>
        <ads-select
          :class="[!isEditing ? 'ml-2' : '']"
          ref="currentEditSelect"
          v-model="providerItem.status"
          :items="providerStatusItems"
          :label="isEditing ? 'Status*' : 'Status'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.required"
          persistent-placeholder
          placeholder="Select status"
        ></ads-select>

        <read-only-date-picker
          :class="[!isEditing ? 'ml-2' : '']"
          v-model="providerItem.insuranceExpiryDate"
          :label="
            isEditing ? 'Insurance expiry date*' : 'Insurance expiry date'
          "
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="rules.required"
          persistent-placeholder
          placeholder="Select a date"
        >
        </read-only-date-picker>
      </v-card>
      <v-card
        class="mx-auto pt-8 pr-0 d-flex justify-end action-card"
        outlined
        color="transparent"
      >
        <ads-button
          v-if="!isCreateMode"
          class="white--text delete-provider"
          color="red"
          button-text=" Delete provider"
          @click="confirmDelete"
        >
        </ads-button>
      </v-card>

      <v-footer padless class="provider-footer" v-if="isEditing" fixed>
        <v-container fluid>
          <v-card class="rounded-0">
            <v-row justify="end">
              <v-col class="text-right pr-8">
                <ads-button
                  class="pr-5"
                  tertiary
                  @click="handleCancel"
                  color="navy"
                  button-text="Cancel"
                >
                </ads-button>
                <ads-button
                  class="white--text ml-3"
                  @click="handleSave"
                  button-text="Save"
                  :disabled="!isFormValid"
                  :loading="isUpdateLoading"
                >
                  <template #loader>
                    <v-progress-circular
                      class="icon spin button__spinner"
                      :size="20"
                      :width="4"
                      color="white"
                      indeterminate
                      aria-label="loading"
                    />
                  </template>
                </ads-button>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-footer>
    </v-form>
    <modal-dialog
      :open-dialog="showDelete"
      max-width="645px"
      title="You are about to delete an SRE/SEE provider"
      icon="mdi-alert-outline"
      :display-close-btn="true"
      :actions="deleteActions"
      :message="deleteDialogMessage"
      aria-label-text="Close delete provider"
      @close="showDelete = false"
      :return-handler="deleteHandler"
      content-class="warning-dialog"
      btn-height="44px"
      :is-loading="providerDialogLoading"
    />
    <modal-dialog
      :open-dialog="showAprrovedToDraft"
      max-width="820px"
      title="You are about to change the status of the approved SRE/SEE provider."
      icon="mdi-alert-outline"
      :display-close-btn="true"
      :actions="approvedToDraftActions"
      :message="approvedToDraftDialogMessage"
      aria-label-text="Close"
      @close="showAprrovedToDraft = false"
      :return-handler="aprrovedToDraftHandler"
      content-class="warning-dialog"
      btn-height="44px"
    />
  </div>
</template>

<script>
import { AdsTextField, AdsSelect, AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import lodash from 'lodash'
import { ROUTE_PATH, STATUS } from '@/constants'
import ReadOnlyDatePicker from '@/components/ReadOnlyDatePicker'
import { upperCaseNoSpaceString } from '@/utils/generalUtils'
import doeColors from '@/settings/doe-colors'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  name: 'ProviderDetails',
  components: {
    ReadOnlyDatePicker,
    AdsTextField,
    AdsSelect,
    AdsButton,
    ModalDialog
  },
  data() {
    const self = this
    return {
      providerStatusItems: [STATUS.APPROVED, STATUS.DRAFT],
      providerItem: {
        name: '',
        contactDetails: {
          firstName: '',
          lastName: '',
          position: '',
          website: '',
          address: '',
          phone: '',
          email: '',
          secondaryEmails: []
        }
      },
      uniqueNames: [],
      rules: {
        required: [(value) => !!value || 'Required'],
        emailRules: [
          (value) => !!value || 'Required',
          (value) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            ) || 'Valid Email required'
        ],
        phoneRules: [
          (value) => !!value || 'Required',
          (value) => {
            if (!value.trim()) return true
            return (
              /^[\\+?0-9 ()\\-]+$/.test(value) || 'Valid Phone number required'
            )
          }
        ],
        websiteRules: [
          (value) => !!value || 'Required',
          (value) => self.isValidURL(value) || 'Valid URL required'
        ],
        providerNameRules: [
          (value) => !!value || 'Required',
          (value) => self.isUniqueName(value) || 'Unique provider name required'
        ]
      },
      isFormValid: false,
      deleteActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Confirm delete',
          color: doeColors.primary.red,
          size: 'medium',
          outlined: false,
          progress: true
        }
      ],
      approvedToDraftActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Confirm & Save',
          color: doeColors.primary.red,
          size: 'medium',
          outlined: false,
          progress: true
        }
      ],
      showDelete: false,
      isCreateMode: false,
      initialStatus: null,
      showAprrovedToDraft: false
    }
  },
  computed: {
    ...mapGetters([
      'providerEditMode',
      'providerById',
      'providerUniqueNames',
      'providerLoading',
      'providerDialogLoading'
    ]),
    isEditing() {
      return !!this.providerEditMode
    },
    isAdditionalEmailLimited() {
      return this.providerItem.contactDetails.secondaryEmails.length >= 4
    },
    isUpdateLoading() {
      return !!this.providerLoading
    },
    deleteDialogMessage() {
      let message = `<p>If you proceed, the SRE/SEE provider <b>'${this.providerItem.name}'</b> will be deleted from the system.</p>`
      const options = this.providerItem?.options ?? []
      if (options.length > 0) {
        message = message.concat(
          '<p> The provider will be removed from the following SRE/SEE Option(s):</p>'
        )
        message = message.concat("<div class='dialog-styling-content-div'>")
        options.forEach((option) => {
          message = message.concat(
            `<li class="ml-10 dialog-styling-content-li">${option.name}</li>`
          )
        })
        message = message.concat('</div>')
        message = message.concat(
          '<p>Affected schools will be notified about this change.</p>'
        )
      }

      return message
    },
    approvedToDraftDialogMessage() {
      let message = `<p>If you proceed, the approved SRE/SEE provider <b>'${this.providerItem.name}'</b> will be moved to draft status. Affected schools will be notified about this change.</p>`
      return message
    }
  },
  beforeCreate() {
    this.$store.commit('setProviderDialogLoading', false)
  },
  mounted() {
    const routePath = this.$router.currentRoute.path
    if (routePath === ROUTE_PATH.PROVIDER.create) {
      this.isCreateMode = true
      this.uniqueNames = this.providerUniqueNames('') // get other providers unique names
    }
    const itemId = this.$route.params.id
    if (itemId) {
      const item = this.providerById(itemId)
      if (item) {
        this.initialStatus = item.status
        this.providerItem = lodash.cloneDeep(item)
        this.uniqueNames = this.providerUniqueNames(item.name) // get other providers unique names
      }
    }
  },
  methods: {
    setEditMode(ref) {
      if (ref) {
        this.$store.commit('setProviderEditMode', true)
      }
    },
    handleCancel() {
      this.$router.push('/')
    },
    async handleSave() {
      const provider = this.providerItem
      if (this.isCreateMode) {
        this.$store
          .dispatch('createProvider', provider)
          .then((dispatchSucceeded) => {
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      } else {
        if (
          this.initialStatus === STATUS.APPROVED &&
          provider.status === STATUS.DRAFT
        ) {
          this.showAprrovedToDraft = true
          return false
        } else {
          this.$store
            .dispatch('updateProvider', provider)
            .then((dispatchSucceeded) => {
              if (dispatchSucceeded) {
                this.$router.push('/')
              }
            })
        }
      }
    },
    handleAddEmail() {
      this.providerItem.contactDetails.secondaryEmails.push('')
    },
    handleRemoveEmail(index) {
      this.providerItem.contactDetails.secondaryEmails.splice(index, 1)
    },
    isUniqueName(value) {
      if (value) {
        const upperCaseName = upperCaseNoSpaceString(value)
        if (this.uniqueNames.includes(upperCaseName)) {
          return false
        }
      }
      return true
    },
    isValidURL(str) {
      const testResult = str.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
      )
      return testResult !== null
    },
    confirmDelete() {
      this.showDelete = true
    },
    async deleteHandler(refr) {
      if (refr === 'Cancel') {
        this.showDelete = false
      } else if (refr === 'Confirm delete') {
        this.$store
          .dispatch('deleteProvider', this.providerItem)
          .then((dispatchSucceeded) => {
            this.showDelete = false
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      }
    },
    async aprrovedToDraftHandler(refr) {
      if (refr === 'Cancel') {
        this.showAprrovedToDraft = false
      } else if (refr === 'Confirm & Save') {
        this.showAprrovedToDraft = false
        this.$store
          .dispatch('updateProvider', this.providerItem)
          .then((dispatchSucceeded) => {
            this.showAprrovedToDraft = false
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.provider-details {
  div.v-card {
    width: 645px;
  }

  div.v-card.action-card {
    padding-bottom: 90px !important;
  }
}

.provider-footer {
  margin-top: auto;
  width: 100%;
  .v-card {
    width: 100% !important;
    box-shadow: 0px 3px 23px rgba(0, 0, 0, 0.15);
  }
}

.v-btn.delete-provider:focus {
  border: 1px solid $ads-grey-01 !important;
}

.v-btn.add-email-button {
  box-sizing: border-box;
  color: $ads-primary-blue;
  background-color: $ads-white !important;
  width: 141px;
}

::v-deep input::placeholder {
  color: $ads-grey-01 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
