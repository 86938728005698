// eslint-disable-next-line import/no-unresolved
import API from 'api/options'
import {
  buildOptionData,
  filterOptions,
  upperCaseNoSpaceString
} from '@/utils/generalUtils'
import { ALERT_TYPES, API_ERROR_SUBTYPES } from '@/constants'

const optionModule = {
  state: {
    options: null,
    optionDialogLoading: false,
    optionsLoading: false,
    optionStatusFilter: [],
    optionTypeFilter: [],
    optionProviderFilter: [],
    optionEditMode: false,
    optionBackButtonIndex: 0
  },
  getters: {
    options: (state) => state.options,
    optionsWithFlatProviders: (state, getters) => {
      const optionItems = getters.options
      const optionsWithProviders = buildOptionData(optionItems)
      return optionsWithProviders
    },
    optionsFiltered: (state, getters) => {
      const optionItems = getters.optionsWithFlatProviders
      const optionTypeFilter = getters.optionTypeFilter
      const optionStatusFilter = getters.optionStatusFilter
      const optionProviderFilter = getters.optionProviderFilter
      if (optionItems) {
        return filterOptions(
          optionItems,
          optionTypeFilter,
          optionStatusFilter,
          optionProviderFilter
        )
      }
      return optionItems
    },
    optionDialogLoading: (state) => state.optionDialogLoading,
    optionsLoading: (state) => state.optionsLoading,
    optionById: (state) => (id) => {
      return state.options.find((item) => item.id === Number(id))
    },
    optionStatusFilter: (state) => state.optionStatusFilter,
    optionTypeFilter: (state) => state.optionTypeFilter,
    optionProviderFilter: (state) => state.optionProviderFilter,
    optionEditMode: (state) => state.optionEditMode,
    optionBackButtonIndex: (state) => state.optionBackButtonIndex,
    optionUniqueTitles: (state, getters) => (currentTitle) => {
      const optionItems = getters.options
      if (Array.isArray(optionItems) && optionItems.length > 0) {
        const optionsWithoutCurrentTitle = optionItems.filter(function (item) {
          return item.title !== currentTitle
        })
        const upperCaseNamesWithoutSpace = optionsWithoutCurrentTitle.map(
          (item) => {
            return upperCaseNoSpaceString(item.title)
          }
        )
        const uniqueNames = [...new Set(upperCaseNamesWithoutSpace)]
        return uniqueNames
      }
      return []
    }
  },
  mutations: {
    setOptions(state, options) {
      state.options = options
    },
    setOptionsLoading(state, loading) {
      state.optionsLoading = loading
    },
    setOptionDialogLoading(state, loading) {
      state.optionDialogLoading = loading
    },
    setOptionStatusFilter(state, item) {
      state.optionStatusFilter = item
    },
    setOptionTypeFilter(state, item) {
      state.optionTypeFilter = item
    },
    setOptionProviderFilter(state, item) {
      state.optionProviderFilter = item
    },
    setOptionEditMode(state, mode) {
      state.optionEditMode = mode
    },
    setOptionBackButtonIndex(state, index) {
      state.optionBackButtonIndex = index
    }
  },
  actions: {
    fetchOptions({ commit, dispatch }) {
      const fallbackCode = API_ERROR_SUBTYPES.fetchOptions
      commit('setShowSpinnerForApplication', true)
      commit('setOptionsLoading', true)
      return API.fetchOptions()
        .then((response) => {
          const responseWasSuccessful = response.status === 200
          if (!responseWasSuccessful) {
            dispatch('setAPIError', fallbackCode)
            return
          }

          dispatch('removeApiError', fallbackCode)
          commit('setOptions', response.data)
        })
        .finally(() => {
          commit('setOptionsLoading', false)
          commit('setShowSpinnerForApplication', false)
        })
    },
    updateOption: function ({ commit, dispatch }, option) {
      commit('setOptionsLoading', true)
      const providers = option.providers.map((item) => {
        return item.id ? item.id : item
      })
      option.providers = providers
      return API.updateOption(option)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${option.title} successfully updated`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showOptionAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setOptionsLoading', false)
          return result
        })
    },
    deleteOption({ commit, dispatch }, optionItem) {
      commit('setOptionDialogLoading', true)
      return API.deleteOption(optionItem.id)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${optionItem.title} successfully deleted`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showProviderAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setOptionDialogLoading', false)
          return result
        })
    },
    createOption({ commit, dispatch }, option) {
      commit('setOptionsLoading', true)
      const titleName = option.title
      return API.createOption(option)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${titleName} successfully created`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showProviderAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setOptionsLoading', false)
          return result
        })
    }
  }
}

export default optionModule
