<template>
  <div class="AppSnackbar">
    <alert v-model="display" :in-page="false" v-bind="attrs"></alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Alert } from '@nswdoe/doe-ui-core'
import { ALERT_TYPES } from '@/constants'

export default {
  name: 'AppSnackbar',
  components: {
    Alert
  },
  computed: {
    ...mapGetters(['snackbar']),
    display: {
      get() {
        return this.snackbar ? this.snackbar.display : false
      },
      set() {
        this.$store.dispatch('hideSnackbar')
      }
    },
    attrs() {
      const defaults = {
        type: 'success',
        multiLine: false,
        timeout: 5000,
        position: 'bottom-left',
        allowDismiss: false,
        icon: ALERT_TYPES.SUCCESS.icon,
        subtext: ''
      }
      if (this.snackbar) {
        return { ...defaults, ...this.snackbar.attrs }
      } else {
        return { ...defaults }
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep div.v-snack .v-snack__content {
  color: $ads-grey-01;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: 26px;
}
::v-deep .alertSnacker {
  .v-icon {
    padding-top: 2px !important;
  }
}
</style>
