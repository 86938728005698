import API from '../../api/server/communications'
import { upperCaseNoSpaceString } from '@/utils/generalUtils'
import {
  COMMUNICATION_STATUSES,
  ALERT_TYPES,
  API_ERROR_SUBTYPES
} from '@/constants'

const communicationsModule = {
  state: {
    communications: null,
    communicationDialogLoading: false,
    communicationsLoading: false,
    communicationEditMode: false,
    communicationBackIndex: 0
  },
  getters: {
    communications: (state) => state.communications,
    communicationDialogLoading: (state) => state.communicationDialogLoading,
    communicationsLoading: (state) => state.communicationsLoading,
    communicationById: (state) => (uuid) => {
      return state.communications.find((item) => item.uuid === uuid)
    },
    communicationEditMode: (state) => state.communicationEditMode,
    communicationBackIndex: (state) => state.communicationBackIndex,
    communicationUniqueIds: (state, getters) => (currentId) => {
      const communicationItems = getters.communications
      if (Array.isArray(communicationItems) && communicationItems.length > 0) {
        const commsWithoutCurrentTitle = communicationItems.filter(function (
          item
        ) {
          return item.notification.notificationId !== currentId
        })
        const upperCaseNamesWithoutSpace = commsWithoutCurrentTitle.map(
          (item) => {
            return upperCaseNoSpaceString(item.notification.notificationId)
          }
        )
        const uniqueNames = [...new Set(upperCaseNamesWithoutSpace)]
        return uniqueNames
      }
      return []
    }
  },
  mutations: {
    setCommunications(state, communications) {
      state.communications = communications
    },
    setCommunicationsLoading(state, loading) {
      state.communicationsLoading = loading
    },
    setCommunicationDialogLoading(state, loading) {
      state.communicationDialogLoading = loading
    },
    setCommunicationEditMode(state, mode) {
      state.communicationEditMode = mode
    },
    setCommunicationBackIndex(state, index) {
      state.communicationBackIndex = index
    }
  },
  actions: {
    fetchCommunications({ commit, dispatch }) {
      const fallbackCode = API_ERROR_SUBTYPES.fetchCommunications
      commit('setShowSpinnerForApplication', true)
      return API.fetchCommunications()
        .then((response) => {
          const responseWasSuccessful = response.status === 200
          const apiCallWasSuccessful = response.data?.status === 200
          if (responseWasSuccessful && apiCallWasSuccessful) {
            dispatch('removeApiError', fallbackCode)
            commit('setCommunications', response.data.body)
          } else {
            dispatch('setAPIError', fallbackCode)
          }
          return response
        })
        .finally(() => {
          commit('setShowSpinnerForApplication', false)
        })
    },
    updateCommunication: function ({ commit, dispatch }, communication) {
      commit('setCommunicationsLoading', true)
      const { status } = communication.notification
      return API.updateCommunication(communication)
        .then((response) => {
          const { status: resStatus } = response
          const resSuccess = resStatus >= 200 && resStatus < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          if (status === COMMUNICATION_STATUSES.DRAFT) {
            dispatch('showSnackbar', {
              display: true,
              attrs: {
                type: ALERT_TYPES.DRAFT.type,
                icon: ALERT_TYPES.DRAFT.icon,
                subtext: `Notification saved as draft`
              }
            })
          } else if (status === COMMUNICATION_STATUSES.REMOVED) {
            dispatch('showSnackbar', {
              display: true,
              attrs: {
                type: ALERT_TYPES.REMOVE.type,
                icon: ALERT_TYPES.REMOVE.icon,
                subtext: `Notification removed`
              }
            })
          } else if (status === COMMUNICATION_STATUSES.PUBLISHED) {
            const startDateTime =
              communication.notification.timing.startDateTime
            if (new Date(startDateTime) > new Date()) {
              dispatch('showSnackbar', {
                display: true,
                attrs: {
                  type: ALERT_TYPES.SCHEDULE.type,
                  icon: ALERT_TYPES.SCHEDULE.icon,
                  subtext: `Notification scheduled for publication`
                }
              })
            } else {
              dispatch('showSnackbar', {
                display: true,
                attrs: {
                  type: ALERT_TYPES.SUCCESS.type,
                  icon: ALERT_TYPES.SUCCESS.icon,
                  subtext: `Notification published`
                }
              })
            }
          }
          return true
        })
        .catch((result) => {
          dispatch('showCommunicationAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setCommunicationsLoading', false)
          return result
        })
    },
    deleteCommunication({ commit, dispatch }, communicationItem) {
      commit('setCommunicationDialogLoading', true)
      return API.deleteCommunication(communicationItem.uuid)
        .then((response) => {
          const { status: resStatus } = response
          const resSuccess = resStatus >= 200 && resStatus < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.DELETE.type,
              icon: ALERT_TYPES.DELETE.icon,
              subtext: `Notification deleted`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showCommunicationAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setCommunicationDialogLoading', false)
          return result
        })
    },
    createCommunication({ commit, dispatch }, communication) {
      commit('setCommunicationsLoading', true)
      const status = communication.notification.status
      return API.createCommunication(communication)
        .then((response) => {
          const { status: resStatus } = response
          const resSuccess = resStatus >= 200 && resStatus < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          if (status === COMMUNICATION_STATUSES.DRAFT) {
            dispatch('showSnackbar', {
              display: true,
              attrs: {
                type: ALERT_TYPES.DRAFT.type,
                icon: ALERT_TYPES.DRAFT.icon,
                subtext: `Notification saved as draft`
              }
            })
          } else if (status === COMMUNICATION_STATUSES.PUBLISHED) {
            const startDateTime =
              communication.notification.timing.startDateTime
            if (new Date(startDateTime) > new Date()) {
              dispatch('showSnackbar', {
                display: true,
                attrs: {
                  type: ALERT_TYPES.SCHEDULE.type,
                  icon: ALERT_TYPES.SCHEDULE.icon,
                  subtext: `Notification scheduled for publication`
                }
              })
            } else {
              dispatch('showSnackbar', {
                display: true,
                attrs: {
                  type: ALERT_TYPES.SUCCESS.type,
                  icon: ALERT_TYPES.SUCCESS.icon,
                  subtext: `Notification published`
                }
              })
            }
          }
          return true
        })
        .catch((result) => {
          dispatch('showCommunicationAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setCommunicationsLoading', false)
          return result
        })
    }
  }
}

export default communicationsModule
