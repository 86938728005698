<template>
  <div ref="dateField">
    <div
      v-show="isReadOnlyOrViewMode"
      class="displayField"
      :class="{ readonly }"
      :tabindex="readonly ? -1 : 0"
      @keydown.enter="setEditMode($refs.dateField)"
      @click="setEditMode($refs.dateField)"
    >
      <label>{{ label }}</label>
      <p class="value mt-2">{{ value }}</p>
      <div class="editIcon" v-if="!readonly">
        <v-icon size="20px">mdi-pencil</v-icon>
      </div>
    </div>
    <div v-show="!isReadOnlyOrViewMode">
      <date-picker
        ref="roDatePicker"
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        :label="label"
      >
      </date-picker>
    </div>
  </div>
</template>

<script>
import { DatePicker } from '@nswdoe/doe-ui-core'

export default {
  name: 'ReadOnlyDatePicker',
  components: {
    DatePicker
  },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isAllowEditingMode: {
      type: Boolean,
      default: false
    },
    setEditMode: {
      type: Function,
      default: null
    }
  },
  computed: {
    isReadOnlyOrViewMode() {
      return this.readonly || (!this.isEditing && this.isAllowEditingMode)
    }
  },
  methods: {
    triggerBlur() {
      this.$refs.roDatePicker.handleDateTextBlur()
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  color: $ads-primary-blue;
  font-size: 1rem;
  font-weight: 700;
}
.value {
  min-height: 1rem;
  color: $ads-grey-01;
}

.editIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  display: none;
  &:focus {
    display: block;
    border: 2px solid $ads-navy;
  }
}

.displayField:not(.readonly) {
  position: relative;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $ads-grey-04;
    .editIcon {
      display: block;
    }
  }
}

.displayField.readonly {
  cursor: not-allowed;
  label {
    cursor: not-allowed;
  }
}

.isEditing .displayField {
  padding-top: 0px !important;
}
</style>
