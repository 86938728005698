<template>
  <div class="countApiErrorWrapper">
    <img class="alertIcon" src="@/assets/alert.svg" alt="NSW Government" />
    <div class="errorTittle">
      {{ title }}
    </div>
    <div class="errorSubtitle">
      {{ message }}
    </div>
    <AdsButton
      button-text="Refresh"
      icon="mdi-refresh"
      aria-label="Refresh"
      class="refreshButton"
      @click="onRefreshClick(dispatch)"
      data-testid="download-button"
    />
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'ApiError',
  components: { AdsButton },
  props: {
    title: {
      type: String,
      default: 'System error'
    },
    message: {
      type: String,
      default: 'We were unable to retrieve any data. Please try again.'
    },
    dispatch: {
      type: String,
      default: ''
    }
  },
  methods: {
    onRefreshClick(value) {
      this.$store.dispatch(value)
    }
  }
}
</script>

<style scoped lang="scss">
.countApiErrorWrapper {
  margin-top: 7rem;
}

.alertIcon {
  display: block;
  margin: 2rem auto;
}

.errorTittle {
  font-weight: bold;
  font-size: 20px;
  color: $ads-primary-blue;
  text-align: center;
}

.errorSubtitle {
  color: $ads-primary-blue;
  text-align: center;
  margin-top: 1rem;
  font-size: 14px;
}

.refreshButton {
  display: block;
  margin: 2rem auto 10rem;
}
</style>
