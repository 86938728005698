<template>
  <v-switch v-bind="$attrs" v-on="$listeners" ref="OesSwitch" :ripple="false">
    <!-- pass through slots -->
    <template v-for="(_, slotName) of $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </v-switch>
</template>

<script>
import { isIe } from '@/utils/generalUtils'

export default {
  name: 'OesSwitch',
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  mounted() {
    if (isIe()) {
      var el = this.$refs.OesSwitch.$el
      el.onkeydown = (e) => {
        if (e.key === 'Spacebar' || e.key === ' ') {
          e.preventDefault()
          const value = this.$attrs.inputValue

          if (Array.isArray(value)) {
            const valueIndex = value.indexOf(this.$attrs.value)
            if (valueIndex === -1) {
              value.push(this.$attrs.value)
            } else {
              value.splice(valueIndex, 1)
            }
            this.$emit('change', value)
          } else {
            this.$emit('change', !value)
          }
        }
      }
    }
  }
}
</script>
