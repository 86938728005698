<template>
  <div class="communication-details mt-6">
    <v-card
      v-if="showDeleteBtn || showRemoveBtn"
      class="mx-auto mb-15 mt-6"
      outlined
      color="transparent"
    >
      <div class="float-right">
        <ads-button
          v-if="showDeleteBtn"
          secondary
          color="red"
          button-text="Delete"
          icon="mdi-delete-outline"
          @click="handleDeleteModal"
        />
        <ads-button
          v-if="showDeleteBtn"
          class="ml-3"
          color="primary"
          button-text="Edit & publish"
          icon="mdi-pencil-outline"
          @click="setEditMode"
        />
        <ads-button
          v-if="showRemoveBtn"
          class="white--text remove-communication"
          color="red"
          button-text="Remove"
          icon="mdi-close-circle"
          @click="handleRemoveModal"
        />
      </div>
    </v-card>
    <v-form ref="communicationForm" v-model="isFormValid">
      <v-card class="mx-auto pa-8 mb-6" outlined>
        <div class="title ads-primary-blue">Notification ID</div>
        <p class="pb-6">
          Create a unique ID for this notification. Only you can see this.
        </p>
        <ads-text-field
          class="column-text-short"
          v-model="communication.notification.notificationId"
          :label="isEditing ? 'Notification ID*' : 'Notification ID'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.notificationsIdRules"
          persistent-placeholder
          placeholder="Specify an ID"
          counter
          :counter-value="
            (value) => {
              return value
                ? value.length + '/100 characters'
                : '0/100 characters'
            }
          "
          :readonly="readOnlyStatus"
        />
      </v-card>
      <v-card class="mx-auto pa-8" outlined>
        <div class="title ads-primary-blue pb-6">Notification design</div>
        <ads-text-area
          class="column-text-short"
          v-model="communication.notification.heading"
          :label="isEditing ? 'Heading*' : 'Heading'"
          :is-editing="isEditing"
          is-allow-editing-mode
          counter
          :counter-value="
            (value) => {
              return value
                ? value.length + '/150 characters'
                : '0/150 characters'
            }
          "
          :set-edit-mode="setEditMode"
          :rules="validationRules.headingRules"
          no-resize
          persistent-placeholder
          placeholder="Type the heading"
          rows="2"
          :readonly="readOnlyStatus"
        />
        <ads-text-area
          class="column-text-short"
          v-model="communication.notification.subheading"
          label="Subheading (optional)"
          :is-editing="isEditing"
          is-allow-editing-mode
          counter
          :counter-value="
            (value) => {
              return value
                ? value.length + '/200 characters'
                : '0/200 characters'
            }
          "
          :set-edit-mode="setEditMode"
          :rules="
            communication.notification.subheading
              ? validationRules.subheadingRules
              : []
          "
          no-resize
          persistent-placeholder
          rows="3"
          :readonly="readOnlyStatus"
        />
        <oes-switch
          v-if="isEditing"
          v-model="isCallToAction"
          class="oesSwitch column-text-short"
          label="Call to action link"
        />
        <div
          v-else
          class="ml-1 mb-6 switch-readonly"
          :tabindex="readOnlyStatus ? '-1' : '0'"
          :readonly="readOnlyStatus"
        >
          Call to action link
          {{ isCallToAction ? '( enabled )' : '( disabled )' }}
        </div>
        <ads-text-field
          v-if="isCallToAction"
          v-model="communication.notification.callToAction.buttonText"
          class="column-text-short"
          :label="isEditing ? 'Call to action text*' : 'Call to action text'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.buttonTextRules"
          persistent-placeholder
          placeholder="Specify a call to action"
          counter
          :counter-value="
            (value) => {
              return value ? value.length + '/50 characters' : '0/50 characters'
            }
          "
          :readonly="readOnlyStatus"
        />
        <ads-text-field
          v-if="isCallToAction"
          v-model="communication.notification.callToAction.URL"
          class="column-text-short"
          :label="isEditing ? 'URL*' : 'URL'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.urlRules"
          persistent-placeholder
          placeholder="Insert a URL"
          :readonly="readOnlyStatus"
        />
        <oes-switch
          v-if="isEditing"
          v-model="communication.notification.dismissable"
          class="oesSwitch column-text-short"
          label="Dismissible message"
        />
        <div
          v-else
          class="ml-1 mb-3 switch-readonly"
          :tabindex="readOnlyStatus ? '-1' : '0'"
          :readonly="readOnlyStatus"
        >
          Dismissible message
          {{
            communication.notification.dismissable
              ? '( enabled )'
              : '( disabled )'
          }}
        </div>
        <span
          v-if="!communication?.notification?.dismissable"
          class="d-flex dismissible-message"
          :class="isEditing ? 'ml-12' : 'ml-1'"
        >
          The notification cannot be dismissed by the user and will remain on
          the dashboard for the specified time duration.
        </span>
        <span
          v-else
          class="d-flex dismissible-message"
          :class="isEditing ? 'ml-12' : 'ml-1'"
        >
          The user will be able to dismiss the notification by clicking the
          close icon. Once dismissed, the notification will not appear again for
          that user.
        </span>
      </v-card>
      <v-card class="mx-auto pt-8 pr-0" outlined color="transparent">
        <div class="title ads-primary-blue">Notification preview</div>
        <p class="pb-3">How users will see the notification</p>
        <Alert
          type="info"
          class="pb-2"
          in-page
          :text="communication.notification.heading"
          :subtext="communication.notification.subheading"
          :action-text="
            isCallToAction
              ? communication?.notification.callToAction.buttonText
              : ''
          "
          :action-handler="handlePreviewAction"
          background-colour
          :allow-dismiss="communication?.notification?.dismissable"
          role="none"
          @click.prevent="handlePreviewPrevent"
        >
          <template
            v-if="communication?.notification?.dismissable"
            slot="close"
          >
            <v-btn
              icon
              class="rounds-dismiss-button"
              aria-label="dismiss"
              ref="alertButton"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </Alert>
      </v-card>
      <v-card class="mx-auto pa-8 mt-1 mb-6" outlined>
        <div class="title ads-primary-blue">Placement</div>
        <p class="pb-6">Specify where schools should see the notification</p>
        <ads-select
          class="column-text-short"
          ref="currentEditSelect"
          v-model="communication.notification.placement.application"
          :items="PLACEMENT_TYPES"
          label="Application"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          placeholder="Select application"
          disabled
          :readonly="readOnlyStatus"
        />
        <read-only-select
          class="column-text-short"
          aria-label="Position"
          item-text="name"
          item-value="value"
          v-model="communication.notification.placement.position"
          :items="POSITION_TYPES"
          label="Position"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          multiple
          chips
          :placeholder="
            communication.notification?.placement?.position?.length > 0
              ? ''
              : 'Select position'
          "
          disabled
          :readonly="readOnlyStatus"
          return-object
        />
      </v-card>
      <v-card class="mx-auto pa-8 mt-1 mb-6" outlined>
        <div class="title ads-primary-blue">Audience</div>
        <p class="pb-6">Specify which schools should see the notification</p>
        <RadioGroup
          v-if="isEditing"
          class="column-text-short mt-n4"
          aria-label="Audience target to"
          v-model="communication.notification.audience.targetedSchools"
          :items="AUDIENCE_TYPES"
          required
        />
        <div
          v-else
          class="ml-1 mb-6 switch-readonly"
          :tabindex="readOnlyStatus ? '-1' : '0'"
          :readonly="readOnlyStatus"
        >
          {{
            communication.notification.audience.targetedSchools
              ? 'Targeted schools'
              : 'All schools'
          }}
        </div>
        <ads-select
          class="column-text-short"
          aria-label="Filter schools by"
          v-if="communication.notification.audience.targetedSchools"
          v-model="communication.notification.audience.filterType"
          :items="filterTypeItems"
          :label="isEditing ? 'Filter schools by*' : 'Filter schools by'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          placeholder="Select a filter type"
          :rules="validationRules.FilterSchoolsByRules"
          :readonly="readOnlyStatus"
        />
        <read-only-select
          class="column-text-short"
          aria-label="School type"
          item-text="name"
          item-value="value"
          v-if="isBySchoolType"
          v-model="communication.notification.audience.schoolTypes"
          :items="SCHOOL_TYPES"
          :label="isEditing ? 'School type*' : 'School type'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          chips
          :placeholder="
            communication.notification?.audience?.schoolTypes?.length > 0
              ? ''
              : 'Select school type'
          "
          multiple
          deletable-chips
          :rules="validationRules.schoolTypeRules"
          :readonly="readOnlyStatus"
          return-object
        />
        <ads-select
          class="column-text-short"
          aria-label="School settings"
          v-if="isBySchoolSettings"
          v-model="communication.notification.audience.schoolSettings"
          :items="settingsTypeItems"
          :label="isEditing ? 'School settings*' : 'School settings'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          placeholder="Select settings type"
          :rules="validationRules.schoolSettingRules"
          :readonly="readOnlyStatus"
        />
        <read-only-select
          class="column-text-short"
          aria-label="School name"
          item-text="schoolName"
          item-value="schoolCode"
          v-if="isBySchoolName"
          v-model="communication.notification.audience.schools"
          :items="oesSchools"
          :label="isEditing ? 'School name*' : 'School name'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          chips
          :placeholder="
            communication.notification.audience.schools?.length > 0
              ? ''
              : 'Search schools'
          "
          multiple
          deletable-chips
          :rules="validationRules.schoolNameRules"
          type="schools"
          :readonly="readOnlyStatus"
          return-object
        />
        <div class="d-flex justify-space-between download-box">
          <div class="d-flex">
            <v-icon class="mt-n1">mdi-school-outline</v-icon>
            <div class="ml-2 mt-2">
              <strong>
                <span> {{ selectedSchools?.length }} </span>
                schools
              </strong>
              will see the notification
            </div>
          </div>
          <div>
            <ads-button
              v-if="selectedSchools?.length"
              tertiary
              color="navy"
              button-text="Download csv"
              @click="handleExport"
            />
          </div>
        </div>
      </v-card>
      <v-card class="mx-auto pa-8 mt-1 mb-6" outlined>
        <div class="title ads-primary-blue">Timing</div>
        <p class="pb-6">Specify when schools should see the notification</p>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <read-only-date-picker
              ref="startDateField"
              v-model="startDate"
              class="column-text-short"
              :label="
                isEditing ? 'Publication start date*' : 'Publication start date'
              "
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              persistent-placeholder
              placeholder="Select start date"
              :rules="validationRules.startDateRules"
              :readonly="readOnlyStatus"
            />
          </v-col>
          <v-col class="ma-0 pa-0">
            <ads-select
              v-model="startTime"
              ref="startTimeField"
              class="column-text-short ml-3"
              :items="TIMING_TYPES"
              :label="
                isEditing ? 'Publication start time*' : 'Publication start time'
              "
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              persistent-placeholder
              placeholder="Select start time"
              prepend-inner-icon="mdi-timer-outline"
              :rules="validationRules.startTimeRules"
              :readonly="readOnlyStatus"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0">
            <read-only-date-picker
              ref="endDateField"
              v-model="endDate"
              class="column-text-short"
              :label="
                isEditing ? 'Publication end date*' : 'Publication end date'
              "
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              persistent-placeholder
              placeholder="Select end date"
              :rules="validationRules.endDateRules"
              :readonly="readOnlyStatus"
            />
          </v-col>
          <v-col class="ma-0 pa-0">
            <ads-select
              v-model="endTime"
              ref="endTimeField"
              class="column-text-short ml-3"
              :items="TIMING_TYPES"
              :label="
                isEditing ? 'Publication end time*' : 'Publication end time'
              "
              :is-editing="isEditing"
              is-allow-editing-mode
              :set-edit-mode="setEditMode"
              persistent-placeholder
              placeholder="Select end time"
              prepend-inner-icon="mdi-timer-outline"
              :rules="validationRules.endTimeRules"
              :readonly="readOnlyStatus"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="mx-auto pt-8 pr-0 justify-end action-card"
        outlined
        color="transparent"
      >
        &nbsp;
      </v-card>
      <v-footer padless class="form-btns" v-if="isEditing" fixed>
        <v-container fluid>
          <v-card class="rounded-0">
            <v-row justify="end">
              <v-col class="text-right pr-8">
                <ads-button
                  tertiary
                  @click="handleCancelModal"
                  color="navy"
                  button-text="Cancel"
                />
                <ads-button
                  class="ml-3"
                  secondary
                  @click="handleSave(COMMUNICATION_STATUSES.DRAFT)"
                  color="navy"
                  button-text="Save as draft"
                  :disabled="
                    !communication?.notification?.notificationId ||
                    communication?.notification?.notificationId?.length > 100 ||
                    !this.isUniqueNotificationId(
                      communication?.notification?.notificationId
                    )
                  "
                  :loading="draftBtnLoading"
                />
                <ads-button
                  class="white--text ml-3"
                  button-text="Publish notification"
                  :disabled="!isFormValid"
                  icon="send"
                  color="green"
                  @click="handleSave(COMMUNICATION_STATUSES.PUBLISHED)"
                  :loading="publishBtnLoading"
                >
                  <template #loader>
                    <v-progress-circular
                      class="icon spin button__spinner"
                      :size="20"
                      :width="4"
                      color="white"
                      indeterminate
                      aria-label="loading"
                    />
                  </template>
                </ads-button>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-footer>
    </v-form>
    <modal-dialog
      :open-dialog="showCancelModal"
      max-width="645px"
      title="Are you sure you want to leave this page?"
      icon="warning"
      :display-close-btn="true"
      aria-label-text="Close cancel modal"
      :actions="cancelActions"
      message="Any changes to your notification will not be saved."
      @close="showCancelModal = false"
      :return-handler="handleCancel"
      content-class="warning-dialog"
      btn-height="44px"
    />
    <modal-dialog
      :open-dialog="showDeleteModal"
      max-width="645px"
      title="Delete notification?"
      icon="mdi-delete-outline"
      :display-close-btn="true"
      aria-label-text="Close delete modal"
      :actions="deleteActions"
      :message="`If you proceed, the notification '<b>${communication?.notification?.notificationId}</b>' will be deleted from the system.`"
      @close="showDeleteModal = false"
      :return-handler="handleDelete"
      content-class="error-dialog"
      btn-height="44px"
      :is-loading="communicationDialogLoading"
    />
    <modal-dialog
      :open-dialog="showRemoveModal"
      max-width="645px"
      title="Remove notification?"
      icon="mdi-close-circle"
      :display-close-btn="true"
      aria-label-text="Close remove modal"
      :actions="removeActions"
      :message="`If you proceed, the notification '<b>${communication?.notification?.notificationId}</b>' will no longer be visible to schools.`"
      @close="showRemoveModal = false"
      :return-handler="handleRemove"
      content-class="error-dialog"
      btn-height="44px"
      :is-loading="communicationsLoading"
    />
  </div>
</template>

<script>
import {
  Alert,
  AdsTextField,
  AdsButton,
  AdsTextArea,
  AdsSelect
} from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import lodash from 'lodash'
import {
  ROUTE_PATH,
  PLACEMENT_TYPES,
  POSITION_TYPES,
  FILTER_TYPES,
  AUDIENCE_TYPES,
  SCHOOL_TYPES,
  SCHOOL_SETTINGS_TYPES,
  TIMING_TYPES,
  COMMUNICATION_STATUSES
} from '@/constants'
import doeColors from '@/settings/doe-colors'
import OesSwitch from '@/components/OesSwitch.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import ReadOnlySelect from '@/components/ReadOnlySelect.vue'
import ReadOnlyDatePicker from '@/components/ReadOnlyDatePicker'
import RadioGroup from '@/components/RadioGroup'
import { upperCaseNoSpaceString } from '@/utils/generalUtils'

const currentDateTime = new Date().toLocaleString('en-GB')
const currentDateTimeSplit = currentDateTime.split(', ')
const currentDate = currentDateTimeSplit[0]
const currentTimeStamp = currentDateTimeSplit[1]
const currentTimeStampSplit = currentTimeStamp.split(':')
const currentTimeHours =
  currentTimeStampSplit[0] > 19 ? '19:00' : currentTimeStampSplit[0] + ':00'

export default {
  name: 'CommunicationDetails',
  components: {
    Alert,
    AdsTextField,
    AdsButton,
    ModalDialog,
    AdsTextArea,
    AdsSelect,
    OesSwitch,
    ReadOnlySelect,
    ReadOnlyDatePicker,
    RadioGroup
  },
  props: {
    cStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    const self = this
    return {
      communication: {
        notification: {
          notificationId: null,
          heading: null,
          subheading: null,
          callToAction: this.isCallToAction
            ? {
                buttonText: null,
                URL: null
              }
            : {},
          dismissable: true,
          placement: {
            application: PLACEMENT_TYPES[0],
            position: [POSITION_TYPES[0]]
          },
          audience: {
            targetedSchools: false,
            filterType: null,
            schoolTypes: [],
            schoolSettings: null,
            schools: []
          },
          timing: {
            startDateTime: '',
            endDateTime: ''
          }
        }
      },
      cancelActions: [
        {
          name: 'Proceed with cancel',
          color: doeColors.primary.red,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Continue editing',
          color: doeColors.primary.navy,
          size: 'large',
          outlined: false
        }
      ],
      deleteActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Delete notification',
          color: doeColors.primary.red,
          size: 'large',
          outlined: false,
          icon: 'mdi-delete-outline',
          progress: true
        }
      ],
      removeActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Remove notification',
          color: doeColors.primary.red,
          size: 'large',
          outlined: false,
          icon: 'mdi-close-circle',
          progress: true
        }
      ],
      showCancelModal: false,
      showDeleteModal: false,
      showRemoveModal: false,
      isFormValid: false,
      uniqueNotificationIds: [],
      validationRules: {
        required: [(value) => !!value || 'Required'],
        notificationsIdRules: [
          (value) => !!value || 'Required',
          (value) =>
            self.isUniqueNotificationId(value) ||
            'The notification ID must be unique',
          (value) =>
            (value && value.length <= 100) ||
            'Notification ID should be 100 characters or less'
        ],
        headingRules: [
          (value) => !!value || 'Required',
          (value) =>
            (value && value.length <= 150) ||
            'Heading should be 150 characters or less'
        ],
        subheadingRules: [
          (value) =>
            value.length <= 200 || 'Subheading should be 200 characters or less'
        ],
        buttonTextRules: [
          (value) => !!value || 'Required',
          (value) =>
            (value && value.length <= 50) ||
            'Call to action text should be 50 characters or less'
        ],
        urlRules: [
          (value) => !!value || 'Required',
          (value) => self.isValidURL(value) || 'Valid URL is required'
        ],
        FilterSchoolsByRules: [
          (value) =>
            (value && value?.length > 0) ||
            'Select how you want to filter schools'
        ],
        schoolTypeRules: [
          (value) =>
            (value && value?.length > 0) || 'Select at least one school type'
        ],
        schoolSettingRules: [
          (value) => !!value || 'Select at least one school setting'
        ],
        schoolNameRules: [
          (value) =>
            (value && value?.length > 0) || 'Select at least one school'
        ],
        startDateRules: [
          (value) => !!value || 'Select a start  date',
          (value) =>
            self.isThisPastDate(value) || 'Date must not be in the past',
          (value) =>
            self.isThisAfterStartDate(value) ||
            'The start date must not occur after the end date'
        ],
        startTimeRules: [
          (value) => !!value || 'Select a start time',
          (value) =>
            self.isThisAfterStartDate(value) ||
            'The start time must not occur after the end time'
        ],
        endDateRules: [
          (value) => !!value || 'Select an end date',
          (value) =>
            self.isThisPastDate(value) || 'Date must not be in the past',
          (value) =>
            self.isThisAfterStartDate(value) ||
            'The end date must not occur before the start date',
          (value) =>
            self.isThisValidDuration(value) ||
            'End date must be within three weeks of the start date'
        ],
        endTimeRules: [
          (value) => (!!value && value != '00:00') || 'Select an end time',
          (value) =>
            self.isThisAfterStartDate(value) ||
            'The end time must not occur before the start time'
        ]
      },
      isCreateMode: false,
      isCallToAction: false,
      PLACEMENT_TYPES,
      POSITION_TYPES,
      FILTER_TYPES,
      AUDIENCE_TYPES,
      SCHOOL_TYPES,
      SCHOOL_SETTINGS_TYPES,
      TIMING_TYPES,
      COMMUNICATION_STATUSES,
      startDate: currentDate,
      startTime: currentTimeHours,
      endDate: '',
      endTime: '00:00',
      status: null,
      filterTypeItems: [
        FILTER_TYPES.SCHOOL_NAME,
        FILTER_TYPES.SCHOOL_TYPE,
        FILTER_TYPES.SCHOOL_SETTINGS
      ],
      settingsTypeItems: [
        SCHOOL_SETTINGS_TYPES.SRESEE_CONFIG,
        SCHOOL_SETTINGS_TYPES.SRESEE_NOT_CONFIG,
        SCHOOL_SETTINGS_TYPES.CAP_NOT_CONFIG
      ],
      noOfAffectedSchools: this.oesSchools?.length
    }
  },
  computed: {
    ...mapGetters([
      'communicationEditMode',
      'communicationById',
      'communicationsLoading',
      'communicationDialogLoading',
      'oesSchools',
      'communicationUniqueIds'
    ]),
    isEditing() {
      return !!this.communicationEditMode
    },
    draftBtnLoading() {
      return (
        !!this.communicationsLoading &&
        this.status === COMMUNICATION_STATUSES.DRAFT
      )
    },
    publishBtnLoading() {
      return !!this.communicationsLoading && !this.draftBtnLoading
    },
    isBySchoolName() {
      return (
        this.communication.notification.audience.targetedSchools &&
        this.communication.notification.audience.filterType ===
          FILTER_TYPES.SCHOOL_NAME
      )
    },
    isBySchoolType() {
      return (
        this.communication.notification.audience.targetedSchools &&
        this.communication.notification.audience.filterType ===
          FILTER_TYPES.SCHOOL_TYPE
      )
    },
    isBySchoolSettings() {
      return (
        this.communication.notification.audience.targetedSchools &&
        this.communication.notification.audience.filterType ===
          FILTER_TYPES.SCHOOL_SETTINGS
      )
    },
    selectedSchools() {
      let filteredSchools = this.oesSchools
      if (this.isBySchoolName) {
        const schoolsArray =
          this.communication.notification.audience?.schools.map(function (s) {
            return s.schoolCode
          })
        filteredSchools = this.oesSchools?.filter((s) =>
          schoolsArray?.includes(s.schoolCode)
        )
      } else if (this.isBySchoolType) {
        const schoolTypesArray =
          this.communication.notification.audience?.schoolTypes.map(function (
            s
          ) {
            return s.value
          })
        filteredSchools = this.oesSchools?.filter((s) =>
          schoolTypesArray?.includes(s.schoolType)
        )
      } else if (this.isBySchoolSettings) {
        const schoolSettings =
          this.communication.notification.audience?.schoolSettings
        if (schoolSettings === SCHOOL_SETTINGS_TYPES.CAP_NOT_CONFIG) {
          filteredSchools = this.oesSchools?.filter((s) => s.capStatus === 'NA')
        } else if (schoolSettings === SCHOOL_SETTINGS_TYPES.SRESEE_CONFIG) {
          filteredSchools = this.oesSchools?.filter(
            (s) => s.sreSeeSettingsEnabled === true
          )
        } else if (schoolSettings === SCHOOL_SETTINGS_TYPES.SRESEE_NOT_CONFIG) {
          filteredSchools = this.oesSchools?.filter(
            (s) => s.sreSeeSettingsEnabled === false
          )
        } else {
          filteredSchools = []
        }
      } else if (this.communication.notification.audience.targetedSchools) {
        filteredSchools = []
      }
      return filteredSchools
    },
    readOnlyStatus() {
      const status = this.cStatus
      if (status) {
        return [
          COMMUNICATION_STATUSES.ACTIVE,
          COMMUNICATION_STATUSES.EXPIRED,
          COMMUNICATION_STATUSES.REMOVED
        ].includes(status)
      }
      return false
    },
    showDeleteBtn() {
      const status = this.cStatus
      if (status) {
        return (
          [
            COMMUNICATION_STATUSES.DRAFT,
            COMMUNICATION_STATUSES.SCHEDULED
          ].includes(status) && !this.isEditing
        )
      }
      return false
    },
    showRemoveBtn() {
      const status = this.cStatus
      return [COMMUNICATION_STATUSES.ACTIVE].includes(status) && !this.isEditing
    }
  },
  beforeCreate() {
    this.$store.commit('setCommunicationDialogLoading', false)
  },
  mounted() {
    const routePath = this.$router.currentRoute.path
    if (routePath === ROUTE_PATH.COMMUNICATION.create) {
      this.isCreateMode = true
      this.$store.commit('setCommunicationEditMode', true)
      this.uniqueNotificationIds = this.communicationUniqueIds('')
    } else {
      const itemId = this.$route.params.id
      if (itemId) {
        const item = this.communicationById(itemId)
        if (item) {
          const clonedCommunication = lodash.cloneDeep(item)
          const timing = clonedCommunication.notification.timing
          this.startDate = timing.startDateTime
            ? this.dateSplit(timing.startDateTime)
            : ''
          this.startTime = timing.startDateTime
            ? this.timeFormat(this.timeSplit(timing.startDateTime))
            : ''
          this.endDate = timing.endDateTime
            ? this.dateSplit(timing.endDateTime)
            : ''
          this.endTime = timing.endDateTime
            ? this.timeFormat(this.timeSplit(timing.endDateTime))
            : ''
          const callToAction = clonedCommunication.notification.callToAction
          if (callToAction?.URL || callToAction?.buttonText) {
            this.isCallToAction = true
          }
          this.noOfAffectedSchools = clonedCommunication.noOfAffectedSchools
          clonedCommunication.notification.audience = {
            ...clonedCommunication.notification.audience,
            ...(() => {
              const audience = clonedCommunication.notification.audience
              return {
                schools: audience?.schools
                  ? this.oesSchools?.filter((s) =>
                      audience?.schools.includes(s.schoolCode)
                    )
                  : [],
                schoolTypes: audience?.schoolTypes
                  ? SCHOOL_TYPES.filter((s) =>
                      audience?.schoolTypes.includes(s.value)
                    )
                  : []
              }
            })()
          }
          clonedCommunication.notification.placement = {
            ...clonedCommunication.notification.placement,
            ...(() => {
              const placement = clonedCommunication.notification.placement
              return {
                position: placement?.position
                  ? POSITION_TYPES?.filter((p) =>
                      placement?.position.includes(p.value)
                    )
                  : []
              }
            })()
          }
          this.communication = {
            uuid: itemId,
            notification: clonedCommunication.notification
          }
        }
        this.uniqueNotificationIds = this.communicationUniqueIds(
          item.notification.notificationId
        )
      }
    }
  },
  watch: {
    startTime() {
      this.revalidateDateTimeFields('startTime')
    },
    endTime() {
      this.revalidateDateTimeFields('endTime')
    },
    startDate() {
      this.revalidateDateTimeFields('startDate')
    },
    endDate() {
      this.revalidateDateTimeFields('endDate')
    }
  },
  methods: {
    async setEditMode(ref) {
      if (ref && !this.readOnlyStatus) {
        this.$store.commit('setCommunicationEditMode', true)
        await this.$nextTick()
        this.$refs.startDateField.triggerBlur()
        this.$refs.endDateField.triggerBlur()
      }
    },

    timingVuetifyRefs() {
      const [vStartDate] = Object.values(
        this.$refs.startDateField.$refs.roDatePicker.$refs
      )
      const [vEndDate] = Object.values(
        this.$refs.endDateField.$refs.roDatePicker.$refs
      )
      const vStartTime = Object.values(this.$refs.startTimeField.$refs).find(
        (r) => r?.$el?.className.includes('ads-select')
      )
      const vEndTime = Object.values(this.$refs.endTimeField.$refs).find((r) =>
        r?.$el?.className.includes('ads-select')
      )
      return { vStartDate, vEndDate, vStartTime, vEndTime }
    },
    handleCancelModal() {
      this.showCancelModal = true
    },
    async handleCancel(ref) {
      if (ref === 'Continue editing') {
        this.showCancelModal = false
      } else if (ref === 'Proceed with cancel') {
        await this.$router.push('/communications')
      }
    },
    handleDeleteModal() {
      this.showDeleteModal = true
    },
    async handleDelete(ref) {
      if (ref === 'Cancel') {
        this.showDeleteModal = false
      } else if (ref === 'Delete notification') {
        this.$store
          .dispatch('deleteCommunication', this.communication)
          .then((dispatchSucceeded) => {
            this.showDeleteModal = false
            if (dispatchSucceeded) {
              this.$router.push('/communications')
            }
          })
      }
    },
    revalidateDateTimeFields(e) {
      e !== 'startTime' && this.timingVuetifyRefs().vStartTime?.validate()
      e !== 'endTime' && this.timingVuetifyRefs().vEndTime?.validate()
      e !== 'startDate' && this.timingVuetifyRefs().vStartDate?.validate()
      e !== 'endDate' && this.timingVuetifyRefs().vEndDate?.validate()
    },
    handleRemoveModal() {
      this.showRemoveModal = true
    },
    handleRemove(ref) {
      if (ref === 'Cancel') {
        this.showRemoveModal = false
      } else if (ref === 'Remove notification') {
        this.handleSave(COMMUNICATION_STATUSES.REMOVED)
      }
    },
    async handleSave(status) {
      this.status = status
      const clonedCommunication = lodash.cloneDeep(this.communication)
      clonedCommunication.notification.status = status
      if (status !== COMMUNICATION_STATUSES.REMOVED) {
        clonedCommunication.notification.timing.startDateTime = this.startDate
          ? this.startDate + ' ' + this.startTime + ':00'
          : ''
        clonedCommunication.notification.timing.endDateTime = this.endDate
          ? this.endDate + ' ' + this.endTime + ':00'
          : ''
      }
      clonedCommunication.notification.audience = {
        ...clonedCommunication.notification.audience,
        ...(() => {
          const audience = clonedCommunication.notification.audience
          if (this.isBySchoolName) {
            return {
              schools: audience?.schools
                ? audience.schools.map(function (s) {
                    return s.schoolCode
                  })
                : [],
              schoolTypes: [],
              schoolSettings: null
            }
          } else if (this.isBySchoolType) {
            return {
              schoolTypes: audience?.schoolTypes
                ? audience.schoolTypes.map(function (s) {
                    return s.value
                  })
                : [],
              schools: [],
              schoolSettings: null
            }
          } else if (this.isBySchoolSettings) {
            return {
              schoolTypes: [],
              schools: []
            }
          }
          return {
            filterType: null,
            schoolTypes: [],
            schoolSettings: null,
            schools: []
          }
        })()
      }
      clonedCommunication.notification.placement = {
        ...clonedCommunication.notification.placement,
        ...(() => {
          const placement = clonedCommunication.notification.placement
          return {
            position: placement?.position
              ? placement.position.map(function (p) {
                  return p.value
                })
              : []
          }
        })()
      }
      this.$store
        .dispatch(
          `${this.isCreateMode ? 'create' : 'update'}Communication`,
          clonedCommunication
        )
        .then((dispatchSucceeded) => {
          if (dispatchSucceeded) {
            this.$store.commit('setCommunicationBackIndex', 0)
            this.$router.push('/communications')
          }
        })
    },
    handlePreviewAction() {
      const URL = this.communication?.notification.callToAction.URL
      window.open(URL, '_blank')
    },
    handlePreviewPrevent(event) {
      event.preventDefault()
    },
    isUniqueNotificationId(value) {
      if (value) {
        const upperCaseName = upperCaseNoSpaceString(value)
        if (this.uniqueNotificationIds.includes(upperCaseName)) {
          return false
        }
      }
      return true
    },
    isValidURL(url) {
      if (url) {
        let res = url.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
        )
        return url.trim() !== '' && res !== null && url.startsWith('https://')
      } else {
        return true
      }
    },
    isThisAfterStartDate(value) {
      if (
        value &&
        this.startDate &&
        this.startTime &&
        this.endDate &&
        this.endTime != '00:00'
      ) {
        const startDateTime = this.startDate + ' ' + this.startTime
        const endDateTime = this.endDate + ' ' + this.endTime
        if (startDateTime < endDateTime) {
          return true
        }
        return false
      } else if (this.startDate && this.endDate) {
        if (value && this.startDate <= this.endDate) {
          return true
        }
        return false
      } else {
        return true
      }
    },
    isThisValidDuration(value) {
      if (value && this.startDate && this.endDate) {
        const date1 = new Date(this.startDate)
        const date2 = new Date(this.endDate)
        const durationDays = parseInt(
          (date2 - date1) / (1000 * 60 * 60 * 24),
          10
        )
        if (durationDays > 20) {
          return false
        }
        return true
      }
      return true
    },
    dateFormat(value) {
      if (value) {
        return value.split('/').reverse().join('-')
      }
    },
    timeFormat(value) {
      if (value) {
        return value.split(':')[0] + ':' + value.split(':')[1]
      }
      return '-'
    },
    dateSplit(value) {
      return value.split(' ')[0]
    },
    timeSplit(value) {
      return value.split(' ')[1]
    },
    isThisPastDate(value) {
      if (value) {
        const date1 = new Date(this.dateFormat(value)).toDateString('en-GB')
        const date2 = new Date().toDateString('en-GB')
        if (new Date(date1) < new Date(date2)) {
          return false
        }
        return true
      }
      return true
    },
    async handleExport() {
      const refinedData = []
      const csvTitles = ['School name', 'School code', 'School type']
      refinedData.push(csvTitles)
      const actualData = this.selectedSchools?.map((school) => {
        const schoolName = '"' + school.schoolName + '"'
        const schoolCode = school.schoolCode
        const schoolType = school.schoolType
        return {
          schoolName,
          schoolCode,
          schoolType
        }
      })
      if (actualData?.length > 0) {
        actualData.forEach((item) => {
          refinedData.push(Object.values(item))
        })
        let csvContent = ''
        refinedData.forEach((row) => {
          csvContent += row.join(',') + '\n'
        })
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8,'
        })
        const objUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', objUrl)
        let name = 'Schools list {YYYYMMDD}.csv'
        const todayDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replaceAll('-', '')
        name = name.replace('{YYYYMMDD}', todayDate)
        link.setAttribute('download', name)
        link.target = '_blank'
        link.click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.communication-details {
  .title {
    margin-bottom: 12px;
  }
  div.v-card.action-card {
    margin-bottom: 48px !important;
  }
  div.v-card {
    width: 645px;
  }
  div.ChipLabel {
    font-size: 1rem !important;
  }
  span.ChipDetails {
    font-size: 14px;
    border-color: #495054;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    height: 29px;
    box-sizing: border-box;
    /* Auto layout */

    flex-direction: row;
    justify-content: center;
    gap: 10px;

    /* Greys / White */
    background: #ffffff;

    /* Greys/Grey 02 */
    border: 1px solid #495054;
    border-radius: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .v-btn.remove-communication:focus {
    border: 1px solid $ads-grey-01 !important;
  }

  .form-btns .v-card {
    margin-top: auto;
    width: 100%;
    .v-card {
      width: 100% !important;
      box-shadow: 0 3px 23px rgba(0, 0, 0, 0.15);
    }
  }
  ::v-deep .alertElement {
    min-height: 62px;
  }
  ::v-deep .alertElement:focus {
    border: none !important;
    outline: 3px solid $color-primary;
  }
  .dismissible-message {
    font-size: 14px;
  }
  ::v-deep .theme--light.v-input input::placeholder {
    color: $color-placeholder !important;
  }
  ::v-deep .theme--light.v-input textarea::placeholder {
    color: $color-placeholder !important;
  }
  .download-box {
    background-color: rgb(229, 246, 254);
    border-radius: 4px;
    padding: 10px 10px;
    color: $ads-primary-blue;
    min-height: 61px;
  }
  .switch-readonly {
    font-weight: 700;
    color: $ads-primary-blue;
  }
}

::v-deep .displayField {
  margin: 10px 0 20px 5px;

  p {
    margin-bottom: 8px !important;
  }
}

::v-deep input::placeholder {
  color: $ads-grey-01 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
::v-deep .oesSwitch {
  .theme--light.v-label {
    color: $color-primary !important;
    font-weight: 700;
  }
}
</style>
