const messageBox = {
  state: {
    messageBox: null
  },
  getters: {
    messageBox: (state) => state.messageBox
  },
  mutations: {
    setMessageBox: (state, value) => {
      state.messageBox = value
    }
  },
  actions: {
    showMessageBox(ctx, payload) {
      ctx.commit('setMessageBox', payload)
    },
    hideMessageBox(ctx) {
      ctx.commit('setMessageBox', null)
    }
  }
}

export default messageBox
