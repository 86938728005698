<template>
  <v-dialog
    ref="dialog"
    scrollable
    :value="value"
    v-bind="attrs"
    v-on="$listeners"
  >
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-card class="modal__content">
      <template>
        <v-card-title v-if="includeCloseButton" class="modal__banner">
          <v-btn
            class="modal__close-button"
            icon
            aria-label="close"
            @click="closeModal"
          >
            <v-icon> close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text ref="body" class="modal__body" tabindex="0">
          <slot />
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    includeCloseButton: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        'max-width': '900px'
      }
      return { ...defaultProps, ...this.$attrs }
    }
  },
  methods: {
    closeModal() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__title.modal__banner {
  padding: 0.25rem;
  justify-content: flex-end;
  border-bottom: none;
}
.v-card.modal__content .modal__body {
  padding: 0 2rem 2rem 2rem;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  border-top: none;
  &:first-child {
    padding: 2rem;
  }
}
</style>
