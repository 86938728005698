<template>
  <div>
    <ApiError
      v-if="apiErrors.includes(getErrorSubtype('fetchOptions'))"
      dispatch="fetchOptions"
    />
    <div v-else class="optionList lighten-6">
      <alert
        v-if="hasProviderErrors"
        in-page
        :text="providerErrorText"
        :subtext="providerErrorSubtext"
        type="error"
        class="pb-2 providerAlertBox"
      />
      <v-row class="pb-3 d-flex align-center">
        <v-col cols="6" class="pt-1 row-align-center">
          <span class="filterLabel">Filter by:</span>
          <span class="pl-3">
            <list-view-filter
              ref="typeFilter"
              label="Type"
              type="checkbox"
              :filter-options="typeFilters"
              @updatefilter="updateTypeFilter"
              width="366px"
              height="210px"
            />
          </span>
          <span class="pl-3">
            <list-view-filter
              ref="providerFilter"
              label="Provider(s)"
              type="checkbox"
              :filter-options="providersFilters"
              @updatefilter="updateProvidersFilter"
              width="376px"
              height="251px"
            />
          </span>
          <span class="pl-3">
            <list-view-filter
              ref="statusFilter"
              label="Status"
              type="checkbox"
              :filter-options="statusFilters"
              @updatefilter="updateStatusFilter"
              width="258px"
              height="210px"
            />
          </span>
          <clear-filters @click="clearAllFilters()" v-if="isFiltered" />
        </v-col>
        <v-col cols="6" class="pt-0 pr-0 text-right">
          <span class="pl-3 pr-3">
            <ads-button
              class="mr-0"
              secondary
              icon="mdi-export-variant"
              button-text="Export csv"
              @click="handleExport"
            />
          </span>
          <span class="pl-3">
            <ads-button
              class="mr-0"
              icon="add_circle_outline"
              button-text="New option"
              @click="createOption"
            />
          </span>
        </v-col>
      </v-row>
      <v-card>
        <ads-data-table
          v-show="!isLoading"
          v-model="selectedIds"
          :headers="headers"
          :items="optionList"
          search-label="Search SRE/SEE options"
          :search="searchValue"
          :no-results-found="'No results found. Try removing some filters or clearing the keyword search.'"
          :no-data-text="'No results found. Try removing some filters or clearing the keyword search.'"
          item-key="id"
          :items-per-page="pageLength"
          :footer-props="{
            'items-per-page-options': [10, 15, 50]
          }"
          @pagination="totalResults"
          :custom-filter="customSearch"
          :sort-by="'title'"
          must-sort
          ref="optionDataTable"
        >
          <template #top>
            <v-row class="row-line, pb-4" align="center">
              <v-col>
                <v-text-field
                  class="searchInput pl-2"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchValue"
                  label="Search SRE/SEE options"
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="auto" class="pr-6 align-self-end body-1 result-text">
                <span>
                  {{ resultsText(itemsLength) }}
                </span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.providers`]="{ item }">
            <div
              class="column-text-short"
              :class="[
                isNoneSpecifiedProvider(item.providers) ? 'error-highlight' : ''
              ]"
            >
              {{ item.providers }}
            </div>
          </template>
          <template #[`item.description`]="{ item }">
            <div class="column-text-short">
              {{ item.description }}
            </div>
          </template>
          <template #[`item.status`]="{ item }">
            <chip v-bind="getStatusChipDetails(item.status)" small />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon color="#002664" class="mr-2" @click="showItem(item)">
              mdi-eye-outline
            </v-icon>
          </template>
        </ads-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Chip, AdsButton, AdsDataTable, Alert } from '@nswdoe/doe-ui-core'
import {
  OPTION_PROVIDER_ERROR,
  OPTION_STATUS_CHIPS,
  OPTION_TYPE,
  OPTION_TYPE_NAME,
  OPTION_STATUS,
  API_ERROR_SUBTYPES
} from '@/constants'
import { mapGetters } from 'vuex'
import ListViewFilter from '@/components/ListViewFilter'
import ClearFilters from '@/components/ClearFilters'
import { generateOptionsCSVExportData } from '@/utils/generalUtils'
import ApiError from '@/components/ApiError.vue'
import lodash from 'lodash'

export default {
  name: 'OptionList',
  data() {
    return {
      selectedIds: [],
      searchValue: '',
      pageLength: 50,
      itemsLength: 0,
      providerErrorText: 'SRE/SEE provider error',
      providerErrorSubtext:
        'One or more SRE/SEE options need updating due to a change in your Provider settings.',
      isMounted: false
    }
  },
  components: {
    AdsDataTable,
    Chip,
    AdsButton,
    ListViewFilter,
    ClearFilters,
    Alert,
    ApiError
  },
  computed: {
    ...mapGetters([
      'apiErrors',
      'options',
      'optionsWithFlatProviders',
      'optionsFiltered',
      'optionsLoading',
      'optionStatusFilter',
      'optionTypeFilter',
      'optionProviderFilter'
    ]),
    headers() {
      return [
        {
          text: 'Option title',
          value: 'title',
          class: 'option-title',
          align: 'start',
          width: '106px'
        },
        {
          text: 'Type',
          value: 'type',
          class: 'type',
          align: 'start',
          width: '51px'
        },
        {
          text: 'Provider(s)',
          value: 'providers',
          class: 'providers',
          align: 'start',
          width: '205px'
        },
        {
          text: 'Description',
          value: 'description',
          class: 'description',
          align: 'start',
          width: '285px'
        },
        {
          text: 'Last updated',
          value: 'updatedDate',
          class: 'last-updated',
          align: 'start',
          width: '97px'
        },
        {
          text: 'Status',
          value: 'status',
          class: 'status text-wrap',
          align: 'start',
          width: '100'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          class: 'action',
          align: 'start',
          width: '80px'
        }
      ]
    },
    optionList() {
      return this.optionsFiltered
    },
    statusFilters() {
      const options = this.optionsWithFlatProviders
      if (Array.isArray(options) && options.length > 0) {
        const uniqueStatusList = [
          ...new Set(options.map((item) => item.status))
        ]
        const itemOrder = [
          OPTION_STATUS.PUBLISHED,
          OPTION_STATUS.DRAFT,
          OPTION_STATUS.ERROR
        ]
        uniqueStatusList.sort(
          (a, b) => itemOrder.indexOf(a) - itemOrder.indexOf(b)
        )
        return uniqueStatusList
      }
      return []
    },
    providersFilters() {
      const options = this.options
      if (Array.isArray(options) && options.length > 0) {
        let providerNames = []
        options.forEach((item) => {
          if (item && item.providers) {
            if (Array.isArray(item.providers) && item.providers.length > 0) {
              const names = item.providers.map((optionItem) => optionItem.name)
              if (names) {
                providerNames = providerNames.concat(names)
              }
            }
          }
        })
        return [...new Set(providerNames)].sort()
      }
      return []
    },
    typeFilters() {
      const options = this.options
      if (Array.isArray(options) && options.length > 0) {
        return [
          ...new Set(
            options.map((item) => {
              if (item.type === OPTION_TYPE.SRE) {
                return OPTION_TYPE_NAME.SRE
              } else if (item.type === OPTION_TYPE.SEE) {
                return OPTION_TYPE_NAME.SEE
              } else if (item.type === OPTION_TYPE.AMA) {
                return OPTION_TYPE_NAME.AMA
              }
            })
          )
        ].sort((a, b) => {
          if (a && b) {
            const aUpperCase = a.toUpperCase()
            const bUpperCase = b.toUpperCase()
            if (aUpperCase < bUpperCase) {
              return 1
            }
            if (aUpperCase > bUpperCase) {
              return -1
            }
          }
          return 0
        })
      }
      return []
    },
    isFiltered() {
      return (
        this.optionTypeFilter.length ||
        this.optionProviderFilter.length ||
        this.optionStatusFilter.length
      )
    },
    isLoading() {
      return !!this.optionsLoading
    },
    hasProviderErrors: {
      cache: false,
      get: function () {
        if (this.isMounted && this.$refs.optionDataTable) {
          const filteredOptions =
            this.$refs.optionDataTable?.$refs?.vDataTable?.$children[0]
              ?.filteredItems
          if (filteredOptions) {
            const erroredResults =
              filteredOptions?.filter((option) => option.status === 'Error') ||
              []
            if (erroredResults.length > 0) {
              return true
            }
          }
        }
        return false
      }
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    getErrorSubtype(code) {
      return API_ERROR_SUBTYPES[code]
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    getStatusChipDetails(status) {
      if (status) {
        return OPTION_STATUS_CHIPS[status]
      }
      return ''
    },
    totalResults(pagination) {
      this.itemsLength = pagination ? pagination.itemsLength : 0
    },
    updateStatusFilter(selectedStatus) {
      this.$store.commit('setOptionStatusFilter', selectedStatus)
    },
    updateProvidersFilter(selectedProvider) {
      this.$store.commit('setOptionProviderFilter', selectedProvider)
    },
    updateTypeFilter(selectedTypes) {
      if (Array.isArray(selectedTypes) && selectedTypes.length > 0) {
        const types = selectedTypes.map((type) => {
          if (type === OPTION_TYPE_NAME.SRE) {
            return OPTION_TYPE.SRE
          } else if (type === OPTION_TYPE_NAME.SEE) {
            return OPTION_TYPE.SEE
          } else if (type === OPTION_TYPE_NAME.AMA) {
            return OPTION_TYPE.AMA
          }
        })
        this.$store.commit('setOptionTypeFilter', types)
      } else {
        this.$store.commit('setOptionTypeFilter', [])
      }
    },
    showItem(item) {
      this.$store.commit('setOptionEditMode', false)
      this.$store.commit('setOptionBackButtonIndex', 1)
      this.$router.push(`/option/edit/${item.id}`)
    },
    customSearch(value, search, item) {
      let isItemTitleIncluded = false
      let isTypeIncluded = false
      let isProviderIncluded = false
      let isDescriptionIncluded = false
      let isStatusIncluded = false
      let isUpdateDateIncluded = false
      if (item) {
        const searchKey = search.toLowerCase()
        if (item.title) {
          isItemTitleIncluded = item.title.toLowerCase().includes(searchKey)
        }
        if (item.type) {
          isTypeIncluded = item.type.toLowerCase().includes(searchKey)
        }
        if (item.providers) {
          isProviderIncluded = item.providers.toLowerCase().includes(searchKey)
        }
        if (item.description) {
          isDescriptionIncluded = item.description
            .toLowerCase()
            .includes(searchKey)
        }
        if (item.status) {
          isStatusIncluded = item.status.toLowerCase().includes(searchKey)
        }
        if (item.updatedDate) {
          isUpdateDateIncluded = item.updatedDate.includes(searchKey)
        }
      }
      return (
        isItemTitleIncluded ||
        isTypeIncluded ||
        isProviderIncluded ||
        isDescriptionIncluded ||
        isStatusIncluded ||
        isUpdateDateIncluded
      )
    },
    clearAllFilters() {
      this.$refs.statusFilter.clearFilter()
      this.$refs.providerFilter.clearFilter()
      this.$refs.typeFilter.clearFilter()
    },
    isNoneSpecifiedProvider(item) {
      return OPTION_PROVIDER_ERROR.NONE_SPECIFIED === item
    },
    createOption() {
      this.$store.commit('setOptionEditMode', true)
      this.$store.commit('setOptionBackButtonIndex', 1)
      this.$router.push('/option/new')
    },
    async handleExport() {
      const filteredOptions =
        this.$refs.optionDataTable?.$refs?.vDataTable?.$children[0]
          .filteredItems
      const allOptions = this.options
      const intersectedArray = lodash.intersectionBy(
        allOptions,
        filteredOptions,
        'id'
      )
      const csvContent = generateOptionsCSVExportData(intersectedArray)
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
      const objUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', objUrl)
      let name = 'Option SMI list {YYYYMMDD}.csv'
      const todayDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replaceAll('-', '')
      name = name.replace('{YYYYMMDD}', todayDate)
      link.setAttribute('download', name)
      link.target = '_blank'
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.optionList {
  overflow: auto;
  min-width: 768px;
  min-height: 100%;
  width: 100%;
  padding: 0.5rem 0.2rem;
}

::v-deep
  .providerAlertBox
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  .d-flex
  .flex-column
  .snackbar--text {
  font-style: normal;
  font-family: 'Public Sans';
  line-height: 24px;
  color: $ads-grey-01;
}

::v-deep
  .providerAlertBox
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  .d-flex
  .flex-column
  .alert--textsimple {
  font-style: normal;
  font-family: 'Public Sans';
  line-height: 24px;
  color: $ads-grey-01;
  font-weight: 400;
}
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    tr {
      height: 64px;
    }
    th {
      white-space: nowrap;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }
    th[scope='col'] {
      font-size: 16px;
      font-weight: normal;
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      .v-data-table-header__icon {
        margin-left: 3px;
      }
    }
    th[scope='col'].active {
      font-weight: bold;
    }

    th[scope='col'].provider-name {
      padding-left: 1px !important;
    }

    th:first-child {
      vertical-align: middle;
      width: 10px !important;
      min-width: 10px !important;
      padding-right: 4px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    th:last-child {
      cursor: not-allowed;
      button.headerBtn {
        cursor: not-allowed;
        display: none;
      }
    }

    th.sortable.active .v-data-table-header__icon {
      color: $ads-primary-blue;
    }
  }
  tbody {
    tr {
      height: 64px;
    }
    td {
      vertical-align: top;
      overflow-wrap: break-word;
      border-bottom: 0 !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    td:first-child {
      vertical-align: top;
      width: 10px !important;
      min-width: 10px !important;
      padding-right: 1px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    td:nth-child(2) {
      min-width: 4px !important;
    }

    td:last-child {
      border-bottom: 1px solid white;
    }
    td span.v-chip__content {
      width: 117px;
      display: inline-grid;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
    }
  }

  div.v-data-footer {
    font-size: 14px;
    height: 64px;
  }

  .row-line {
    border-bottom: 1px solid #e0e0e0;
    margin: 0 5px 10px 0;
    padding: 0;
    width: 100%;
    height: 64px;
  }

  .results-text {
    margin: 25px 20px 0 0 !important;
  }

  .row-align-center {
    line-height: 200px;
  }

  .column-text-short {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .error-highlight {
    color: $ads-primary-red;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .result-text {
    line-height: 32px;
  }
}
</style>
