<template>
  <div class="content">
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start" v-if="isEditing">
              <v-icon size="40px" class="banner__icon mr-3"
                >mdi-pencil-outline</v-icon
              >
              <div>
                <div class="banner__title display-1">Create/Edit Provider</div>
              </div>
            </v-col>
            <v-col cols="8" class="d-flex align-start" v-else>
              <v-icon size="40px" class="banner__icon mr-3"> star </v-icon>
              <div>
                <div class="banner__title display-1">Provider details</div>
              </div>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>
    <div class="mainContent">
      <NavBar />
      <div class="card">
        <provider-details />
      </div>
    </div>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core'
import NavBar from '@/components/NavBar.vue'
import ProviderDetails from '@/components/ProviderDetails.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProviderDetailsView',
  components: {
    Banner,
    NavBar,
    ProviderDetails
  },
  computed: {
    ...mapGetters(['providerEditMode']),
    isEditing() {
      return !!this.providerEditMode
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  padding: 0 50px;
}
::v-deep div {
  .v-tab {
    &[aria-selected='false'] {
      color: $grey-dark !important;
    }
  }
}
</style>
