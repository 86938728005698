<template>
  <div>
    <ApiError
      v-if="apiErrors.includes(getErrorSubtype('retrieveLog'))"
      dispatch="fetchRetrieveLog"
    />
    <div v-else class="tableWrapper">
      <AdsButton
        class="syncButton"
        icon="mdi-autorenew"
        button-text="Sync school cap data"
        @click="onSyncSchoolCapData($event)"
      />
      <div class="tableTitle">Scout data sync history</div>
      <AdsDataTable
        :items="smiCapStatusChangeLog"
        :headers="headers"
        sort-by="lastUpdated"
        :no-data-text="noResultsText"
        must-sort
        sort-desc
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': [10, 15, 50]
        }"
      >
        <!-- This is to remove the search bar which is a default slot -->
        <template #top />
        <template
          v-for="headerData in headers"
          :slot="`header.${headerData.value}`"
        >
          <AdsButton
            :key="headerData.value"
            class="headerBtn"
            tertiary
            small
            :button-text="headerData.text"
          />
        </template>
      </AdsDataTable>
      <DialogGeneric
        v-model="openModal"
        persistent
        display-close-btn
        icon="mdi-autorenew"
        title="Scout data sync"
        btn-class="dialog-buttons-style"
        :actions="dialogAction"
        @click:action="handleDialogAction"
        aria-role="alert"
        class="dialog"
        min-height="530px"
      >
        <template slot="component">
          <div v-if="loadingRetrievingLog" class="spinnerWrapper">
            <v-progress-circular v-bind="attrs" />
            <div class="schoolCapTitle">Retrieving & comparing Scout data</div>
            <div class="subtitle">This may take up to 20 seconds</div>
          </div>
          <div v-else-if="hasRetrievingError" class="spinnerWrapper">
            <img src="@/assets/icons/systemError.svg" alt="green tick" />
            <div class="schoolCapTitle">
              There was an error retrieving Scout data
            </div>
            <div class="subtitle">Please try again in a moment</div>
          </div>
          <div v-else-if="hasRecords" class="recordWrapper">
            <div class="record">
              <v-icon size="40" :color="ADS_Colors.PRIMARY_BLUE"
                >playlist_add_check</v-icon
              >
              <div class="count recordCount">
                {{ calculateDeltasMetadata.totalChangesToCapStatus }}
              </div>
              <div class="schoolMessage">School cap records will change</div>
            </div>
            <div
              class="record"
              style="margin-bottom: 5rem; background: #f2fafe"
            >
              <v-icon size="40" :color="ADS_Colors.PRIMARY_BLUE"
                >mdi-email-outline</v-icon
              >
              <div class="count recordCount">
                {{
                  calculateDeltasMetadata.totalChangesThatRequireNotification
                }}
              </div>
              <div class="schoolMessage">
                Schools will be notified about this change due to a discrepancy
                with their SI settings
              </div>
            </div>
            <div v-if="downLoadError" class="downLoadError">
              <v-icon size="20" class="errorIcon">mdi-alert-circle</v-icon>
              CSV failed to download. Please try again.
            </div>
          </div>
          <div v-else-if="noRecords" class="spinnerWrapper">
            <img src="@/assets/icons/thumbup.svg" alt="up to date" />
            <div class="schoolCapTitle">You’re all up to date</div>
            <div class="subtitle">
              There are no school cap records to update at this time
            </div>
          </div>
          <div v-else-if="isPublishing" class="spinnerWrapper">
            <v-progress-circular v-bind="attrs" />
            <div class="schoolCapTitle">Publishing changes</div>
          </div>
          <div v-else-if="publishSucceed" class="spinnerWrapper">
            <div class="publishSucceedWrapper">
              <div class="publishSucceed">
                <img src="@/assets/icons/greenTick.svg" alt="system error" />
                <div class="syncCount">
                  {{ calculateDeltasMetadata.totalChangesToCapStatus }}
                </div>
              </div>
              <div class="publishSucceedMessage">
                School cap records have been updated in SI.
              </div>
            </div>
            <AdsButton
              class="schoolCapTitle"
              tertiary
              button-text="Close window"
              @click="onCloseModal($event)"
            />
          </div>
          <div v-else-if="hasSystemError" class="spinnerWrapper">
            <img src="@/assets/icons/systemError.svg" alt="system error" />
            <div class="schoolCapTitle">System error</div>
            <div class="subtitle">
              We were unable to publish the requested changes. Please try again
              in a moment.
            </div>
          </div>
        </template>
      </DialogGeneric>
    </div>
  </div>
</template>

<script>
const headers = [
  {
    text: 'Last updated',
    value: 'lastUpdated',
    align: 'left',
    class: 'last-updated'
  },
  {
    text: 'Updated by',
    value: 'updatedBy',
    align: 'left',
    class: 'updated-by'
  },
  {
    text: 'No. records updated',
    value: 'noOfRecordsUpdated',
    align: 'left',
    class: 'no-records'
  }
]

import {
  AdsButton,
  DialogGeneric,
  ADS_Colors,
  AdsDataTable
} from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import ApiError from '@/components/ApiError.vue'
import { API_ERROR_SUBTYPES } from '@/constants'

export default {
  name: 'SchoolCapData',
  components: {
    AdsButton,
    DialogGeneric,
    AdsDataTable,
    ApiError
  },
  data() {
    return {
      retrievingLog: false,
      headers,
      openModal: false,
      dialogButtons: [],
      ADS_Colors
    }
  },
  computed: {
    ...mapGetters([
      'apiErrors',
      'smiCapStatusChangeLog',
      'hasCapStatusChangeLogError',
      'hasRetrievingError',
      'loadingRetrievingLog',
      'calculateDeltasMetadata',
      'calculateDeltasResponse',
      'noRecords',
      'hasRecords',
      'hasSystemError',
      'isPublishing',
      'publishSucceed',
      'downLoadError'
    ]),
    attrs() {
      const defaultAttrs = {
        indeterminate: true,
        color: '#CDD3D6',
        size: 126,
        width: 10
      }
      return { ...defaultAttrs, ...this.$attrs }
    },
    dialogAction() {
      if (
        this.hasRecords &&
        !this.loadingRetrievingLog &&
        !this.hasRetrievingError
      ) {
        return [
          { name: 'Download csv', color: 'primary', outlined: true },
          { name: 'Publish changes to SI', color: ADS_Colors.Success_Green }
        ]
      }
      return null
    },
    noResultsText() {
      return this.hasCapStatusChangeLogError &&
        this.smiCapStatusChangeLog.length === 0
        ? 'We could not retrieve any data. Please refresh the page.'
        : 'No results found.'
    }
  },
  methods: {
    getErrorSubtype(code) {
      return API_ERROR_SUBTYPES[code]
    },
    async onSyncSchoolCapData() {
      this.openModal = true
      await this.$store.dispatch('fetchCalculateDeltas')
    },
    onCloseModal() {
      this.openModal = false
    },
    handleDialogAction(actionName) {
      if (actionName === this.dialogAction[0].name) {
        this.$store.dispatch('downLoadCsv')
      }
      if (actionName === this.dialogAction[1].name) {
        this.$store.dispatch('publishChangesToSI', this.calculateDeltasResponse)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tableWrapper {
  position: relative;
  padding: 60px 40px;
}

.tableTitle {
  font-weight: bold;
  font-size: 20px;
  color: $ads-primary-blue;
  margin: 15px auto;
}

.syncButton {
  position: absolute;
  right: 40px;
  top: 50px;
}

::v-deep .v-data-table tbody tr {
  height: 56px;
}
::v-deep .v-data-table div.v-data-footer {
  font-size: 14px;
  height: 64px;
}

::v-deep .v-data-table-header {
  tr {
    height: 56px;
  }

  button {
    color: $ads-primary-blue;
    font-size: 16px;
    font-weight: 400;

    &:focus {
      outline: 2px solid $ads-navy !important;
    }
  }
  .active button {
    font-weight: bold;
  }

  .v-icon {
    color: $ads-primary-blue !important;
    font-weight: 400;
    margin-left: 10px;
  }

  .active .v-icon {
    font-weight: bold;
  }
}
.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.schoolCapTitle {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: $ads-primary-blue;
  margin-top: 4rem;
}
.subtitle {
  font-size: 14px;
  line-height: 25px;
  color: $ads-primary-blue;
  margin-bottom: 6rem;
}

.record {
  border: 1px solid #cdd3d6;
  border-radius: 4px;
  padding: 1.5rem 3rem;
  margin: 1rem 2rem;
  display: flex;
  align-items: center;
  min-height: 7rem;
}

.schoolMessage {
  margin-left: 0.8rem;
  font-size: 18px;
  color: $ads-primary-blue;
}

.count {
  margin-left: 5px;
  font-weight: bold;
  color: $ads-primary-blue;
}

.recordCount {
  font-size: 34px;
}
.syncCount {
  font-size: 56px;
  color: $ads-success-green;
  padding-left: 10px;
  font-weight: bold;
}

.publishSucceed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.publishSucceedWrapper {
  width: 80%;
  background: rgba(168, 237, 179, 0.3);
  border-radius: 4px;
  padding: 50px;
  margin-top: -30px;
}

.publishSucceedMessage {
  font-size: 16px;
  color: $ads-grey-01;
  text-align: center;
  margin-top: 15px;
}

.downLoadError {
  font-size: 14px;
  color: $ads-grey-01;
  background: rgba(255, 184, 193, 0.31);
  border-radius: 4px;
  padding: 0.8rem 0.4rem;
  margin: -4rem 2rem 2rem;
}

.errorIcon {
  color: $ads-primary-red;
  padding: 1px 3px 4px;
}

.headerBtn {
  margin: 0;
  border: none;
  padding: 0 !important;
  font-weight: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  &:hover::before {
    opacity: 0;
  }
}

::v-deep .v-data-table__empty-wrapper {
  td {
    color: $ads-primary-blue;
    padding: 10rem 0 !important; // Adds extra tall row height to "No data available" row
  }
}

::v-deep .buttonText {
  font-size: 16px;
}
</style>
