import { ADS_Colors } from '@nswdoe/doe-ui-core'

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const STATUS = {
  APPROVED: 'Approved',
  DRAFT: 'Draft',
  EXPIRED: 'Expired'
}

export const TYPE = {
  SRE: 'SRE',
  SEE: 'SEE'
}

export const OPTION_STATUS = {
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  ERROR: 'Error'
}

export const CT_PROPERTIES = {
  UUID: 'uuid',
  NOTIFICATION_ID: 'notificationId',
  LAST_EDITED: 'updatedDateTime',
  START_DATE: 'startDateTime',
  END_DATE: 'endDateTime',
  NO_OF_SCHOOLS: 'noOfAffectedSchools',
  STATUS: 'status',
  STATUS_ALTERED: 'status_altered'
}

export const COMMUNICATION_STATUSES = {
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled',
  ACTIVE: 'Active',
  REMOVED: 'Removed',
  EXPIRED: 'Expired',
  PUBLISHED: 'Published'
}

export const COMMUNICATION_STATUS_CHIPS = {
  [COMMUNICATION_STATUSES.DRAFT]: {
    text: COMMUNICATION_STATUSES.DRAFT.toUpperCase(),
    outlined: false,
    color: ADS_Colors.TERTIARY_BLUE_DARK,
    'text-color': ADS_Colors.PRIMARY_BLUE
  },
  [COMMUNICATION_STATUSES.SCHEDULED]: {
    text: COMMUNICATION_STATUSES.SCHEDULED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.Success_Green_Dark,
    'text-color': ADS_Colors.GREY_01
  },
  [COMMUNICATION_STATUSES.ACTIVE]: {
    text: COMMUNICATION_STATUSES.ACTIVE.toUpperCase(),
    outlined: false,
    color: ADS_Colors.Success_Green,
    'text-color': ADS_Colors.White
  },
  [COMMUNICATION_STATUSES.EXPIRED]: {
    text: COMMUNICATION_STATUSES.EXPIRED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  },
  [COMMUNICATION_STATUSES.REMOVED]: {
    text: COMMUNICATION_STATUSES.REMOVED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  }
}

export const PLACEMENT_TYPES = ['Online Enrolment System (OES)']

export const POSITION_TYPES = [
  {
    name: 'SI Dashboard',
    value: 'SI Dashboard'
  }
]

export const FILTER_TYPES = {
  SCHOOL_NAME: 'School name',
  SCHOOL_TYPE: 'School type',
  SCHOOL_SETTINGS: 'School settings'
}

export const AUDIENCE_TYPES = [
  {
    label: 'All schools',
    value: false
  },
  {
    label: 'Targeted schools',
    value: true
  }
]

export const SCHOOL_TYPES = [
  {
    name: 'Infant schools',
    value: 'infants'
  },
  {
    name: 'Primary schools',
    value: 'primary'
  },
  {
    name: 'Secondary schools',
    value: 'secondary'
  },
  {
    name: 'Central / Community schools',
    value: 'central'
  }
]

export const SCHOOL_SETTINGS_TYPES = {
  SRESEE_CONFIG: 'SRE/SEE configured',
  SRESEE_NOT_CONFIG: 'SRE/SEE not configured',
  CAP_NOT_CONFIG: 'Cap status not configured'
}

export const TIMING_TYPES = [
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00'
]

export const STATUS_CHIPS = {
  [STATUS.APPROVED]: {
    text: STATUS.APPROVED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.PRIMARY_BLUE,
    'text-color': ADS_Colors.White
  },
  [STATUS.DRAFT]: {
    text: STATUS.DRAFT.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  },
  [STATUS.EXPIRED]: {
    text: STATUS.EXPIRED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.PRIMARY_RED,
    'text-color': ADS_Colors.White
  }
}

export const STATUS_CHIPS_OPTIONS = {
  ['Default']: {
    textColor: ADS_Colors.GREY_02
  }
}

export const OPTION_STATUS_CHIPS = {
  [OPTION_STATUS.PUBLISHED]: {
    text: OPTION_STATUS.PUBLISHED.toUpperCase(),
    outlined: false,
    color: ADS_Colors.Success_Green,
    'text-color': ADS_Colors.White
  },
  [OPTION_STATUS.DRAFT]: {
    text: OPTION_STATUS.DRAFT.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  },
  [OPTION_STATUS.ERROR]: {
    text: OPTION_STATUS.ERROR.toUpperCase(),
    outlined: false,
    color: ADS_Colors.PRIMARY_RED,
    'text-color': ADS_Colors.White
  }
}

export const API_ERROR_SUBTYPES = {
  fetchProviders: 'FETCH_PROVIDERS',
  fetchOptions: 'FETCH_OPTIONS',
  getSMIUserRoles: 'GET_SMI_USER_ROLES',
  updateProvider: 'UPDATE_PROVIDER',
  createProvider: 'CREATE_PROVIDER',
  updateOption: 'UPDATE_OPTION',
  createOption: 'CREATE_OPTION',
  retrieveLog: 'RETRIEVELOG',
  calculateDeltas: 'CALCULATEDELTAS',
  publishDeltas: 'PUBLISHDELTAS',
  createCommunication: 'CREATE_COMMUNICATION',
  fetchCommunications: 'FETCH_COMMUNICATIONS'
}

export const API_ERROR_MESSAGES = {
  GENERIC_BACKEND_API_ERROR:
    'Unable to save due to server error. Please try again.'
}

export const SMI_USER_ROLES = {
  SMI_SRE_SEE_ADMIN: 'smi-sre-see-admin',
  SMI_CAPSTATUS_ADMIN: 'smi-capstatus-admin',
  SMI_COMMUNICATIONS_ADMIN: 'smi-communications_admin'
}

export const OPTION_TYPE = {
  SRE: 'SRE',
  SEE: 'SEE',
  AMA: 'AMA'
}

export const OPTION_TYPE_NAME = {
  SRE: 'Special Religious Education (SRE)',
  SEE: 'Special Education in Ethics (SEE)',
  AMA: 'Alternative Meaningful Activities (AMA)'
}

export const OPTION_PROVIDER_ERROR = {
  NONE_SPECIFIED: 'None specified'
}

export const ALERT_TYPES = {
  DRAFT: {
    type: 'info',
    icon: 'edit_note',
    color: ADS_Colors.PRIMARY_BLUE
  },
  SUCCESS: {
    type: 'success',
    icon: 'mdi-check-circle',
    color: ADS_Colors.Success_Green
  },
  WARNING: {
    type: 'warning',
    icon: 'mdi-alert',
    color: ADS_Colors.Warning_Orange
  },
  ERROR: {
    type: 'error',
    icon: 'mdi-alert-circle',
    color: ADS_Colors.Error_Red
  },
  INFO: {
    type: 'info',
    icon: 'mdi-information',
    color: ADS_Colors.PRIMARY_BLUE
  },
  SCHEDULE: {
    type: 'success',
    icon: 'mdi-av-timer',
    color: ADS_Colors.Success_Green
  },
  DELETE: {
    type: 'error',
    icon: 'mdi-delete-outline',
    color: ADS_Colors.Error_Red
  },
  REMOVE: {
    type: 'error',
    icon: 'mdi-close-circle',
    color: ADS_Colors.Error_Red
  }
}

export const ROUTE_PATH = {
  PROVIDER: {
    create: '/provider/new'
  },
  OPTION: {
    create: '/option/new'
  },
  COMMUNICATION: {
    create: '/communications/new'
  }
}
