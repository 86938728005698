import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthUtils from '@/utils/authUtils'
import loginGuard from '@/router/loginGuard'
import SreSeeSettingsView from '@/views/SreSeeSettingsView.vue'
import ProviderDetailsView from '@/views/ProviderDetailsView.vue'
import OptionDetailsView from '@/views/OptionDetailsView.vue'
import providerGuard from '@/router/providerGuard'
import optionGuard from '@/router/optionGuard'
import schoolCapGuard from '@/router/schoolCapGuard'
import communicationsGuard from '@/router/communicationsGuard'
import CommunicationsView from '@/views/CommunicationsView.vue'
import CommunicationDetailsView from '@/views/CommunicationDetailsView.vue'
import SchoolCapView from '@/views/SchoolCapView.vue'
import ErrorView from '@/views/ErrorView.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'SreSeeSettingsView',
    beforeEnter: loginGuard,
    component: SreSeeSettingsView
  },
  {
    path: '/provider/edit/:id',
    name: 'EditProviderDetails',
    component: ProviderDetailsView,
    beforeEnter: providerGuard
  },
  {
    path: '/provider/new',
    name: 'CreateProviderDetails',
    component: ProviderDetailsView,
    beforeEnter: providerGuard
  },
  {
    path: '/option/edit/:id',
    name: 'EditOptionDetails',
    component: OptionDetailsView,
    beforeEnter: optionGuard
  },
  {
    path: '/option/new',
    name: 'CreateOptionDetails',
    component: OptionDetailsView,
    beforeEnter: optionGuard
  },
  {
    path: '/communications',
    name: 'CommunicationsView',
    component: CommunicationsView,
    beforeEnter: communicationsGuard
  },
  {
    path: '/communications/new',
    name: 'CreateCommunicationDetails',
    component: CommunicationDetailsView,
    beforeEnter: communicationsGuard
  },
  {
    path: '/communications/edit/:id',
    name: 'EditCommunicationDetails',
    component: CommunicationDetailsView,
    beforeEnter: communicationsGuard
  },
  {
    path: '/schoolcap',
    name: 'SchoolCapView',
    component: SchoolCapView,
    beforeEnter: schoolCapGuard
  },
  {
    path: '/error',
    name: 'ErrorView',
    component: ErrorView,
    meta: {
      title: 'Error'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter(to, from, next) {
      AuthUtils.clearTokens()
      window.location.href = process.env.VUE_APP_AUTH_LOGOUT_URI
      next(false)
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scroll(0, 0)
})

if (!AuthUtils.isOfflineTesting()) {
  router.beforeEach((to, from, next) => {
    if (
      !AuthUtils.isTokens() &&
      !AuthUtils.isCode() &&
      !AuthUtils.isRedirectFromOpenAm() &&
      to.path !== '/logout'
    ) {
      router.app.$auth.DET.startAuth()
    }
    next()
  })
}

export default router
