<!--
  Custom HTML version of the native JavaScript window.alert() and
  window.confirm() dialogs. Uses a Vue transition for fading in and out.

  options: 

  message     - Message text to display (either message or html should be specified)
  html        - html to display (any user content should be sanitised first)

  onConfirm   - Optional callback for the confirm button (this will also cause a cancel button to be displayed)
  onCancel    - Optional callback for the cancel button
  onAlways    - Optional callback when clicking any button (button callback will fire first)
  onReady     - Optional callback when message box has rendered
  textConfirm - Optional text for the confirm button (default is "OK")
  textCancel  - Optional text for the cancel button (default is "Cancel")
  icon        - Optional icon name to determine the default icon (e.g. "exclamation")
-->

<template>
  <transition name="fade">
    <div class="AppMessageBox" @click.self="escape">
      <FocusTrap is-active>
        <div
          class="box"
          role="dialog"
          aria-label="Message box"
          aria-describedby="boxDescription"
          @keyup.esc="escape"
          tabindex="0"
        >
          <v-icon
            v-if="messageBox.showCloseButton"
            size="24"
            @click="cancel"
            class="cancelIcon"
            >mdi-close</v-icon
          >
          <div class="body" id="boxDescription">
            <div>
              <div class="icon iconCircleBackground">
                <v-icon size="24" color="#041e42"> {{ iconName }} </v-icon>
              </div>
            </div>
            <div class="scrollingMessageArea">
              <div v-if="messageBox.message" v-html="messageBox.message"></div>
              <div v-if="messageBox.html" v-html="messageBox.html"></div>
            </div>
          </div>
          <div class="footer">
            <AdsButton
              id="v-btn-confirm"
              ref="confirm"
              class="primary"
              @click="confirm"
              :button-text="messageBox.textConfirm || 'OK'"
              :aria-label="messageBox.textConfirm || 'OK'"
            />
            <button
              v-if="messageBox.onConfirm"
              id="v-btn-cancel"
              @click="cancel"
              type="button"
              class="cancelButton"
              v-html="this.messageBox.textCancel || `<span>Cancel</span>`"
            />
          </div>
        </div>
      </FocusTrap>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsButton, FocusTrap } from '@nswdoe/doe-ui-core'

export default {
  name: 'AppMessageBox',
  components: {
    AdsButton,
    FocusTrap
  },
  computed: {
    ...mapGetters(['messageBox']),
    iconName() {
      if (this.messageBox.icon) {
        return this.messageBox.icon
      }
      return this.messageBox.onConfirm ? 'mdi-help' : 'mdi-information-variant'
    }
  },
  methods: {
    confirm() {
      // Timeouts are used to ensure the message box has completed execution
      // before callbacks are fired. Without this, the callback would not be
      // able to launch another message box if it needed to do so.
      var onConfirm = this.messageBox.onConfirm // Must store references to callbacks because they will fire after the messageBox object is cleared by 'hideMessageBox'
      var onAlways = this.messageBox.onAlways
      if (onConfirm) {
        setTimeout(() => onConfirm(), 0)
      }
      if (onAlways) {
        setTimeout(() => onAlways(), 0)
      }
      this.$store.dispatch('hideMessageBox')
    },
    cancel() {
      var onCancel = this.messageBox.onCancel // Must store references to callbacks because they will fire after the messageBox object is cleared by 'hideMessageBox'
      var onAlways = this.messageBox.onAlways
      if (onCancel) {
        setTimeout(() => onCancel(), 0)
      }
      if (onAlways) {
        setTimeout(() => onAlways(), 0)
      }
      this.$store.dispatch('hideMessageBox')
    },
    escape() {
      this.cancel()
    }
  },
  mounted() {
    if (this.$refs.confirm) this.$refs.confirm.$el.focus()
    if (this.messageBox.onReady) this.messageBox.onReady()
  }
}
</script>

<style lang="scss">
$content-padding: 1rem;

.AppMessageBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .box {
    margin: 1em;
    padding: $content-padding;
    max-width: 550px;
    min-width: 350px;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    outline: none; // Prevents focus outline on dialog itself
  }

  .cancelIcon {
    margin-left: 490px;
    &:focus {
      border: 2px solid #005fcc !important;
    }
    &:after {
      background-color: transparent;
    }
  }

  .iconCircleBackground {
    background-color: $ads-light-blue;
    font-size: 1.5em;
    width: 1.75em;
    line-height: 1.75em;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
  }

  .body {
    display: flex;
    margin: 0.5em 2em 1.75em 0;
    color: black;
    line-height: 1.3;

    .icon {
      margin: -0.5em 0.5rem 0 0; // Yes, the use of "em" and "rem" is intended
    }

    h1,
    h2 {
      margin: 0 0 1em 0;
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-weight: bold;
    }

    p {
      margin: 0 0 0.5em 0;
    }

    a {
      font-weight: 600;
    }
  }

  .footer {
    direction: rtl;
    text-align: right;
  }

  button {
    margin: 0 0 0 0.5em;
    border: none;
  }

  .cancelButton {
    border: none;
    padding: 0.6em 0 !important;
  }

  ul {
    margin: 0;
    padding-left: 1em;
  }

  .scrollingMessageArea {
    overflow: auto;
    max-height: calc(
      100vh - 120px
    ); // Ensures message cannot extend beyond the screen height. 120px gives room for the message box footer.
  }

  #v-btn-confirm {
    height: 39px;
  }

  #v-btn-cancel {
    &:focus {
      text-decoration: underline;
    }
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.linkButton {
  color: $ads-navy;
  font-weight: 700;
  text-decoration: none;
  outline: 2px solid $ads-navy;
  border-radius: 4px;
  padding: 10px 16px;

  &:hover {
    outline: 2px solid $ads-navy;
  }
}
</style>
