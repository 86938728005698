<template>
  <div>
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start">
              <v-icon size="48px" class="banner__icon mr-3">
                playlist_add_check
              </v-icon>
              <div>
                <h1 class="banner__title">School cap settings</h1>
                <p class="banner__text">Sync school cap data</p>
              </div>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>
    <SchoolCapData />
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core'
import SchoolCapData from '@/components/SchoolCapData'

export default {
  name: 'SchoolCapView',
  components: {
    Banner,
    SchoolCapData
  },
  async created() {
    await this.$store.dispatch('showSpinnerForApplication')
    await this.$store.dispatch('fetchRetrieveLog')
    await this.$store.dispatch('hideSpinnerForApplication')
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  padding: 0 50px;
}
</style>
