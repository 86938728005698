<!--
  Displays bar with session timeout countdown and login button  
-->

<template>
  <div>
    <div
      v-if="hasTimedOut"
      ref="disableUi"
      class="disableUi"
      tabindex="0"
    ></div>
    <div v-if="showBanner" class="AppSessionCountdownBar">
      <div class="message">
        <div class="icon iconCircleBackground">
          <font-awesome-icon icon="exclamation" />
        </div>
        <span class="timer-message_begin" v-if="!hasTimedOut"
          >Your session will expire in
          <strong class="timer-message">{{ countdownText }}</strong></span
        >
        <span v-if="hasTimedOut" class="timer-message"
          ><strong
            >Your session has expired. Please log in again to continue.</strong
          ></span
        >
      </div>
      <button type="button" class="primary" @click="onClick">
        {{ hasTimedOut ? 'Login to continue' : 'Continue session' }}
      </button>
    </div>
  </div>
</template>

<script>
import AuthUtils from '@/utils/authUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'AppSessionCountdownBar',
  props: { minutesWarning: { type: Number, default: 5 } },
  data() {
    return {
      countdownTimer: null,
      timeout: {
        mins: this.minutesWarning,
        secs: 0
      }
    }
  },
  watch: {
    showBanner: {
      handler(newValue) {
        //If we have just started displaying the banner, then start the countdown and event listeners
        if (newValue) {
          this.addListeners()
          this.beginCountdown()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['showTimeoutBanner']),
    hasTimedOut() {
      return this.timeout.mins === 0 && this.timeout.secs === 0
    },
    showBanner() {
      return this.showTimeoutBanner
    },
    countdownText() {
      return `${this.timeout.mins} minute${
        this.timeout.mins !== 1 ? 's' : ''
      }, ${this.timeout.secs} second${this.timeout.secs !== 1 ? 's' : ''}`
    }
  },
  methods: {
    beginCountdown() {
      this.timeout.mins = this.minutesWarning
      this.timeout.secs = 0
      this.countdownTimer = setInterval(this.countDownOneSecond, 1000)
    },
    addListeners() {
      window.addEventListener('keydown', this.continueSession)
      window.addEventListener('mousedown', this.continueSession)
    },
    removeListeners() {
      window.removeEventListener('keydown', this.continueSession)
      window.removeEventListener('mousedown', this.continueSession)
    },
    countDownOneSecond() {
      let mins = this.timeout.mins
      let secs = this.timeout.secs
      if (mins === 0 && secs === 0) {
        clearInterval(this.countdownTimer)
        this.removeListeners()
        this.removeTokens()
      } else if (secs === 0) {
        this.timeout.mins = mins - 1
        this.timeout.secs = 59
      } else {
        this.timeout.secs = secs - 1
      }
    },
    removeTokens() {
      AuthUtils.clearTokens()
    },
    continueSession() {
      clearInterval(this.countdownTimer)
      this.removeListeners()
      this.$store.commit('setShowTimeoutBanner', false)
      this.$activityMonitor.activate()
    },
    onClick() {
      this.hasTimedOut ? this.reauthenticate() : this.continueSession()
    },
    reauthenticate() {
      this.$auth.DET.startAuth()
    }
  },
  beforeDestroy() {
    this.removeListeners()
    clearInterval(this.countdownTimer)
  }
}
</script>

<style scoped lang="scss">
.AppSessionCountdownBar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 66px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  background-color: #ffb8c1;
  color: black;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);

  .message {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 0.5rem;
    }

    .timer-message_begin {
      height: 24px;
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $ads-primary-blue;
    }
    .timer-message {
      height: 24px;
      font-family: 'Public Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $ads-primary-blue;
    }
  }

  > p,
  > button {
    margin: 0;
    width: 177px;
    height: 44px;
    left: 1238px;
    top: 11px;
    background: #002664;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}
.disableUi {
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
}
.iconCircleBackground {
  color: white;
  font-size: 0.9em;
  background-color: $ads-red;
  width: 1.75em;
  line-height: 1.75em;
  text-align: center;
  border-radius: 1em;
  svg {
    vertical-align: -0.175em;
  }
}
</style>
