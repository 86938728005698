var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.apiErrors.includes(_vm.getErrorSubtype('fetchCommunications')))?_c('ApiError',{attrs:{"dispatch":"fetchCommunications"}}):_c('div',{staticClass:"notificationsList lighten-6"},[_c('div',{staticClass:"pb-3 d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"filterLabel"},[_vm._v("Filter by:")]),_c('span',{staticClass:"pl-3"},[_c('list-view-filter',{ref:"startDateFilter",attrs:{"label":"Start date","type":"daterange","settings":{
              min: this.firstStartDate,
              max: this.lastStartDate
            },"filter-disabled":_vm.filteredItems.some(
                (item) => item[_vm.CT_PROPERTIES.START_DATE] === null
              )},on:{"updatefilter":function($event){return _vm.updateFilterValue({
                filterName: 'startDate',
                value: $event
              })}}})],1),_c('span',{staticClass:"pl-3"},[_c('list-view-filter',{ref:"endDateFilter",attrs:{"label":"End date","type":"daterange","settings":{
              min: this.firstEndDate,
              max: this.lastEndDate
            },"filter-disabled":_vm.filteredItems.some(
                (item) => item[_vm.CT_PROPERTIES.END_DATE] === null
              )},on:{"updatefilter":function($event){return _vm.updateFilterValue({
                filterName: 'endDate',
                value: $event
              })}}})],1),_c('span',{staticClass:"pl-3"},[_c('list-view-filter',{ref:"statusFilter",attrs:{"label":"Status","type":"checkbox","filter-options":_vm.statusFilters,"height":"auto"},on:{"updatefilter":function($event){return _vm.updateFilterValue({
                filterName: 'status',
                value: $event
              })}}})],1),(_vm.isFiltered)?_c('clear-filters',{on:{"click":_vm.handleClearFilters}}):_vm._e()],1),_c('div',{staticClass:"pt-0 pr-0 text-right"},[_c('span',{staticClass:"pl-3"},[(_vm.actionFrom === 0)?_c('ads-button',{staticClass:"mr-0",attrs:{"icon":"add_circle_outline","button-text":"Create notification"},on:{"click":_vm.createOption}}):_vm._e()],1)])]),_c('v-card',[(!_vm.isLoading)?_c('ads-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.searchValue,"item-key":"id","items-per-page":_vm.pageLength,"footer-props":{
          'items-per-page-options': [10, 15, 50]
        },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","no-results-found":'No results found. Try removing some filters or clearing the keyword search.',"no-data-text":_vm.isFiltered
            ? 'No results found. Try removing some filters or clearing the keyword search.'
            : 'No results found.'},on:{"pagination":_vm.totalResults},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"row-line, pb-4",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{staticClass:"searchInput pl-2",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search for keywords","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-col',{staticClass:"pr-6 align-self-end body-1 result-text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.resultsText(_vm.itemsLength))+" ")])])],1)]},proxy:true},{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.dateFormat(props.item[_vm.CT_PROPERTIES.LAST_EDITED]))+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.dateFormat(props.item[_vm.CT_PROPERTIES.START_DATE]))+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm.dateFormat(props.item[_vm.CT_PROPERTIES.END_DATE]))+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showCommunication(props.item)}}},[_vm._v(" "+_vm._s(props.item[_vm.CT_PROPERTIES.NOTIFICATION_ID])+" ")])]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item[_vm.CT_PROPERTIES.NO_OF_SCHOOLS])+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('chip',_vm._b({attrs:{"small":""}},'chip',
                  _vm.getStatusChipDetails(
                    props.item[_vm.CT_PROPERTIES.STATUS_ALTERED]
                  )
                ,false))],1)])]}}],null,false,2070772770),model:{value:(_vm.selectedIds),callback:function ($$v) {_vm.selectedIds=$$v},expression:"selectedIds"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }