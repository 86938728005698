<template>
  <div>
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start">
              <v-icon size="48px" class="banner__icon mr-3">
                mdi-message-text-outline
              </v-icon>
              <div>
                <h1 class="banner__title">Communications</h1>
                <p class="banner__text">Manage communications with OES users</p>
              </div>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>

    <v-container fluid>
      <div class="inline-alert__container">
        <slot name="inline-alert" />
      </div>
      <v-row>
        <v-col class="pt-0 pl-0 pr-0">
          <tab-navigation
            :items="tabItems"
            data-testid="option-list-tab"
            v-model="activeTab"
          >
            <template slot="content1">
              <v-row class="pr-5">
                <v-col class="pl-7">
                  <communications-list
                    :action-from="0"
                    :items="inProgressList"
                    :sort-by="CT_PROPERTIES.STATUS_ALTERED"
                    :sort-desc="false"
                  />
                </v-col>
              </v-row>
            </template>
            <template slot="content2">
              <v-row class="pr-5">
                <v-col class="pl-7">
                  <communications-list
                    :action-from="1"
                    :items="completedList"
                    :sort-by="CT_PROPERTIES.END_DATE"
                  />
                </v-col>
              </v-row>
            </template>
          </tab-navigation>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Banner, TabNavigation } from '@nswdoe/doe-ui-core'
import CommunicationsList from '@/components/CommunicationsList'
import {
  COMMUNICATION_STATUSES,
  CT_PROPERTIES,
  COMMUNICATION_STATUS_CHIPS
} from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'CommunicationsView',
  components: {
    Banner,
    TabNavigation,
    CommunicationsList
  },
  data: () => ({
    tabItems: [
      {
        index: 0,
        tab: 'IN-PROGRESS'
      },
      { index: 1, tab: 'COMPLETED' }
    ],
    COMMUNICATION_STATUSES,
    COMMUNICATION_STATUS_CHIPS,
    CT_PROPERTIES
  }),
  created() {
    this.$store.dispatch('fetchCommunications')
  },
  computed: {
    ...mapGetters(['communications', 'communicationBackIndex']),
    activeTab() {
      return this.communicationBackIndex
    },
    mappedCommunicationsList() {
      const communicationsList = (this.communications || []).map((i) => ({
        [CT_PROPERTIES.UUID]: i.uuid,
        [CT_PROPERTIES.NOTIFICATION_ID]: i.notification.notificationId,
        [CT_PROPERTIES.LAST_EDITED]: i.updatedDateTime,
        [CT_PROPERTIES.START_DATE]: i.notification.timing?.startDateTime,
        [CT_PROPERTIES.END_DATE]: i.notification.timing?.endDateTime,
        [CT_PROPERTIES.NO_OF_SCHOOLS]: i?.noOfAffectedSchools,
        [CT_PROPERTIES.STATUS]: this.alterStatus(
          i.notification?.status,
          i.notification.timing?.startDateTime,
          i.notification.timing?.endDateTime
        )
      }))
      return communicationsList?.map((obj) => ({
        ...obj,
        [CT_PROPERTIES.STATUS_ALTERED]:
          this.getStatusIndex(obj[CT_PROPERTIES.STATUS]) +
          '|' +
          obj[CT_PROPERTIES.STATUS]
      }))
    },
    inProgressList() {
      return this.mappedCommunicationsList.filter((comm) =>
        [
          COMMUNICATION_STATUSES.DRAFT,
          COMMUNICATION_STATUSES.SCHEDULED,
          COMMUNICATION_STATUSES.ACTIVE
        ].includes(comm[CT_PROPERTIES.STATUS])
      )
    },
    completedList() {
      return this.mappedCommunicationsList.filter((comm) =>
        [
          COMMUNICATION_STATUSES.REMOVED,
          COMMUNICATION_STATUSES.EXPIRED
        ].includes(comm[CT_PROPERTIES.STATUS])
      )
    }
  },
  methods: {
    getStatusIndex(status) {
      return Object.keys(COMMUNICATION_STATUS_CHIPS).indexOf(status)
    },
    alterStatus(status, startDateTime, endDateTime) {
      if (status === COMMUNICATION_STATUSES.PUBLISHED) {
        if (new Date(startDateTime) > new Date()) {
          return COMMUNICATION_STATUSES.SCHEDULED
        } else if (new Date(endDateTime) < new Date()) {
          return COMMUNICATION_STATUSES.EXPIRED
        } else {
          return COMMUNICATION_STATUSES.ACTIVE
        }
      } else if (status === COMMUNICATION_STATUSES.REMOVED) {
        return COMMUNICATION_STATUSES.REMOVED
      } else {
        return COMMUNICATION_STATUSES.DRAFT
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  padding: 0 50px;
}
::v-deep div {
  .v-tab {
    &[aria-selected='false'] {
      color: $grey-dark !important;
    }
  }
}
</style>
