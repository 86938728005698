import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import authModule from '@/store/modules/auth'
import providerModule from '@/store/modules/provider'
import optionModule from '@/store/modules/option'
import notificationsModule from '@/store/modules/notifications'
import VuexPersist from 'vuex-persist'
import actionsModule from '@/store/modules/actions'
import schoolCapStatusModule from '@/store/modules/schoolCapStatus'
import communicationsModule from '@/store/modules/communications'
import schoolsModule from '@/store/modules/schools'
import api from '@/store/modules/api'
import messageBox from '@/store/modules/messageBox'

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersist({
  key: 'vuex',
  storage: window.sessionStorage,
  modules: ['provider', 'option']
})

export const config = {
  modules: {
    api,
    messageBox,
    auth: authModule,
    provider: providerModule,
    option: optionModule,
    notification: notificationsModule,
    actions: actionsModule,
    schoolCapStatus: schoolCapStatusModule,
    communications: communicationsModule,
    schools: schoolsModule
  },
  plugins: [vuexSessionStorage.plugin]
}

export default new Store(config)
