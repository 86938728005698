import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_BASE = process.env.VUE_APP_SMI_API

const RETRIEVELOG_URL = `${API_BASE}/capStatus/retrieveLogs`
const CALCULATEDELTAS_URL = `${API_BASE}/capStatus/calculateDeltas`
const PUBLISHDELTAS_URL = `${API_BASE}/capStatus/publishDeltas`

function fetchRetrieveLog() {
  return axios
    .get(`${RETRIEVELOG_URL}`)
    .then((response) => response)
    .catch((error) => error.response)
}

function fetchCalculateDeltas() {
  return axios
    .get(`${CALCULATEDELTAS_URL}`)
    .then((response) => response)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.calculateDeltas
      })
      throw error
    })
}

function publishChangesToSI(calculateDeltas) {
  return axios
    .post(`${PUBLISHDELTAS_URL}`, calculateDeltas)
    .then((response) => response)
    .catch((error) => {
      store.dispatch('setAPIError', {
        error,
        fallbackCode: API_ERROR_SUBTYPES.publishDeltas
      })
      throw error
    })
}

export default {
  fetchRetrieveLog,
  fetchCalculateDeltas,
  publishChangesToSI
}
