<template>
  <v-overlay :value="showOverlay">
    <div class="AppSpinner" role="alert" aria-busy="true">
      <font-awesome-icon focusable="false" class="icon" icon="circle-notch" />
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'AppOverlaySpinner',
  computed: {
    showOverlay() {
      return (
        this.$store.getters.isSpinner ||
        this.$store.getters.isSpinnerForApplication
      )
    }
  }
}
</script>

<style scoped lang="scss">
.AppSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8rem;
  background-color: rgba(87, 101, 119, 0.28); // Shades the background slightly
  color: white;
  z-index: 10000;
  cursor: default;

  .icon {
    animation: spin 2s infinite linear;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
