// eslint-disable-next-line import/no-unresolved
import API from 'api/providers'
import {
  buildProviderData,
  filterProviders,
  sanitizeProviderRequestPayload,
  upperCaseNoSpaceString
} from '@/utils/generalUtils'
import { ALERT_TYPES, STATUS, API_ERROR_SUBTYPES } from '@/constants'

const providerModule = {
  state: {
    providers: null,
    providerDialogLoading: false,
    statusFilter: [],
    optionsFilter: [],
    providerLoading: false,
    providerEditMode: false
  },
  getters: {
    providers: (state) => state.providers,
    providersWithExpiredStatus: (state, getters) => {
      let providersWithExpiredStatus = []
      const providers = getters.providers
      if (providers) {
        providersWithExpiredStatus = buildProviderData(providers)
      }
      return providersWithExpiredStatus
    },
    providersWithApprovedStatus: (state, getters) => {
      let providersWithApprovedStatus = []
      const providers = getters.providers
      if (providers) {
        providersWithApprovedStatus = providers
          .filter((item) => item.status === STATUS.APPROVED)
          .sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
      }
      return providersWithApprovedStatus
    },
    providersFiltered: (state, getters) => {
      const providerItems = getters.providersWithExpiredStatus
      const statusFilter = getters.statusFilter
      const optionsFilter = getters.optionsFilter
      if (providerItems) {
        return filterProviders(providerItems, statusFilter, optionsFilter)
      }
      return providerItems
    },
    providerDialogLoading: (state) => state.providerDialogLoading,
    statusFilter: (state) => state.statusFilter,
    optionsFilter: (state) => state.optionsFilter,
    providerLoading: (state) => state.providerLoading,
    providerById: (state) => (id) => {
      return state.providers.find((item) => String(item.id) === String(id))
    },
    providerEditMode: (state) => state.providerEditMode,
    providerUniqueNames: (state, getters) => (currentName) => {
      const providerItems = getters.providers
      if (Array.isArray(providerItems) && providerItems.length > 0) {
        const providersWithoutCurrentName = providerItems.filter(function (
          item
        ) {
          return item.name !== currentName
        })
        const upperCaseNamesWithoutSpace = providersWithoutCurrentName.map(
          (item) => {
            return upperCaseNoSpaceString(item.name)
          }
        )
        const uniqueNames = [...new Set(upperCaseNamesWithoutSpace)]
        return uniqueNames
      }
      return []
    }
  },
  mutations: {
    setProviders(state, providers) {
      state.providers = providers
    },
    setStatusFilter(state, value) {
      state.statusFilter = value
    },
    setOptionsFilter(state, value) {
      state.optionsFilter = value
    },
    setProviderLoading(state, loading) {
      state.providerLoading = loading
    },
    setProviderEditMode(state, mode) {
      state.providerEditMode = mode
    },
    setProviderDialogLoading(state, loading) {
      state.providerDialogLoading = loading
    }
  },
  actions: {
    fetchProviders({ commit, dispatch }) {
      const fallbackCode = API_ERROR_SUBTYPES.fetchProviders
      commit('setShowSpinnerForApplication', true)
      commit('setProviderLoading', true)
      return API.fetchProviders()
        .then((response) => {
          const responseWasSuccessful = response.status === 200
          if (!responseWasSuccessful) {
            dispatch('setAPIError', fallbackCode)
            return
          }

          dispatch('removeApiError', fallbackCode)
          const providers = response.data ?? []
          if (Array.isArray(providers) && providers.length > 0) {
            providers.forEach((provider) => {
              if (
                provider.contactDetails &&
                Array.isArray(provider.contactDetails.secondaryEmails)
              ) {
                provider.contactDetails.secondaryEmails =
                  provider.contactDetails.secondaryEmails.filter((n) => n) // remove all the empty item.
              }
            })
          }
          commit('setProviders', providers)
        })
        .finally(() => {
          commit('setProviderLoading', false)
          commit('setShowSpinnerForApplication', false)
        })
    },
    updateProvider({ commit, dispatch }, provider) {
      commit('setProviderLoading', true)
      const providerName = provider.name
      sanitizeProviderRequestPayload(provider)
      return API.updateProvider(provider)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${providerName} successfully updated`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showProviderAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setProviderLoading', false)
          return result
        })
    },
    deleteProvider({ commit, dispatch }, providerItem) {
      commit('setProviderLoading', true)
      commit('setProviderDialogLoading', true)
      return API.deleteProvider(providerItem.id)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${providerItem.name} successfully deleted`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showProviderAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setProviderLoading', false)
          commit('setProviderDialogLoading', false)
          return result
        })
    },
    createProvider({ commit, dispatch }, provider) {
      commit('setProviderLoading', true)
      const providerName = provider.name
      sanitizeProviderRequestPayload(provider)
      return API.createProvider(provider)
        .then((response) => {
          const { status } = response
          const resSuccess = status >= 200 && status < 300
          if (!resSuccess) {
            return Promise.reject(false)
          }

          dispatch('showSnackbar', {
            display: true,
            attrs: {
              type: ALERT_TYPES.SUCCESS.type,
              icon: ALERT_TYPES.SUCCESS.icon,
              subtext: `${providerName} successfully created`
            }
          })
          return true
        })
        .catch((result) => {
          dispatch('showProviderAlert', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><div>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</div>`
          })
          return result
        })
        .finally((result) => {
          commit('setProviderLoading', false)
          return result
        })
    }
  }
}

export default providerModule
