const actionsModule = {
  state: {
    showTimeoutBanner: false
  },
  getters: {
    showTimeoutBanner: (state) => state.showTimeoutBanner,
    getShowTimeoutBanner: (state) => state.showTimeoutBanner
  },
  mutations: {
    setShowTimeoutBanner(state, showTimeout) {
      state.showTimeoutBanner = showTimeout
    }
  },
  actions: {
    beginFinalInactivityTimer({ commit }) {
      this._vm.$activityMonitor.deactivate()
      commit('setShowTimeoutBanner', true)
    }
  }
}

export default actionsModule
