<template>
  <div class="errorView">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="235"
      height="235"
      viewBox="0 0 235 235"
    >
      <g
        id="Group_4720"
        data-name="Group 4720"
        transform="translate(-553 -124)"
      >
        <circle
          id="Ellipse_40"
          data-name="Ellipse 40"
          cx="117.5"
          cy="117.5"
          r="117.5"
          transform="translate(553 124)"
          fill="#fff"
        />
        <path
          id="Path_556"
          data-name="Path 556"
          d="M106.079,36.422a24.786,24.786,0,0,0-3.269.144,41.061,41.061,0,0,0-73.6,7.815,27.5,27.5,0,0,0,.716,54.986H43.277a4.829,4.829,0,0,0,0-9.657H29.923a17.688,17.688,0,0,1,0-35.377c.566,0,.993.144,1.565.144l.71.144a4.846,4.846,0,0,0,5.4-3.7,31.156,31.156,0,0,1,58.25-6.688,4.884,4.884,0,0,0,5.539,2.559,17.275,17.275,0,0,1,4.546-.566,21.741,21.741,0,1,1,0,43.481h-9.38a4.829,4.829,0,1,0,0,9.657h9.38a31.473,31.473,0,0,0,.145-62.946Z"
          transform="translate(600.531 178.073)"
          fill="#041e42"
        />
        <path
          id="Path_557"
          data-name="Path 557"
          d="M69.382,61.61a4.889,4.889,0,0,0-6.821,0l-7.815,7.815L46.931,61.61a4.823,4.823,0,0,0-6.821,6.821l7.815,7.815L40.11,84.06a4.889,4.889,0,0,0,0,6.821A4.619,4.619,0,0,0,43.518,92.3a4.4,4.4,0,0,0,3.408-1.421l7.815-7.815,7.815,7.815A4.619,4.619,0,0,0,65.963,92.3a4.4,4.4,0,0,0,3.408-1.421,4.889,4.889,0,0,0,0-6.821l-7.815-7.815,7.815-7.815a4.875,4.875,0,0,0,.011-6.821Z"
          transform="translate(615.776 196.222)"
          fill="#041e42"
        />
      </g>
    </svg>

    <h2>Sorry, you don't have access to the System Management Interface</h2>
  </div>
</template>

<script>
export default {
  name: 'ErrorView',
  components: {}
}
</script>

<style lang="scss" scoped>
.errorView {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  margin-top: 3.125rem;
}

/*
     * Typography
     */

h1 {
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 1.3125rem;
  font-weight: normal;
  line-height: 32px;
  margin-left: 1.3125rem;
  text-align: center;
}

h2 {
  color: #041e42;
  font-family: Arial;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 32px;
  margin-top: 3.125rem;
  text-align: center;
}

h3 {
  color: #525252;
  font-family: Arial;
  font-weight: normal;
  font-size: 1.3125rem;
  margin-top: 0.875rem;
  text-align: center;
}
</style>
