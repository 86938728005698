import store from '@/store'

export default async (to, from, next) => {
  if (
    !store.getters.userMenuItems ||
    store.getters.userMenuItems?.length === 0
  ) {
    await store.dispatch('setCurrentUser')
  }
  const menuItems = store.getters.userMenuItems
  if (
    menuItems &&
    menuItems.length === 1 &&
    menuItems[0].route === '/schoolcap'
  ) {
    next({ name: 'SchoolCapView' })
  } else if (
    menuItems &&
    menuItems.length === 1 &&
    menuItems[0].route === '/communications'
  ) {
    next({ name: 'CommunicationsView' })
  } else {
    //provider list
    store.commit('setOptionsFilter', [])
    store.commit('setStatusFilter', [])
    // option list
    store.commit('setOptionTypeFilter', [])
    store.commit('setOptionProviderFilter', [])
    store.commit('setOptionStatusFilter', [])
    next()
  }
}
