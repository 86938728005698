import axios from 'axios'

const API_BASE = process.env.VUE_APP_SMI_API
const COMMUNICATIONS_URL = `${API_BASE}/alerts/customNotifications`

function fetchCommunications() {
  return axios
    .get(`${COMMUNICATIONS_URL}`)
    .then((response) => response)
    .catch((error) => error.response)
}

function updateCommunication(communication) {
  const communicationId = communication.uuid
  return axios
    .put(`${COMMUNICATIONS_URL}/${communicationId}`, communication)
    .then((response) => response)
    .catch((error) => error.response)
}

function deleteCommunication(communicationID) {
  return axios
    .delete(`${COMMUNICATIONS_URL}/` + communicationID)
    .then((response) => response)
    .catch((error) => error.response)
}

function createCommunication(communication) {
  return axios
    .post(`${COMMUNICATIONS_URL}`, communication)
    .then((response) => response)
    .catch((error) => error.response)
}

export default {
  fetchCommunications,
  updateCommunication,
  deleteCommunication,
  createCommunication
}
