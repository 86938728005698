const api = {
  state: {
    apiErrors: []
  },
  getters: {
    apiErrors: (state) => state.apiErrors
  },
  mutations: {
    addApiError(state, value) {
      const errors = [...state.apiErrors]
      errors.push(value)
      state.apiErrors = [...new Set(errors)]
    },
    removeApiError(state, value) {
      state.apiErrors = state.apiErrors.filter((e) => e !== value)
    }
  },
  actions: {
    setAPIError(ctx, value) {
      ctx.commit('addApiError', value)
    },
    removeApiError(ctx, value) {
      ctx.commit('removeApiError', value)
    }
  }
}

export default api
