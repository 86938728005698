export default {
  isChildWindow() {
    try {
      return window.opener.location.hostname === window.location.hostname
    } catch (e) {
      return false
    }
  },
  getAppWindow() {
    return this.isChildWindow() ? window.opener : window
  },
  decodeToken(token) {
    const tokenBody = token.split('.')[1]
    return JSON.parse(atob(tokenBody))
  },
  getToken(tokenName) {
    try {
      let token = window.sessionStorage.getItem(tokenName)
      return this.decodeToken(token)
    } catch (e) {
      return null
    }
  },
  clearTokens() {
    const appWindow = this.getAppWindow()
    if (appWindow) {
      appWindow.sessionStorage.removeItem('accessToken')
      appWindow.sessionStorage.removeItem('idToken')
      appWindow.sessionStorage.removeItem('refreshToken')
      appWindow.sessionStorage.removeItem('tokenExpiry')
    }
  },
  isOfflineTesting() {
    return process.env.VUE_APP_OFFLINE_TESTING === 'true'
  },
  isTokens() {
    const appWindow = this.getAppWindow()
    if (appWindow) {
      return !!(
        appWindow.sessionStorage.getItem('accessToken') &&
        appWindow.sessionStorage.getItem('idToken')
      )
    }
    return false
  },
  isCode() {
    const appWindow = this.getAppWindow()
    if (appWindow) {
      return !!appWindow.sessionStorage.getItem('si.auth.code')
    }
    return false
  },
  getQueryParams() {
    return location.search
      .slice(1)
      .split('&')
      .map((p) => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
  },
  isRedirectFromOpenAm() {
    const queryParams = this.getQueryParams()
    const isRedirectFromOpenAm = queryParams.code
    return isRedirectFromOpenAm
  }
}
