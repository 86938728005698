import axios from 'axios'

const API_REFDATA = process.env.VUE_APP_API_REFDATA

const getOesSchools = () => {
  const url = `${API_REFDATA}/oes-pilot-schools.json`

  return axios.get(url).then((res) => res.data)
}

export { getOesSchools }
