// eslint-disable-next-line import/no-unresolved
import { getOesSchools } from 'api/refData'

const schoolsModule = {
  state: {
    oesSchools: null
  },
  getters: {
    oesSchools: (state) => state.oesSchools
  },
  mutations: {
    setOesSchools(state, value) {
      state.oesSchools = value
    }
  },
  actions: {
    async getOesSchools({ commit }) {
      const schools = await getOesSchools()
      commit('setOesSchools', schools)
      return schools
    }
  }
}

export default schoolsModule
