import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { Touch } from 'vuetify/lib/directives'
import doeColors from '../settings/doe-colors'
import flagIconBlue from '@/assets/icons/flagIconBlue'
import flagIconBlueLight from '@/assets/icons/flagIconBlueLight'
import flagIconGreen from '@/assets/icons/flagIconGreen'
import flagIconGreenStrikeThrough from '@/assets/icons/flagIconGreenStrikeThrough'
import flagIconGrey from '@/assets/icons/flagIconGrey'
import flagIconOrange from '@/assets/icons/flagIconOrange'
import flagIconOrangeExclamation from '@/assets/icons/flagIconOrangeExclamation'
import flagIconOrangeStop from '@/assets/icons/flagIconOrangeStop'
import flagIconPink from '@/assets/icons/flagIconPink'
import flagIconLimeStripe from '@/assets/icons/flagIconLimeStripe'
import flagIconPurple from '@/assets/icons/flagIconPurple'
import flagIconRed from '@/assets/icons/flagIconRed'
import flagIconYellow from '@/assets/icons/flagIconYellow'
import flagIconYellowStrikeThrough from '@/assets/icons/flagIconYellowStrikeThrough'
import iconTeacher from '@/assets/icons/iconTeacher'
import iconTeacherBlueSmall from '@/assets/icons/iconTeacherBlueSmall'
import iconRedEye from '@/assets/icons/iconRedEye'
import '@mdi/font/css/materialdesignicons.css' //MDI Icons

Vue.use(Vuetify, {
  directives: { Touch }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      blueFlag: {
        component: flagIconBlue
      },
      blueFlagLight: {
        component: flagIconBlueLight
      },
      greenFlag: {
        component: flagIconGreen
      },
      greenFlagStrikeThrough: {
        component: flagIconGreenStrikeThrough
      },
      greyFlag: {
        component: flagIconGrey
      },
      orangeFlag: {
        component: flagIconOrange
      },
      orangeFlagExclamation: {
        component: flagIconOrangeExclamation
      },
      orangeFlagStop: {
        component: flagIconOrangeStop
      },
      pinkFlag: {
        component: flagIconPink
      },
      purpleFlag: {
        component: flagIconPurple
      },
      redFlag: {
        component: flagIconRed
      },
      yellowFlag: {
        component: flagIconYellow
      },
      yellowFlagStrikeThrough: {
        component: flagIconYellowStrikeThrough
      },
      limeStripeFlag: {
        component: flagIconLimeStripe
      },
      iconTeacher: {
        component: iconTeacher
      },
      iconTeacherBlueSmall: {
        component: iconTeacherBlueSmall
      },
      iconRedEye: {
        component: iconRedEye
      }
    }
  },
  theme: {
    themes: {
      light: {
        primary: {
          base: doeColors.primary.navy,
          lighten1: '#1A3B69',
          lighten2: '#1D428A',
          lighten3: '#C8DCF0'
        },
        secondary: doeColors.primary.lightblue,
        accent: doeColors.secondary.blue1,
        success: doeColors.secondary.blue2,
        grey: {
          base: doeColors.userInterface.darkgray,
          lighten1: doeColors.userInterface.grey70,
          lighten2: doeColors.userInterface.grey60,
          lighten3: doeColors.userInterface.grey30,
          lighten4: doeColors.userInterface.grey20,
          lighten5: doeColors.userInterface.grey15,
          lighten6: doeColors.userInterface.grey10,
          lighten7: doeColors.userInterface.grey7
        },
        green: {
          base: doeColors.notification.successgreen
        },
        red: {
          base: doeColors.primary.red
        },
        lightblue: {
          base: doeColors.primary.lightblue
        },
        error: {
          base: doeColors.notification.errorred
        },
        navy: {
          base: doeColors.primary.navy
        }
      }
    }
  }
})
