import API from '@/api/server/schoolCapStatus'
import { generateSchoolCapStatusExportData } from '@/utils/generalUtils'
import moment from 'moment'
import { API_ERROR_SUBTYPES } from '@/constants'

const schoolCapStatusModule = {
  state: {
    smiCapStatusChangeLog: [],
    hasCapStatusChangeLogError: false,
    loadingRetrievingLog: false,
    calculateDeltas: [],
    calculateDeltasMetadata: [],
    calculateDeltasResponse: {},
    hasRetrievingError: false,
    noRecords: false,
    hasRecords: false,
    isPublishing: false,
    publishSucceed: false,
    hasSystemError: false,
    downLoadError: false
  },
  getters: {
    smiCapStatusChangeLog: (state) => state.smiCapStatusChangeLog,
    hasCapStatusChangeLogError: (state) => state.hasCapStatusChangeLogError,
    loadingRetrievingLog: (state) => state.loadingRetrievingLog,
    calculateDeltas: (state) => state.calculateDeltas,
    calculateDeltasMetadata: (state) => state.calculateDeltasMetadata,
    calculateDeltasResponse: (state) => state.calculateDeltasResponse,
    hasRetrievingError: (state) => state.hasRetrievingError,
    noRecords: (state) => state.noRecords,
    hasRecords: (state) => state.hasRecords,
    isPublishing: (state) => state.isPublishing,
    publishSucceed: (state) => state.publishSucceed,
    hasSystemError: (state) => state.hasSystemError,
    downLoadError: (state) => state.downLoadError
  },
  mutations: {
    setSmiCapStatusChangeLog(state, smiCapStatusChangeLog) {
      state.smiCapStatusChangeLog = smiCapStatusChangeLog
    },
    setCalculateDeltas(state, calculateDeltas) {
      state.calculateDeltas = calculateDeltas
    },
    setCalculateDeltasMetadata(state, calculateDeltasMetadata) {
      state.calculateDeltasMetadata = calculateDeltasMetadata
    },
    setCalculateDeltasResponse(state, calculateDeltasResponse) {
      state.calculateDeltasResponse = calculateDeltasResponse
    },
    setLoadingRetrievingLog(state, loadingRetrievingLog) {
      state.loadingRetrievingLog = loadingRetrievingLog
    },
    setRetrievingError(state, hasRetrievingError) {
      state.hasRetrievingError = hasRetrievingError
    },
    setNoRecords(state, noRecords) {
      state.noRecords = noRecords
    },
    setHasRecords(state, hasRecords) {
      state.hasRecords = hasRecords
    },
    setIsPublishing(state, isPublishing) {
      state.isPublishing = isPublishing
    },
    setPublishSucceed(state, publishSucceed) {
      state.publishSucceed = publishSucceed
    },
    setHasSystemError(state, hasSystemError) {
      state.hasSystemError = hasSystemError
    },
    setDownLoadError(state, downLoadError) {
      state.downLoadError = downLoadError
    }
  },
  actions: {
    fetchRetrieveLog({ commit, dispatch }) {
      const fallbackCode = API_ERROR_SUBTYPES.retrieveLog
      commit('setShowSpinnerForApplication', true)
      return API.fetchRetrieveLog()
        .then((response) => {
          const responseWasSuccessful = response.status === 200
          const apiCallWasSuccessful = response.data?.status === 200
          if (responseWasSuccessful && apiCallWasSuccessful) {
            dispatch('removeApiError', fallbackCode)
            const smiCapStatusChangeLog = response.data.body ?? []
            const formattedSmiCapStatusChangeLog = smiCapStatusChangeLog.map(
              (log) => {
                return {
                  ...log,
                  lastUpdated: moment(log.lastUpdated).format('YYYY-MM-DD')
                }
              }
            )
            commit('setSmiCapStatusChangeLog', formattedSmiCapStatusChangeLog)
          } else {
            dispatch('setAPIError', fallbackCode)
          }
          return response
        })
        .finally(() => {
          commit('setShowSpinnerForApplication', false)
        })
    },
    fetchCalculateDeltas({ commit }) {
      commit('setLoadingRetrievingLog', true)
      return API.fetchCalculateDeltas()
        .then((response) => {
          commit('setLoadingRetrievingLog', false)
          commit('setRetrievingError', false)
          let calculateDeltasMetadata = []
          if (response.data.body.metadata) {
            calculateDeltasMetadata = response.data.body.metadata
            commit('setCalculateDeltasMetadata', calculateDeltasMetadata)
            commit('setLoadingRetrievingLog', false)
            if (
              calculateDeltasMetadata.totalChangesToCapStatus > 0 ||
              calculateDeltasMetadata.totalChangesThatRequireNotification > 0
            ) {
              commit('setHasRecords', true)
              commit('setNoRecords', false)
            }
            if (
              calculateDeltasMetadata.totalChangesToCapStatus == 0 &&
              calculateDeltasMetadata.totalChangesThatRequireNotification == 0
            ) {
              commit('setNoRecords', true)
              commit('setHasRecords', false)
            }
          }
          if (response.data.body.data) {
            commit('setCalculateDeltas', response.data.body.data)
          }
          commit('setCalculateDeltasResponse', response.data.body)
        })
        .catch(() => {
          commit('setLoadingRetrievingLog', false)
          commit('setRetrievingError', true)
        })
    },
    downLoadCsv({ commit, getters }) {
      try {
        const csvContent = generateSchoolCapStatusExportData(
          getters.calculateDeltas
        )
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8,'
        })
        const objUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', objUrl)
        let name = 'School Cap Status Staged {YYYYMMDD}.csv'
        const todayDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replaceAll('-', '')
        name = name.replace('{YYYYMMDD}', todayDate)
        link.setAttribute('download', name)
        link.target = '_blank'
        link.click()
        commit('setDownLoadError', false)
      } catch (error) {
        commit('setDownLoadError', true)
      }
    },
    publishChangesToSI({ commit, dispatch }, calculateDeltasResponse) {
      commit('setIsPublishing', true)
      commit('setHasRecords', false)
      commit('setNoRecords', false)
      return API.publishChangesToSI(calculateDeltasResponse)
        .then((response) => {
          commit('setIsPublishing', false)
          commit('setHasSystemError', false)
          if (
            response &&
            response.data.status >= 200 &&
            response.data.status < 300
          ) {
            dispatch('fetchRetrieveLog')
            commit('setPublishSucceed', true)
          }
        })
        .catch(() => {
          commit('setIsPublishing', false)
          commit('setHasSystemError', true)
          commit('setPublishSucceed', false)
        })
    }
  }
}

export default schoolCapStatusModule
