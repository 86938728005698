<template>
  <div class="content">
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start" v-if="isEditing">
              <v-icon size="48px" class="banner__icon mr-3">
                mdi-pencil-outline
              </v-icon>
              <div>
                <h1 class="banner__title">Create/Edit Option</h1>
              </div>
            </v-col>
            <v-col cols="8" class="d-flex align-start" v-else>
              <v-icon size="48px" class="banner__icon mr-3"> star </v-icon>
              <div>
                <h1 class="banner__title">SRE/SEE Option details</h1>
              </div>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>
    <div class="mainContent">
      <NavBar />
      <div class="card">
        <option-details />
      </div>
    </div>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core'
import NavBar from '@/components/NavBar.vue'
import OptionDetails from '@/components/OptionDetails.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OptionDetailsView',
  components: {
    Banner,
    NavBar,
    OptionDetails
  },
  computed: {
    ...mapGetters(['optionEditMode']),
    isEditing() {
      return !!this.optionEditMode
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  padding: 0 50px;
}
::v-deep div {
  .v-tab {
    &[aria-selected='false'] {
      color: $grey-dark !important;
    }
  }
}
</style>
