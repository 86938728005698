<template>
  <div class="option-details">
    <v-form ref="optionForm" v-model="isFormValid">
      <v-card class="mx-auto pa-8" outlined>
        <div class="title ads-primary-blue pb-3">SRE/SEE option</div>

        <ads-text-field
          class="column-text-short"
          v-model="optionItem.title"
          :label="isEditing ? 'Title*' : 'Title'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.titleRules"
          persistent-placeholder
          placeholder="Type title"
          maxlength="255"
        ></ads-text-field>

        <ads-select
          class="column-text-short"
          ref="currentEditSelect"
          v-model="optionItem.type"
          :items="isAMA ? optionTypeOptionsWithAMA : optionTypeOptions"
          :label="isEditing ? 'Type*' : 'Type'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.required"
          persistent-placeholder
          placeholder="Select type"
          :disabled="!isCreateMode && isAMA"
        ></ads-select>

        <ads-text-field
          class="column-text-short"
          label="Subtitle"
          value="No religious or ethics education"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          persistent-placeholder
          v-if="isAMA"
          :disabled="!isCreateMode && isAMA"
          placeholder="No religious or ethics education"
          maxlength="255"
        ></ads-text-field>

        <read-only-select
          class="column-text-short"
          aria-label="Provider(s)*"
          item-text="name"
          item-value="id"
          v-model="optionItem.providers"
          :items="providers"
          :label="isEditing ? 'Provider(s)*' : 'Provider(s)'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.providerRules"
          persistent-placeholder
          multiple
          chips
          deletable-chips
          placeholder="Select provider(s)"
          v-else
        ></read-only-select>

        <ads-text-area
          class="column-text-short"
          v-model="optionItem.description"
          :label="isEditing ? 'Description*' : 'Description'"
          :is-editing="isEditing"
          is-allow-editing-mode
          counter
          :counter-value="
            (value) => {
              return value ? value.length + '/300 characters' : ''
            }
          "
          :set-edit-mode="setEditMode"
          :rules="validationRules.descriptionRules"
          no-resize
          persistent-placeholder
        >
        </ads-text-area>
        <ads-select
          ref="currentEditSelect"
          v-model="optionItem.status"
          :items="optionStatusItems"
          :label="isEditing ? 'Status*' : 'Status'"
          :is-editing="isEditing"
          is-allow-editing-mode
          :set-edit-mode="setEditMode"
          :rules="validationRules.required"
          persistent-placeholder
          placeholder="Select status"
          :disabled="!isCreateMode && isAMA"
        ></ads-select>
      </v-card>
      <v-card
        class="mx-auto pt-8 pr-0 d-flex justify-end action-card"
        outlined
        color="transparent"
      >
        <ads-button
          v-if="!isCreateMode && !isAMA"
          class="white--text delete-option"
          color="red"
          @click="confirmDelete"
        >
          Delete option
        </ads-button>
      </v-card>
      <v-footer padless class="form-btns" v-if="isEditing" fixed>
        <v-container fluid>
          <v-card class="rounded-0">
            <v-row justify="end">
              <v-col class="text-right pr-8">
                <ads-button
                  class="pr-5"
                  tertiary
                  @click="handleCancel"
                  color="navy"
                  button-text="Cancel"
                >
                </ads-button>
                <ads-button
                  class="white--text ml-3"
                  @click="handleSave"
                  button-text="Save"
                  :disabled="!isFormValid"
                  :loading="isUpdateLoading"
                >
                  <template #loader>
                    <v-progress-circular
                      class="icon spin button__spinner"
                      :size="20"
                      :width="4"
                      color="white"
                      indeterminate
                      aria-label="loading"
                    />
                  </template>
                </ads-button>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-footer>
    </v-form>
    <modal-dialog
      :open-dialog="showDelete"
      max-width="645px"
      title="You are about to delete an SRE/SEE option"
      icon="mdi-alert-outline"
      :display-close-btn="true"
      aria-label-text="Close delete option"
      :actions="deleteActions"
      :message="deleteDialogMessage"
      @close="showDelete = false"
      :return-handler="handleDelete"
      content-class="warning-dialog"
      btn-height="44px"
      :is-loading="optionDialogLoading"
    />
    <modal-dialog
      :open-dialog="showPublishedToDraftModal"
      max-width="810px"
      title="You are about to change the status of the published SRE/SEE option."
      icon="mdi-alert-outline"
      :display-close-btn="true"
      aria-label-text="Close"
      :actions="publishedToDraftActions"
      :message="publishedToDraftDialogMessage"
      @close="showPublishedToDraftModal = false"
      :return-handler="handlePublishedToDraft"
      content-class="warning-dialog"
      btn-height="44px"
    />
  </div>
</template>

<script>
import {
  AdsTextField,
  AdsButton,
  AdsSelect,
  AdsTextArea
} from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import lodash from 'lodash'
import {
  OPTION_STATUS,
  STATUS_CHIPS_OPTIONS,
  ROUTE_PATH,
  OPTION_TYPE
} from '@/constants'
import doeColors from '@/settings/doe-colors'
import ModalDialog from '@/components/ModalDialog.vue'
import ReadOnlySelect from '@/components/ReadOnlySelect.vue'
import { upperCaseNoSpaceString } from '@/utils/generalUtils'

export default {
  name: 'OptionDetails',
  components: {
    AdsTextField,
    AdsButton,
    ModalDialog,
    AdsSelect,
    AdsTextArea,
    ReadOnlySelect
  },
  data() {
    const self = this
    return {
      optionStatusItems: [OPTION_STATUS.PUBLISHED, OPTION_STATUS.DRAFT],
      optionTypeOptions: [OPTION_TYPE.SRE, OPTION_TYPE.SEE],
      optionTypeOptionsWithAMA: [
        OPTION_TYPE.SRE,
        OPTION_TYPE.SEE,
        OPTION_TYPE.AMA
      ],
      optionItem: {
        title: null,
        description: null,
        providers: [],
        status: null,
        type: null
      },
      deleteActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Confirm delete',
          color: doeColors.primary.red,
          size: 'large',
          outlined: false,
          progress: true
        }
      ],
      publishedToDraftActions: [
        {
          name: 'Cancel',
          color: doeColors.primary.navy,
          size: 'medium',
          outlined: true
        },
        {
          name: 'Confirm & Save',
          color: doeColors.primary.red,
          size: 'large',
          outlined: false,
          progress: true
        }
      ],
      showDelete: false,
      isFormValid: false,
      uniqueTitles: [],
      validationRules: {
        required: [(value) => !!value || 'Required'],
        titleRules: [
          (value) => !!value || 'Required',
          (value) =>
            self.isUniqueTitle(value) || 'Unique option title required',
          (value) =>
            (value && value.length <= 255) ||
            'Title should be 255 characters or less',
          (value) =>
            self.isHavingBrackets(value) || 'Title cannot contain brackets ( )'
        ],
        descriptionRules: [
          (value) => !!value || 'Required',
          (value) =>
            (value && value.length <= 300) ||
            'Description should be 300 characters or less'
        ],
        providerRules: [(value) => value.length > 0 || 'Required']
      },
      isCreateMode: false,
      initialStatus: null,
      showPublishedToDraftModal: false
    }
  },
  computed: {
    ...mapGetters([
      'optionEditMode',
      'providersFiltered',
      'optionById',
      'optionUniqueTitles',
      'optionsLoading',
      'optionDialogLoading',
      'providersWithApprovedStatus'
    ]),
    isEditing() {
      return !!this.optionEditMode
    },
    deleteDialogMessage() {
      return `<p>If you proceed, the SRE/SEE option '<b>${this.optionItem.title}</b>' will be deleted from the system. Schools will no longer be able to offer this option.</p><p>Affected schools will be notified about this change.</p>`
    },
    publishedToDraftDialogMessage() {
      return `<p>If you proceed, the published SRE/SEE option '<b>${this.optionItem.title}</b>' will be moved to draft status. Affected schools will be notified about this change.</p>`
    },
    providers() {
      return this.providersWithApprovedStatus || []
    },
    isAMA() {
      return OPTION_TYPE.AMA === this.optionItem.type
    },
    isUpdateLoading() {
      return !!this.optionsLoading
    }
  },
  beforeCreate() {
    this.$store.commit('setOptionDialogLoading', false)
  },
  mounted() {
    const routePath = this.$router.currentRoute.path
    if (routePath === ROUTE_PATH.OPTION.create) {
      this.isCreateMode = true
      this.optionTypeOptions = [OPTION_TYPE.SRE, OPTION_TYPE.SEE] // remove the AMA type for creation
      this.uniqueTitles = this.optionUniqueTitles('')
    }
    const itemId = this.$route.params.id
    if (itemId) {
      const item = this.optionById(itemId)
      if (item) {
        this.initialStatus = item.status
        this.optionItem = lodash.cloneDeep(item)
        this.uniqueTitles = this.optionUniqueTitles(item.title)
      }
    }
  },
  methods: {
    setEditMode(ref) {
      if (ref) {
        this.$store.commit('setOptionEditMode', true)
      }
    },
    getStatusChipDetails(status) {
      if (status) {
        return STATUS_CHIPS_OPTIONS['Default']
      }
      return ''
    },
    confirmDelete() {
      this.showDelete = true
    },
    async handleDelete(ref) {
      if (ref === 'Cancel') {
        this.showDelete = false
      } else if (ref === 'Confirm delete') {
        this.$store
          .dispatch('deleteOption', this.optionItem)
          .then((dispatchSucceeded) => {
            this.showDelete = false
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      }
    },
    async handlePublishedToDraft(ref) {
      if (ref === 'Cancel') {
        this.showPublishedToDraftModal = false
      } else if (ref === 'Confirm & Save') {
        this.showPublishedToDraftModal = false
        this.$store
          .dispatch('updateOption', this.optionItem)
          .then((dispatchSucceeded) => {
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      }
    },
    async handleSave() {
      const option = this.optionItem
      if (this.isCreateMode) {
        this.$store
          .dispatch('createOption', option)
          .then((dispatchSucceeded) => {
            if (dispatchSucceeded) {
              this.$router.push('/')
            }
          })
      } else {
        if (
          this.initialStatus === OPTION_STATUS.PUBLISHED &&
          option.status === OPTION_STATUS.DRAFT
        ) {
          this.showPublishedToDraftModal = true
          return false
        } else {
          this.$store
            .dispatch('updateOption', option)
            .then((dispatchSucceeded) => {
              if (dispatchSucceeded) {
                this.$router.push('/')
              }
            })
        }
      }
    },
    handleCancel() {
      this.$store.commit('setOptionEditMode', false)
      this.$router.push('/')
    },
    isUniqueTitle(value) {
      if (value) {
        const upperCaseName = upperCaseNoSpaceString(value)
        if (this.uniqueTitles.includes(upperCaseName)) {
          return false
        }
      }
      return true
    },
    isHavingBrackets(value) {
      if (value) {
        if (value.includes('(') || value.includes(')')) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.option-details {
  .title {
    margin-bottom: 15px;
  }
  div.v-card.action-card {
    padding-bottom: 90px !important;
  }
  div.v-card {
    width: 645px;
  }
  div.ChipLabel {
    font-size: 1rem !important;
  }
  span.ChipDetails {
    font-size: 14px;
    border-color: #495054;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    height: 29px;
    box-sizing: border-box;
    /* Auto layout */

    flex-direction: row;
    justify-content: center;
    gap: 10px;

    /* Greys / White */
    background: #ffffff;

    /* Greys/Grey 02 */
    border: 1px solid #495054;
    border-radius: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .v-btn.delete-option:focus {
    border: 1px solid $ads-grey-01 !important;
  }

  .form-btns .v-card {
    margin-top: auto;
    width: 100%;
    .v-card {
      width: 100% !important;
      box-shadow: 0 3px 23px rgba(0, 0, 0, 0.15);
    }
  }
}

::v-deep .displayField {
  margin: 10px 0 20px 5px;

  p {
    margin-bottom: 8px !important;
  }
}

::v-deep input::placeholder {
  color: $ads-grey-01 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
