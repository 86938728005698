<template>
  <div>
    <ApiError
      v-if="apiErrors.includes(getErrorSubtype('fetchCommunications'))"
      dispatch="fetchCommunications"
    />
    <div v-else class="notificationsList lighten-6">
      <div class="pb-3 d-flex justify-space-between align-center">
        <div>
          <span class="filterLabel">Filter by:</span>
          <span class="pl-3">
            <list-view-filter
              ref="startDateFilter"
              label="Start date"
              type="daterange"
              :settings="{
                min: this.firstStartDate,
                max: this.lastStartDate
              }"
              :filter-disabled="
                filteredItems.some(
                  (item) => item[CT_PROPERTIES.START_DATE] === null
                )
              "
              @updatefilter="
                updateFilterValue({
                  filterName: 'startDate',
                  value: $event
                })
              "
            />
          </span>
          <span class="pl-3">
            <list-view-filter
              ref="endDateFilter"
              label="End date"
              type="daterange"
              :settings="{
                min: this.firstEndDate,
                max: this.lastEndDate
              }"
              :filter-disabled="
                filteredItems.some(
                  (item) => item[CT_PROPERTIES.END_DATE] === null
                )
              "
              @updatefilter="
                updateFilterValue({
                  filterName: 'endDate',
                  value: $event
                })
              "
            />
          </span>
          <span class="pl-3">
            <list-view-filter
              ref="statusFilter"
              label="Status"
              type="checkbox"
              :filter-options="statusFilters"
              height="auto"
              @updatefilter="
                updateFilterValue({
                  filterName: 'status',
                  value: $event
                })
              "
            />
          </span>
          <clear-filters @click="handleClearFilters" v-if="isFiltered" />
        </div>
        <div class="pt-0 pr-0 text-right">
          <span class="pl-3">
            <ads-button
              v-if="actionFrom === 0"
              class="mr-0"
              icon="add_circle_outline"
              button-text="Create notification"
              @click="createOption"
            />
          </span>
        </div>
      </div>
      <v-card>
        <ads-data-table
          v-if="!isLoading"
          v-model="selectedIds"
          :headers="headers"
          :items="filteredItems"
          :search="searchValue"
          item-key="id"
          :items-per-page="pageLength"
          :footer-props="{
            'items-per-page-options': [10, 15, 50]
          }"
          @pagination="totalResults"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          must-sort
          :no-results-found="'No results found. Try removing some filters or clearing the keyword search.'"
          :no-data-text="
            isFiltered
              ? 'No results found. Try removing some filters or clearing the keyword search.'
              : 'No results found.'
          "
        >
          <template #top>
            <v-row class="row-line, pb-4" align="center">
              <v-col>
                <v-text-field
                  class="searchInput pl-2"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchValue"
                  label="Search for keywords"
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="auto" class="pr-6 align-self-end body-1 result-text">
                <span>
                  {{ resultsText(itemsLength) }}
                </span>
              </v-col>
            </v-row>
          </template>
          <template slot="item" slot-scope="props">
            <!-- Table template for "out of area applications" -->
            <tr>
              <td class="text-xs-right">
                {{ dateFormat(props.item[CT_PROPERTIES.LAST_EDITED]) }}
              </td>
              <td class="text-xs-right">
                {{ dateFormat(props.item[CT_PROPERTIES.START_DATE]) }}
              </td>
              <td class="text-xs-right">
                {{ dateFormat(props.item[CT_PROPERTIES.END_DATE]) }}
              </td>
              <td class="text-xs-right">
                <a href="#" @click.prevent="showCommunication(props.item)">
                  {{ props.item[CT_PROPERTIES.NOTIFICATION_ID] }}
                </a>
              </td>
              <td class="text-xs-right">
                {{ props.item[CT_PROPERTIES.NO_OF_SCHOOLS] }}
              </td>
              <td class="text-xs-right">
                <chip
                  v-bind="
                    getStatusChipDetails(
                      props.item[CT_PROPERTIES.STATUS_ALTERED]
                    )
                  "
                  small
                />
              </td>
            </tr>
          </template>
        </ads-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Chip, AdsButton, AdsDataTable } from '@nswdoe/doe-ui-core'
import {
  COMMUNICATION_STATUSES,
  COMMUNICATION_STATUS_CHIPS,
  CT_PROPERTIES,
  API_ERROR_SUBTYPES
} from '@/constants'
import ApiError from '@/components/ApiError.vue'
import { mapGetters } from 'vuex'
import ListViewFilter from './ListViewFilter.vue'
import ClearFilters from './ClearFilters.vue'

export default {
  name: 'CommunicationsList',
  props: {
    actionFrom: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: CT_PROPERTIES.LAST_EDITED
    },
    sortDesc: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedIds: [],
      searchValue: '',
      pageLength: 50,
      itemsLength: 0,
      CT_PROPERTIES,
      listFilters: []
    }
  },
  components: {
    ApiError,
    Chip,
    AdsButton,
    AdsDataTable,
    ListViewFilter,
    ClearFilters
  },
  computed: {
    ...mapGetters(['apiErrors', 'communicationsLoading']),
    headers() {
      return [
        {
          text: 'Last edited',
          value: CT_PROPERTIES.LAST_EDITED,
          align: 'start',
          filterable: false
        },
        {
          text: 'Start date',
          value: CT_PROPERTIES.START_DATE,
          align: 'start',
          filterable: false
        },
        {
          text: 'End date',
          value: CT_PROPERTIES.END_DATE,
          align: 'start',
          filterable: false
        },
        {
          text: 'Notification ID',
          value: CT_PROPERTIES.NOTIFICATION_ID,
          align: 'start'
        },
        {
          text: 'No. of schools',
          value: CT_PROPERTIES.NO_OF_SCHOOLS,
          align: 'start',
          filterable: false
        },
        {
          text: 'Status',
          value: CT_PROPERTIES.STATUS_ALTERED,
          align: 'start',
          filterable: false
        }
      ]
    },
    isLoading() {
      return !!this.communicationsLoading
    },
    isFiltered() {
      return this.listFilters.length > 0
    },
    firstStartDate() {
      return this.items.map((item) => item[CT_PROPERTIES.START_DATE]).sort()[0]
    },
    lastStartDate() {
      return this.items
        .map((item) => item[CT_PROPERTIES.START_DATE])
        .sort()
        .at(-1)
    },
    firstEndDate() {
      return this.items
        .map((item) => item[CT_PROPERTIES.END_DATE])
        .filter((d) => d)
        .sort()[0]
    },
    lastEndDate() {
      return this.items
        .map((item) => item[CT_PROPERTIES.END_DATE])
        .filter((d) => d)
        .sort()
        .at(-1)
    },
    statusFilters() {
      return [
        ...new Set(
          this.items.map(
            (item) =>
              COMMUNICATION_STATUSES[
                this.getStatusChipDetails(item[CT_PROPERTIES.STATUS_ALTERED])
                  .text
              ]
          )
        )
      ].sort()
    },
    filteredItems() {
      return this.items.filter((item) => {
        if (this.listFilters.length > 0) {
          return this.listFilters
            .map(({ type, value }) => {
              switch (type) {
                case 'status':
                  return value.includes(
                    COMMUNICATION_STATUSES[
                      this.getStatusChipDetails(
                        item[CT_PROPERTIES.STATUS_ALTERED]
                      ).text
                    ]
                  )
                case 'startDate':
                  return this.isDateBetweenTwoDatesTimeRemoved(
                    item[CT_PROPERTIES.START_DATE],
                    { from: value[0], to: value[1] }
                  )
                case 'endDate':
                  return this.isDateBetweenTwoDatesTimeRemoved(
                    item[CT_PROPERTIES.END_DATE],
                    { from: value[0], to: value[1] }
                  )
                default:
                  return true
              }
            })
            .every((i) => i)
        }
        return true
      })
    }
  },
  methods: {
    getErrorSubtype(code) {
      return API_ERROR_SUBTYPES[code]
    },
    createOption() {
      this.$store.commit('setCommunicationBackIndex', this.actionFrom)
      this.$router.push('/communications/new')
    },
    totalResults(pagination) {
      this.itemsLength = pagination ? pagination.itemsLength : 0
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    getStatusChipDetails(status) {
      if (status) {
        return COMMUNICATION_STATUS_CHIPS[status.split('|')[1]]
      }
      return ''
    },
    dateSplit(value) {
      return value.split(' ')[0]
    },
    dateFormat(value) {
      if (value) {
        return new Date(this.dateSplit(value)).toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })
      }
      return ''
    },
    showCommunication(communication) {
      this.$store.commit('setCommunicationEditMode', false)
      this.$store.commit('setCommunicationBackIndex', this.actionFrom)
      this.$router.push(`/communications/edit/${communication.uuid}`)
    },
    isDateBetweenTwoDatesTimeRemoved(date, { from, to }) {
      const dateToCheck = new Date(this.dateSplit(date))
      const fromDate = new Date(this.dateSplit(from))
      if (!to) {
        return dateToCheck.toDateString() === fromDate.toDateString()
      }
      const toDate = new Date(this.dateSplit(to))
      return dateToCheck >= fromDate && dateToCheck <= toDate
    },
    updateFilterValue({ filterName, value }) {
      const isStatusFiltered = this.listFilters.some(
        (filter) => filter.type === filterName
      )

      this.listFilters = [
        ...(isStatusFiltered
          ? this.listFilters.filter((filter) => filter.type !== filterName)
          : this.listFilters),
        ...(() => {
          if (value === null || value.length === 0) {
            // Delete
            return []
          } else {
            // Add / Edit
            return [
              {
                type: filterName,
                value
              }
            ]
          }
        })()
      ]
    },
    handleClearFilters() {
      this.listFilters = []
      this.$refs.statusFilter.clearFilter()
      this.$refs.startDateFilter.clearFilter()
      this.$refs.endDateFilter.clearFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.notificationsList {
  overflow: auto;
  min-width: 768px;
  min-height: 100%;
  width: 100%;
  padding: 0.5rem 0.2rem;
}

::v-deep
  .providerAlertBox
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  .d-flex
  .flex-column
  .snackbar--text {
  font-style: normal;
  font-family: 'Public Sans';
  line-height: 24px;
  color: $ads-grey-01;
}

::v-deep
  .providerAlertBox
  .v-alert
  .v-alert__wrapper
  .v-alert__content
  .d-flex
  .flex-column
  .alert--textsimple {
  font-style: normal;
  font-family: 'Public Sans';
  line-height: 24px;
  color: $ads-grey-01;
  font-weight: 400;
}
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    tr {
      height: 64px;
    }
    th {
      white-space: nowrap;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }
    th[scope='col'] {
      font-size: 16px;
      font-weight: normal;
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      .v-data-table-header__icon {
        margin-left: 3px;
      }
    }
    th[scope='col'].active {
      font-weight: bold;
    }

    th[scope='col'].provider-name {
      padding-left: 1px !important;
    }

    th:first-child {
      vertical-align: middle;
      width: 10px !important;
      min-width: 10px !important;
      padding-right: 4px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    th.sortable.active .v-data-table-header__icon {
      color: $ads-primary-blue;
    }
  }
  tbody {
    tr {
      height: 64px;
    }
    td {
      vertical-align: top;
      overflow-wrap: break-word;
      border-bottom: 0 !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    td:first-child {
      vertical-align: top;
      width: 10px !important;
      min-width: 10px !important;
      padding-right: 1px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    td:nth-child(2) {
      min-width: 4px !important;
    }

    td:last-child {
      border-bottom: 1px solid white;
    }
    td span.v-chip__content {
      width: 117px;
      display: inline-grid;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
    }
  }

  div.v-data-footer {
    font-size: 14px;
    height: 64px;
  }

  .row-line {
    border-bottom: 1px solid #e0e0e0;
    margin: 0 5px 10px 0;
    padding: 0;
    width: 100%;
    height: 64px;
  }

  .results-text {
    margin: 25px 20px 0 0 !important;
  }

  .row-align-center {
    line-height: 200px;
  }

  .column-text-short {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .error-highlight {
    color: $ads-primary-red;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .result-text {
    line-height: 32px;
  }
}
</style>
