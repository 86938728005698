import axiosRetry, { isRetryableError, isNetworkError } from 'axios-retry'

function exponentialDelay(baseDelay = 100) {
  return (retryNumber = 0) => {
    const delay = 2 ** retryNumber * baseDelay
    const randomSum = delay * 0.2 * Math.random() // 0-20% of the delay
    return delay + randomSum
  }
}

// Retries iff network error or 5xx status
export function applyAxiosRetry(axios) {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: exponentialDelay(300),
    retryCondition: (e) => isRetryableError(e) || isNetworkError(e)
  })
}

export function applyAuthTokenInterceptor(axios) {
  axios.interceptors.request.use(
    (request) => {
      const accessToken = sessionStorage.getItem('accessToken')
      const idToken = sessionStorage.getItem('idToken')
      if (idToken && accessToken) {
        const protectedAPIs = ['v1/smi', 'v1/admin/smi']
        if (protectedAPIs.some((apiUrl) => request.url.includes(apiUrl))) {
          request.headers.Authorization = `Bearer ${accessToken}`
          request.headers['x-id-token'] = idToken
          request.headers['Content-Type'] = 'application/json'
        }
      }
      return request
    },
    (error) => Promise.reject(error)
  )
}

export function applyRefreshInterceptor(axios, store) {
  let isRefreshing = false
  let requestsAwaitingRefresh = []

  function addToRefreshWaitQueue(callback) {
    requestsAwaitingRefresh.push(callback)
  }

  function onRefreshed() {
    requestsAwaitingRefresh.map((callback) => callback())
  }

  axios.interceptors.response.use(
    // return a successful response with no processing
    (response) => response,
    (error) => {
      const originalRequest = error.config
      const response = error.response

      if (!response || !originalRequest) {
        return Promise.reject(error)
      }

      const { status } = response

      // Token refresh or auth related API didn't work, so user must reauthenticate.
      if (originalRequest.url.includes('access_token')) {
        store.dispatch('authenticateUser')
        return Promise.reject(error)
      }

      // Check if its a 401 & invoke refreshTokens action, before retrying the request
      else if (status === 401) {
        if (!isRefreshing) {
          isRefreshing = true
          store
            .dispatch('refreshTokens')
            .then((wasSuccess) => {
              if (wasSuccess) {
                isRefreshing = false
                // Refreshing complete and new token set. Re-run failed requests and empty the queue.
                onRefreshed()
                requestsAwaitingRefresh = []
              }
            })
            .catch((authError) => {
              // Token refresh didn't work, so reauthenticate user
              store.dispatch('authenticateUser')
              throw authError
            })
        }

        //Add callback to queue to rerun the 401'd request with new tokens
        return new Promise((resolve) => {
          addToRefreshWaitQueue(() => {
            resolve(axios(originalRequest))
          })
        })
      }

      // Shouldn't really get here... But ESLint wants this.
      return Promise.reject(error)
    }
  )
}

export default function setupAxios(axios, store) {
  applyAxiosRetry(axios)
  applyAuthTokenInterceptor(axios)
  applyRefreshInterceptor(axios, store)
}
