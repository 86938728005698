<template>
  <div>
    <banner class="d-none d-md-flex" default-background2>
      <template #content>
        <v-container fluid class="ml-10 pr-15">
          <v-row>
            <v-col cols="8" class="d-flex align-start">
              <v-icon size="48px" class="banner__icon mr-3">
                mdi-human-male-board
              </v-icon>
              <div>
                <h1 class="banner__title">SRE/SEE settings</h1>
                <p class="banner__text">Manage SRE/SEE Options and Providers</p>
              </div>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-container>
      </template>
    </banner>

    <v-container fluid>
      <div class="inline-alert__container">
        <slot name="inline-alert" />
      </div>
      <v-row>
        <v-col class="pt-0 pl-0 pr-0">
          <tab-navigation
            :items="tabItems"
            data-testid="option-list-tab"
            v-model="activeTab"
          >
            <template slot="content1">
              <v-row class="pr-5">
                <v-col class="pl-7"> <provider-list /></v-col>
              </v-row>
            </template>
            <template slot="content2">
              <v-row class="pr-5">
                <v-col class="pl-7"> <option-list /> </v-col>
              </v-row>
            </template>
          </tab-navigation>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Banner, TabNavigation } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import ProviderList from '@/components/ProviderList'
import OptionList from '@/components/OptionList'

export default {
  name: 'SreSeeSettingsView',
  components: {
    Banner,
    TabNavigation,
    ProviderList,
    OptionList
  },

  data: () => ({
    active_tab: 0,
    tabItems: [
      {
        index: 0,
        tab: 'PROVIDERS'
      },
      { index: 1, tab: 'SRE/SEE OPTIONS' }
    ]
  }),
  async created() {
    await this.$store.dispatch('showSpinnerForApplication')
    await this.$store.dispatch('fetchProviders')
    await this.$store.dispatch('fetchOptions')
    await this.$store.dispatch('hideSpinnerForApplication')
  },
  computed: {
    ...mapGetters(['optionBackButtonIndex']),
    activeTab() {
      return this.optionBackButtonIndex
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
::v-deep .v-tab {
  padding: 0 50px;
}
::v-deep div {
  .v-tab {
    &[aria-selected='false'] {
      color: $grey-dark !important;
    }
  }
}
</style>
