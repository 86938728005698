<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    v-model="show"
    :max-width="maxWidth"
  >
    <v-card id="adsDialogComponent">
      <div class="close-btn__wrapper">
        <v-btn
          v-if="displayCloseBtn"
          icon
          @click="show = false"
          :aria-label="ariaLabelText"
          :disabled="isLoading"
        >
          <v-icon> close </v-icon>
        </v-btn>
      </div>
      <slot name="title" :icon="icon" :title="title">
        <v-card-title class="d-flex justify-space-between">
          <div class="title">
            <v-avatar v-if="icon" size="40">
              <v-icon>{{ icon }}</v-icon>
            </v-avatar>
            {{ title }}
          </div>
        </v-card-title>
      </slot>
      <slot name="text" :message="message">
        <!-- The following eslint ignore was added after the introduction of 
          program wide eslint rules. The following line of code depends on a
          prop string. v-html is not the right way to achieve this, it 
          should be done with slots -->
        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component-->
        <v-card-text class="content" v-html="message" />
      </slot>
      <slot
        name="actions"
        :actions-list="actionsList"
        :btn-height="btnHeight"
        :btn-class="btnClass"
      >
        <v-card-actions>
          <div v-for="action in actionsList" :key="action.name">
            <v-btn
              @click="returnHandler(action.name)"
              :aria-label="action.name"
              :class="btnClass ? btnClass : ''"
              :color="
                $vuetify.theme.dark
                  ? action.darkModeColor
                    ? action.darkModeColor
                    : '#6BACE4'
                  : action.color
              "
              action.size
              :outlined="action.outlined"
              :height="btnHeight"
              :disabled="isLoading"
            >
              <v-progress-circular
                class="icon spin button__spinner"
                :size="20"
                :width="4"
                color="white"
                indeterminate
                aria-label="loading"
                v-if="action.progress"
                v-show="isLoading"
                mr-2
              />
              <v-icon class="pr-2" v-if="action.icon">
                {{ action.icon }}
              </v-icon>
              {{ action.name }}
            </v-btn>
          </div>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDialog',
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '675px'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    actions: {
      type: Array,
      default() {
        return []
      }
    },
    displayCloseBtn: {
      type: Boolean,
      default: false
    },
    ariaLabelText: {
      type: String,
      default: 'close'
    },
    message: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    },
    btnHeight: {
      type: String,
      default: '50'
    },
    returnHandler: {
      type: Function,
      default: (name) => {
        return name
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get() {
        return this.openDialog
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    actionsList() {
      return this.actions
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@nswdoe/doe-ui-core/styles/variables.scss';

.v-card {
  position: relative;

  .close-btn__wrapper {
    position: absolute;
    top: 9px;
    right: 9px;
  }

  .v-card__title {
    padding: 24px 10px 0 24px;
    margin-bottom: 18px;
    color: $ads-primary-blue !important;

    .v-avatar {
      background-color: $ads-light-blue !important;
      margin-right: 6px;
    }
  }

  .content {
    padding: 0 24px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 24px;
    color: $ads-grey-01 !important;
    background-color: unset !important;
  }

  .v-card__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px 24px !important;

    .v-btn {
      margin-left: 12px !important;
      padding: 0 16px;
      color: $ads-white;
    }
  }

  .v-btn:focus {
    outline: 3px auto -webkit-focus-ring-color;
  }
}

.theme--dark {
  .content,
  .v-card__title {
    color: $ads-primary-blue !important;
    background-color: unset !important;
  }

  .v-card__title .v-avatar {
    background-color: $ads-info-blue-dark !important;
  }

  .buttonText {
    color: $ads-dark !important;
  }
}

.v-progress-circular {
  margin-right: 5px;
}
</style>
