<!-- Component for filter options (Checkbox and radio seclection controls) -->
<template>
  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <a role="button" href="#" @click="menu = true" :aria-label="chipLabel">
        <v-chip
          class="chip"
          :disabled="
            (type !== 'daterange' && !filterOptions.length) ||
            filterDisabled === true
          "
          :class="selectedFilterOptions ? 'selected' : ''"
          outlined
          v-on="on"
        >
          {{ label }}
          <span v-if="selectedFilterOptions">
            <v-icon class="pl-1">cancel</v-icon>
          </span>
          <span v-else>
            <v-icon>arrow_drop_down</v-icon>
          </span>
        </v-chip>
      </a>
    </template>

    <focus-trap :is-active="isActive" v-if="type === 'daterange'">
      <v-card class="filterOptions">
        <v-date-picker v-model="selectedDate" range v-bind="settings" />
        <div class="d-flex justify-space-between pb-4">
          <v-btn
            text
            class="text-uppercase"
            color="primary"
            @click="handleDateRangeClear"
          >
            Clear
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="handleDateRangeFilter"
            :disabled="!selectedDate.length"
          >
            OK
          </v-btn>
        </div>
      </v-card>
    </focus-trap>

    <focus-trap
      :is-active="isActive"
      v-if="filterOptionsInternal.length && type !== 'daterange'"
    >
      <v-card class="filterOptions" :style="getStyle">
        <v-list class="overflow-y-auto" flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ label }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle v-if="comment" tabindex="0">{{
                comment
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <a
                href="#"
                @click="menu = false"
                class="dialogCloseBtn"
                aria-label="Close filter options menu"
                role="button"
              >
                <v-icon class="text-right closeBtn">mdi-close</v-icon>
              </a>
            </v-list-item-action>
          </v-list-item>
          <v-list-item-group
            v-model="selectedOptions"
            multiple
            active-class="lightblue"
          >
            <v-list-item
              v-for="filter in filterOptionsInternal"
              :key="filter.value"
            >
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active" color="primary">
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ filter.label }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </focus-trap>
  </v-menu>
</template>

<script>
import { FocusTrap } from '@nswdoe/doe-ui-core'
import _isEqual from 'lodash/isEqual'

export default {
  name: 'ListViewFilter',
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Show only'
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    showDefaultFilter: {
      type: Array,
      default: () => []
    },
    comment: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '250px'
    },
    height: {
      type: String,
      default: '400px'
    },
    columnWrapper: {
      type: String,
      default: ''
    },
    settings: {
      type: Object,
      default: () => {}
    },
    filterDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FocusTrap
  },
  data() {
    return {
      menu: false,
      selectedOptions: [],
      radioOption: null,
      isActive: false,
      selectedDate: [],
      dateSubmitted: false
    }
  },
  watch: {
    // DG: this logic won't actually clear the filters when the school is swapped.
    // This will only clear the filters if the options are different.
    filterOptions(newVal, oldVal) {
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        // don't clear the filters ( back to list view button)
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.selectedOptions = this.showDefaultFilter
        return
      }
      this.selectedOptions = []
      this.radioOption = null
    },
    menu(opened) {
      this.isActive = opened
      this.$emit('menuOpened', opened)
    },
    schoolCode(newVal, oldVal) {
      // clear selected filters on change of school switcher
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.selectedOptions = this.showDefaultFilter
        return
      }
      this.selectedOptions = []
      this.radioOption = null
    },
    selectedOptions(newVal, oldVal) {
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        return
      } else {
        if (Array.isArray(newVal) && newVal.length > 0) {
          if (
            Array.isArray(this.filterOptionsInternal) &&
            this.filterOptionsInternal.length > 0
          ) {
            const newSelectedOptions = newVal.map(
              (item) => this.filterOptionsInternal[item]
            )
            if (newSelectedOptions && newSelectedOptions.length > 0) {
              const selectedNames = newSelectedOptions.map((item) => item.value)
              this.$emit('updatefilter', selectedNames)
            }
          }
        } else {
          this.$emit('updatefilter', [])
        }
      }
    }
  },
  created() {
    // y67t set default filter - display applications for the current year
    if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
      this.selectedOptions = this.showDefaultFilter
    }
  },
  computed: {
    selectedFilterOptions() {
      if (this.type === 'checkbox') {
        return !!this.selectedOptions.length
      }
      if (this.type === 'radio') {
        return !!this.radioOption
      }
      if (this.type === 'daterange') {
        return !!this.selectedDate.length && this.dateSubmitted
      }
      return false
    },
    chipLabel() {
      return `Filter by ${this.label}`
    },
    filterOptionsInternal() {
      return this.filterOptions.map((option) =>
        typeof option === 'object' ? option : { value: option, label: option }
      )
    },
    schoolCode() {
      return this.$store.getters.selectedSchoolCode
    },
    getStyle() {
      return {
        'max-width': this.width,
        width: this.width,
        'max-height': this.height,
        height: this.height
      }
    }
  },
  methods: {
    clearFilter() {
      if (this.type === 'radio') {
        this.radioOption = null
        this.$emit('updatefilter', this.radioOption)
      } else if (this.type === 'daterange') {
        this.selectedDate = []
        this.dateSubmitted = false
        this.$emit('updatefilter', this.selectedDate)
      } else {
        this.selectedOptions = []
        this.$emit('updatefilter', this.selectedOptions)
      }
    },
    handleDateRangeFilter() {
      this.$emit('updatefilter', this.selectedDate.toSorted())
      this.dateSubmitted = true
      this.menu = false
    },
    handleDateRangeClear() {
      this.selectedDate = []
      this.$emit('updatefilter', this.selectedDate)
      this.dateSubmitted = false
      this.menu = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
  border-color: $ads-navy;
}
.checkFilter {
  margin-top: 2px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $grey-dark;
}

::v-deep .filterOptions .theme--light.v-list-item .v-list-item__subtitle {
  font-size: 16px;
  color: $grey-dark;
}

::v-deep .v-list-item__title {
  font-size: 16px;
}
::v-deep .v-list-item__action:first-child {
  margin-right: 11px;
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
}
.column_wrapper {
  max-height: 400px;
  display: flex;
  flex-flow: column wrap;
}

/* width */
::-webkit-scrollbar {
  width: 18px;
  height: 86px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $ads-light-10;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $ads-light-40;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey-1;
}
</style>
