const notificationsModule = {
  state: {
    snackbar: null,
    providerAlert: null,
    optionAlert: null,
    communicationAlert: null
  },
  getters: {
    snackbar: (state) => state.snackbar,
    providerAlert: (state) => state.providerAlert,
    optionAlert: (state) => state.optionAlert,
    communicationAlert: (state) => state.communicationAlert
  },
  mutations: {
    setSnackbar(state, option) {
      state.snackbar = option
    },
    setProviderAlert(state, option) {
      state.providerAlert = option
    },
    setOptionAlert(state, option) {
      state.optionAlert = option
    },
    setCommunicationAlert(state, option) {
      state.communicationAlert = option
    }
  },
  actions: {
    showSnackbar({ commit }, options) {
      commit('setSnackbar', options)
    },
    hideSnackbar({ commit }) {
      commit('setSnackbar', null)
    },
    showProviderAlert({ commit }, options) {
      commit('setMessageBox', options)
    },
    hideProviderAlert({ commit }) {
      commit('setProviderAlert', null)
    },
    showOptionAlert({ commit }, options) {
      commit('setMessageBox', options)
    },
    hideOptionAlert({ commit }) {
      commit('setOptionAlert', null)
    },
    showCommunicationAlert({ commit }, options) {
      commit('setMessageBox', options)
    },
    hideCommunicationAlert({ commit }) {
      commit('setCommunicationAlert', null)
    }
  }
}

export default notificationsModule
