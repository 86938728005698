<template>
  <div>
    <ApiError
      v-if="apiErrors.includes(getErrorSubtype('fetchProviders'))"
      dispatch="fetchProviders"
    />
    <div v-else class="providerList lighten-6">
      <v-row class="pb-3 d-flex align-center">
        <v-col cols="6" class="pt-1 row-align-center">
          <span class="filterLabel">Filter by:</span>
          <span class="pl-3">
            <list-view-filter
              ref="optionsFilter"
              label="Approved options"
              type="checkbox"
              :filter-options="optionsFilters"
              @updatefilter="updateOptionsFilter"
              width="587px"
              height="251px"
            />
          </span>
          <span class="pl-3">
            <list-view-filter
              ref="statusFilter"
              label="Status"
              type="checkbox"
              :filter-options="statusFilters"
              @updatefilter="updateStatusFilter"
              height="220px"
            />
          </span>
          <clear-filters @click="clearAllFilters()" v-if="isFiltered" />
        </v-col>
        <v-col cols="6" class="pt-0 pr-0 text-right">
          <span class="pl-3 pr-3">
            <ads-button
              class="mr-0"
              secondary
              icon="mdi-export-variant"
              button-text="Export csv"
              @click="handleExport"
            />
          </span>
          <span class="pl-3">
            <ads-button
              class="mr-0"
              icon="add_circle_outline"
              button-text="New provider"
              @click="createProvider"
            />
          </span>
        </v-col>
      </v-row>
      <v-card>
        <ads-data-table
          v-if="!isLoading"
          v-model="selectedIds"
          :headers="headers"
          :items="providerList"
          search-label="Search SRE SEE providers"
          :search="searchValue"
          :no-results-found="'No results found. Try removing some filters or clearing the keyword search.'"
          :no-data-text="'No results found. Try removing some filters or clearing the keyword search.'"
          item-key="id"
          :items-per-page="pageLength"
          :footer-props="{
            'items-per-page-options': [10, 15, 50]
          }"
          @pagination="totalResults"
          :custom-filter="customSearch"
          :sort-by="'providerName'"
          must-sort
          ref="providerDataTable"
        >
          <template #top>
            <v-row class="row-line, pb-4" align="center">
              <v-col>
                <v-text-field
                  class="searchInput pl-2"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchValue"
                  label="Search SRE/SEE providers"
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="auto" class="pr-6 align-self-end body-1 result-text">
                <span>
                  {{ resultsText(itemsLength) }}
                </span>
              </v-col>
            </v-row>
          </template>
          <template #[`item.providerName`]="{ item }">
            <div class="column-text-short">
              {{ item.providerName }}
            </div>
          </template>
          <template #[`item.approvedOptions`]="{ item }">
            <div class="column-text-short">
              {{ item.approvedOptions }}
            </div>
          </template>
          <template #[`item.status`]="{ item }">
            <chip v-bind="getStatusChipDetails(item.status)" small />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon color="#002664" class="mr-2" @click="showItem(item)">
              mdi-eye-outline
            </v-icon>
          </template>
        </ads-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Chip, AdsButton, AdsDataTable } from '@nswdoe/doe-ui-core'
import { STATUS_CHIPS, API_ERROR_SUBTYPES } from '@/constants'
import { mapGetters } from 'vuex'
import ListViewFilter from '@/components/ListViewFilter'
import ClearFilters from '@/components/ClearFilters'
import ApiError from '@/components/ApiError'
import lodash from 'lodash'
import { generateProviderCSVExportData } from '@/utils/generalUtils'

export default {
  name: 'ProviderList',
  data() {
    return {
      selectedIds: [],
      searchValue: '',
      pageLength: 50,
      itemsLength: 0
    }
  },
  components: {
    ApiError,
    AdsDataTable,
    Chip,
    AdsButton,
    ListViewFilter,
    ClearFilters
  },
  computed: {
    ...mapGetters([
      'apiErrors',
      'providers',
      'providersWithExpiredStatus',
      'providersFiltered',
      'statusFilter',
      'optionsFilter',
      'providerLoading'
    ]),
    headers() {
      return [
        {
          text: 'Provider name',
          value: 'providerName',
          class: 'provider-name text-wrap',
          align: 'start',
          width: '250px'
        },
        {
          text: 'Contact',
          value: 'contact',
          class: 'contact text-wrap',
          align: 'start',
          width: '210px'
        },
        {
          text: 'Approved options',
          value: 'approvedOptions',
          class: 'approved-options text-wrap',
          align: 'start',
          width: '280px'
        },
        {
          text: 'Insurance expiry',
          value: 'insuranceExpiry',
          class: 'insurance-expiry text-wrap',
          align: 'start',
          width: '180px'
        },
        {
          text: 'Last updated',
          value: 'lastUpdated',
          class: 'last-updated',
          align: 'start',
          width: '140px'
        },
        {
          text: 'Status',
          value: 'status',
          class: 'status text-wrap',
          align: 'start',
          width: '140px'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          class: 'action',
          align: 'start',
          width: '80px'
        }
      ]
    },
    providerList() {
      return this.providersFiltered
    },
    statusFilters() {
      const providers = this.providersWithExpiredStatus
      if (Array.isArray(providers) && providers.length > 0) {
        return [...new Set(providers.map((item) => item.status))].sort()
      }
      return []
    },
    optionsFilters() {
      const providers = this.providers
      if (Array.isArray(providers) && providers.length > 0) {
        let optionNames = []
        providers.forEach((item) => {
          if (item && item.options) {
            if (Array.isArray(item.options) && item.options.length > 0) {
              const names = item.options.map((optionItem) => optionItem.name)
              if (names) {
                optionNames = optionNames.concat(names)
              }
            }
          }
        })
        return [...new Set(optionNames)].sort()
      }
      return []
    },
    isFiltered() {
      return this.statusFilter.length || this.optionsFilter.length
    },
    isLoading() {
      return !!this.providerLoading
    }
  },
  methods: {
    getErrorSubtype(code) {
      return API_ERROR_SUBTYPES[code]
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    getStatusChipDetails(status) {
      if (status) {
        return STATUS_CHIPS[status]
      }
      return ''
    },
    totalResults(pagination) {
      this.itemsLength = pagination ? pagination.itemsLength : 0
    },
    updateStatusFilter(selectedStatus) {
      this.$store.commit('setStatusFilter', selectedStatus)
    },
    updateOptionsFilter(selectedOptions) {
      this.$store.commit('setOptionsFilter', selectedOptions)
    },
    showItem(item) {
      this.$store.commit('setProviderEditMode', false)
      this.$store.commit('setOptionBackButtonIndex', 0)
      this.$router.push(`/provider/edit/${item.id}`)
    },
    customSearch(value, search, item) {
      let isItemNameIncluded = false
      let isContactIncluded = false
      let isOptionIncluded = false
      let isExpiryDateIncluded = false
      let isStatusIncluded = false
      let isUpdateDateIncluded = false
      if (item) {
        const searchKey = search.toLowerCase()
        if (item.providerName) {
          isItemNameIncluded = item.providerName
            .toLowerCase()
            .includes(searchKey)
        }
        if (item.contact) {
          isContactIncluded = item.contact.toLowerCase().includes(searchKey)
        }
        if (item.approvedOptions) {
          isOptionIncluded = item.approvedOptions
            .toLowerCase()
            .includes(searchKey)
        }
        if (item.insuranceExpiry) {
          isExpiryDateIncluded = item.insuranceExpiry.includes(searchKey)
        }
        if (item.status) {
          isStatusIncluded = item.status.toLowerCase().includes(searchKey)
        }
        if (item.lastUpdated) {
          isUpdateDateIncluded = item.lastUpdated.includes(searchKey)
        }
      }
      return (
        isItemNameIncluded ||
        isContactIncluded ||
        isOptionIncluded ||
        isExpiryDateIncluded ||
        isStatusIncluded ||
        isUpdateDateIncluded
      )
    },
    clearAllFilters() {
      this.$refs.statusFilter.clearFilter()
      this.$refs.optionsFilter.clearFilter()
    },
    createProvider() {
      this.$store.commit('setProviderEditMode', true)
      this.$store.commit('setOptionBackButtonIndex', 0)
      this.$router.push(`/provider/new`)
    },
    async handleExport() {
      const filteredProviders =
        this.$refs.providerDataTable?.$refs?.vDataTable?.$children[0]
          .filteredItems
      const allProviders = this.providers
      const intersectedArray = lodash.intersectionBy(
        allProviders,
        filteredProviders,
        'id'
      )
      const csvContent = generateProviderCSVExportData(intersectedArray)
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
      const objUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', objUrl)
      let name = 'Provider SMI list {YYYYMMDD}.csv'
      const todayDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replaceAll('-', '')
      name = name.replace('{YYYYMMDD}', todayDate)
      link.setAttribute('download', name)
      link.target = '_blank'
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.providerList {
  overflow: auto;
  min-width: 768px;
  min-height: 100%;
  width: 100%;
  padding: 0.5rem 0.2rem;
}

::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    tr {
      height: 64px;
    }
    th {
      white-space: nowrap;
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }
    th[scope='col'] {
      font-size: 16px;
      font-weight: normal;
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      .v-data-table-header__icon {
        margin-left: 3px;
      }
    }
    th[scope='col'].active {
      font-weight: bold;
    }

    //th[scope="col"].provider-name {
    //  padding-left: 1px !important;
    //}

    th:first-child {
      vertical-align: middle;
      padding-right: 4px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    th:last-child {
      cursor: not-allowed;
      button.headerBtn {
        cursor: not-allowed;
        display: none;
      }
    }

    th.sortable.active .v-data-table-header__icon {
      color: $ads-primary-blue;
    }
  }
  tbody {
    tr {
      height: 64px;
    }
    td {
      vertical-align: top;
      overflow-wrap: break-word;
      border-bottom: 0 !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    td:first-child {
      vertical-align: top;
      width: 10px !important;
      min-width: 10px !important;
      padding-right: 1px !important;
      padding-left: 22px !important;
      div.v-data-table__checkbox {
        .v-icon.mdi-checkbox-blank-outline {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }
    td:last-child {
      border-bottom: 1px solid white;
    }
    td span.v-chip__content {
      width: 117px;
      display: inline-grid;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
    }
  }

  div.v-data-footer {
    font-size: 14px;
    height: 64px;
  }

  .row-line {
    border-bottom: 1px solid #e0e0e0;
    margin: 0 5px 10px 0;
    padding: 0;
    width: 100%;
    height: 64px;
  }

  .results-text {
    margin: 25px 20px 0 0 !important;
  }

  .row-align-center {
    line-height: 200px;
  }

  .column-text-short {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .result-text {
    line-height: 32px;
  }
}
</style>
