<template>
  <div ref="selectField">
    <div
      v-if="readonly || (!isEditing && isAllowEditingMode)"
      class="displayField"
      :class="{ readonly }"
      :tabindex="readonly ? -1 : 0"
      @keydown.enter="setEditMode($refs.selectField)"
      @click="setEditMode($refs.selectField)"
    >
      <label>{{ label }}</label>
      <div class="mt-3">
        <chip
          v-for="val in value"
          :key="type === 'schools' ? val.schoolCode : val.id"
          :text="type === 'schools' ? val.schoolName : val.name"
          class="mr-3"
          outlined
        />
      </div>
      <div class="editIcon" v-if="!readonly">
        <v-icon size="20px">mdi-pencil</v-icon>
      </div>
    </div>
    <div v-else>
      <v-autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        outlined
      />
    </div>
  </div>
</template>

<script>
import { Chip } from '@nswdoe/doe-ui-core'

export default {
  name: 'ReadOnlySelect',
  components: {
    Chip
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isAllowEditingMode: {
      type: Boolean,
      default: false
    },
    setEditMode: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: ' ' // override the default value 'undefined' in v-select
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  color: $ads-primary-blue;
  font-size: 1rem;
  font-weight: 700;
}
.value {
  min-height: 1rem;
  color: $ads-grey-01;
}

::v-deep .v-chip {
  margin-bottom: 10px;
  background-color: $ads-primary-teal !important;
  color: $ads-primary-blue !important;
  border: 1px solid $ads-primary-blue;
}

.v-chip--outlined {
  border-color: $ads-grey-02 !important;
  color: $ads-grey-01 !important;
}

.editIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  display: none;
  &:focus {
    display: block;
    border: 2px solid $ads-navy;
  }
}

.displayField:not(.readonly) {
  position: relative;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $ads-grey-04;
    .editIcon {
      display: block;
    }
  }
}

.displayField.readonly {
  cursor: not-allowed;
  label {
    cursor: not-allowed;
  }
}

.isEditing .displayField {
  padding-top: 0 !important;
}

::v-deep div.v-select__slot div.v-select__selections {
  min-height: 48px !important;
}

::v-deep .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit;
}
</style>
